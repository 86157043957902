import anylogger from '@app/anylogger'
import { Ref, useCallback, useEffect, useState } from 'react'
const log = anylogger('useCurrentRef')

/**
 * This hook accepts an optional ref and returns a an array consisting of an actual element reference
 * (i.e. the ref.current value, and not a ref that contains a current property) and a ref setter.
 * You can pass the the ref setter to an element (i.e. ref={refSetter}).  Once the element is created,
 * the element ref is returned as the first part of the array.  Because the returned element ref is
 * an actual element, it can be used in dependency lists, such as useEffect.
 * @param passedRef (optional)
 * @returns an array of 1) an element (not just a ref) and 2) a function that you can to set the ref
 */
export function useCurrentRef<T extends any | null>(passedRef?: Ref<T>): [T | null, (val: T) => void] {
	const [ref, setRef] = useState<T | null>(null)
	const setRefCallback = useCallback((newRef: T) => {
		setRef(newRef)
	}, [])

	useEffect(() => {
		if (passedRef) {
			if (typeof passedRef == 'function') {
				passedRef(ref)
			} else {
				// this is valid, except for a createRef object, which is only used in classes, not functional components
				// @ts-ignore
				passedRef.current = ref
			}
		}
	}, [ref, passedRef])
	return [ref, setRefCallback]
}
