/**
 * This wraps a normal Proimise so that it can be created and passed around to other objects which can resolve or reject it.
 * This allows you to create and wait for a promise while some other function or part of the program is responsible for resolving or rejecting it.
 * !NOTE:  When waiting for the Promise, you MUST wait for the promise object in this class instance, and not for the class instance itself
 * i.e.
 * const deferredPromise = new DeferredPromise
 * controlingFunction(deferredPromise, other, params)
 * return await deferredPromise.promise
 */
export class DeferredPromise {
	promise: Promise<any>
	reject: (reason?: any) => void = () => {}
	resolve: (value: any) => void = () => {}
	constructor() {
		this.promise = new Promise((resolve, reject) => {
			this.reject = reject
			this.resolve = resolve
		})
	}
}
