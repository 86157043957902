import React, { useEffect, useState } from 'react'
import anylogger from '@app/anylogger'
import { useAsyncEffect, useLocalStorageOrState } from '@app/hooks'
import { Flex, FlexC } from './Flex'
import { Text } from './Text'
import { ComboBox } from './ComboBox'
import { useModal } from './ModalOverlay'
import { ComboBoxProvider } from './ComboBox'
import { Button, DialogActions, Input, Menu, MenuItem } from '@mui/material'
import { GenericDB } from './GenericDB'
import { TitleDefinitionList } from './TitleDefinition'
import { VirtualListView } from './VirtualListView'
import { ListViewProviderBase } from './IListViewProvider'
import { ColumnMapper, ColumnType } from './ColumnMapper'

const log = anylogger('DBView')

interface DBViewProps {
	/** Use this to persist the values of the combo boxes upon refresh */
	localStorageKey?: string
}

const DBView = React.forwardRef(function DBView(props: DBViewProps, ref: any) {
	const { localStorageKey } = props
	const dbKey = localStorageKey ? localStorageKey + '.CurrentDatabase' : undefined
	const tableKey = localStorageKey ? localStorageKey + '.CurrentTable' : undefined
	const indexKey = localStorageKey ? localStorageKey + '.CurrentIndex' : undefined
	const keyValuesKey = localStorageKey ? localStorageKey + '.KeyValues' : undefined
	const titlesKey = localStorageKey ? localStorageKey + '.Titles' : undefined

	const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>()

	const [dbComboProvider, setDBComboProvider] = useState<ComboBoxProvider>(new ComboBoxProvider([]))
	const [database, setDatabase] = useLocalStorageOrState(dbKey, '')
	const [db, setDb] = useState<GenericDB>()

	const [tableComboProvider, setTableComboProvider] = useState<ComboBoxProvider>(new ComboBoxProvider([]))
	const [table, setTable] = useLocalStorageOrState(tableKey, '')
	const [indexes, setIndexes] = useState<ComboBoxProvider>(new ComboBoxProvider([]))
	const [index, setIndex] = useLocalStorageOrState(indexKey, '')
	const [data, setData] = useState<DBViewListViewProvider>()
	const [keyPath, setKeyPath] = useState([])
	const [keyValues, setKeyValues] = useLocalStorageOrState(keyValuesKey, [])
	const [storageTitles, setStorageTitles] = useLocalStorageOrState(titlesKey, {})

	const { customDialog } = useModal()

	useAsyncEffect(async () => {
		const dbs = await GenericDB.getDatabases()
		const dbNames = dbs
			.map((db) => {
				return db.name
			})
			.filter(Boolean)

		setDBComboProvider(new ComboBoxProvider(dbNames, '', '', 'Select a Database'))
	}, [])
	useAsyncEffect(async () => {
		if (!database) return setDb(undefined)

		const dbs = await GenericDB.getDatabases()
		const dbInfo = dbs.find((db) => {
			return db.name == database
		})

		if (!dbInfo?.version) return
		const db = await GenericDB.createDB(database, dbInfo.version)
		setDb(db)
	}, [database])
	useEffect(() => {
		if (!db) return setTableComboProvider(new ComboBoxProvider([]))
		const tables = db.tables()
		setTableComboProvider(new ComboBoxProvider(tables, '', '', 'Select a Table'))
	}, [db])

	const titleKey = `${database ?? ''}-${table ?? ''}`
	const titles = storageTitles[titleKey]

	useEffect(() => {
		if (!db || !table) return setIndexes(new ComboBoxProvider([]))
		const indexes = db.getIndexes(table)
		indexes.push('Primary')

		if (!indexes) return
		setIndexes(new ComboBoxProvider(indexes, '', '', 'Select an Index'))
	}, [data, db, storageTitles, table, titleKey])
	useEffect(() => {
		if (!db || !table || !index) return setKeyPath([])

		const def = db.getIndex(table, index)
		if (!def) return setKeyPath([])
		let keys = def.keyPath
		if (!Array.isArray(keys)) keys = [keys]

		setKeyPath(keys)
	}, [db, index, indexes, table])

	const databaseChanged = (val: string) => {
		setDatabase(val)
	}
	const tableChanged = (val: string) => {
		setTable(val)
	}
	const indexChanged = (val: string) => {
		if (!db) return
		setIndex(val)
		setKeyValues([])
		setData(undefined)
	}
	const getAll = async () => {
		if (!db || !table) return
		const d = await db.getAll(table, 100)
		log('d', d)

		setData(new DBViewListViewProvider(d))
	}
	const useIndex = async () => {
		if (!db) return

		const vals = keyValues
		const data = await db.searchByIndex(table, index, vals)
		if (!data) return setData(undefined)
		setData(new DBViewListViewProvider(data))
	}
	const getKeyValue = (idx: number) => {
		if (keyValues?.length <= idx) return ''
		return keyValues[idx]
	}
	const setKeyValue = (idx: number, e: any) => {
		let vals = [...keyValues]
		vals[idx] = e.target.value
		setKeyValues(vals)
	}

	const titlesChanged = (titles: TitleDefinitionList) => {
		setTitles(titles)
	}
	const renderContent = () => {
		if (!data || !data) return

		return <VirtualListView provider={data} selectMode="none" titles={titles} userSelectedTitles={titlesChanged} />
	}
	const setTitles = (selected: TitleDefinitionList) => {
		const t = { ...storageTitles }
		t[titleKey] = selected
		setStorageTitles(t)
	}
	const renderKeyProps = () => {
		if (!keyPath) return

		return keyPath.map((key, idx) => {
			return (
				<Flex key={idx} alignItems="center">
					<Text>{key}</Text>
					<Input
						sx={{
							fontSize: 'inherit'
						}}
						value={getKeyValue(idx)}
						onChange={(e) => setKeyValue(idx, e)}
					/>
				</Flex>
			)
		})
	}
	return (
		<FlexC fill fontSize={14} px="0.5em">
			<Flex alignItems="center">
				<Flex alignItems="center">
					<Text>Database</Text>
					<ComboBox provider={dbComboProvider} value={database} onItemSelected={databaseChanged}></ComboBox>
				</Flex>
				<Flex alignItems="center">
					<Text>Table</Text>
					<ComboBox provider={tableComboProvider} value={table} onItemSelected={tableChanged}></ComboBox>
				</Flex>
				<Button
					onClick={getAll}
					sx={{
						fontSize: 'inherit'
					}}
				>
					Get All
				</Button>
			</Flex>
			<Flex alignItems="center">
				Index
				<ComboBox provider={indexes} value={index} onItemSelected={indexChanged} />
				{renderKeyProps()}
				<Button
					onClick={useIndex}
					sx={{
						fontSize: 'inherit'
					}}
				>
					Search
				</Button>
			</Flex>
			<Flex fill>{renderContent()}</Flex>
		</FlexC>
	)
})
export { DBView }

export class DBViewListViewProvider extends ListViewProviderBase {
	columns: ColumnMapper
	allTitles: TitleDefinitionList = []

	constructor(rows: any[]) {
		super(rows)
		const titles = this.createTitles(rows)

		let arr: ColumnType<any>[] = titles.map((title) => {
			return [title, title]
		})
		this.columns = new ColumnMapper(arr)
	}
	getAllTitles() {
		return this.columns.getAllTitles()
	}
	createRow(row: any) {
		return this.columns.getValues(row)
	}
	private createTitles(rows: any[]) {
		let titles: string[] = []
		rows.forEach((row) => {
			Object.entries(row).forEach(([k, v]) => {
				if (typeof v == 'object') return
				const t = titles.find((t) => {
					return t.toLocaleLowerCase() == k.toLocaleLowerCase()
				})
				if (!t) {
					titles.push(k)
				}
			})
		})
		return titles
	}
}
