'use client'
import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import anylogger from '@app/anylogger'
import { AddTo } from '@app/utils'

const log = anylogger('KeyBinder')

type KeyBindingHandler = () => void
export type KeyBinding = [key: string, action: KeyBindingHandler]
export type KeyBindingList = KeyBinding[]
class KeyBinder {
	bindKey!: (key: string, action: KeyBindingHandler) => void
	unbindKey!: (key: string, action: KeyBindingHandler) => void
	bindKeys!: (list: KeyBindingList) => void
	unbindKeys!: (list: KeyBindingList) => void
	useKeyBinding!: (key: string, action: KeyBindingHandler) => void
}

export const UseKeyBinderContext = React.createContext<KeyBinder>(new KeyBinder())

export function useKeyBinder(): KeyBinder {
	const context = useContext(UseKeyBinderContext)
	return context
}
function useKeyBinding(key: string, action: KeyBindingHandler) {
	const { bindKey, unbindKey } = useKeyBinder()
	useEffect(() => {
		if (!key) return undefined
		bindKey(key, action)
		return () => unbindKey(key, action)
	}, [action, bindKey, key, unbindKey])
}
interface KeyBindingOptions {
	ignoreNonModifiedKeys?: boolean
}
class KeyBindings {
	ignoreNonModifiedChars: boolean
	constructor(options: KeyBindingOptions) {
		// log('creating KeyBindings', options)

		const { ignoreNonModifiedKeys = false } = options
		this.ignoreNonModifiedChars = ignoreNonModifiedKeys
	}
	bindings: any = {}

	normalizeKey(key: string): string {
		key = key.toLocaleLowerCase()
		let res = ''
		if (key.includes('ctrl')) res = AddTo(res, '-', 'ctrl')
		if (key.includes('alt')) res = AddTo(res, '-', 'alt')
		if (key.includes('shift')) res = AddTo(res, '-', 'shift')
		const vals = key.split(/[-+]/g)
		if (vals?.length > 0) res = AddTo(res, '-', vals[vals.length - 1].toLocaleLowerCase())
		return res
	}
	normalizeEvent(e: KeyboardEvent): string {
		let res = ''
		if (e.ctrlKey) res = AddTo(res, '-', 'ctrl')
		if (e.altKey) res = AddTo(res, '-', 'alt')
		if (e.shiftKey) res = AddTo(res, '-', 'shift')
		res = AddTo(res, '-', e.key.toLocaleLowerCase())
		return res
	}
	add(key: string, action: KeyBindingHandler) {
		key = this.normalizeKey(key)

		const existing = this.bindings[key]
		if (existing) log.warn('Overriding keystroke', key)
		this.bindings[key] = action
		// log('add binding', key, this.bindings)
	}
	remove(key: string, action: KeyBindingHandler) {
		// log('remiving binding', key)

		key = this.normalizeKey(key)
		delete this.bindings[key]
	}
	execute(e: KeyboardEvent) {
		if (['Control', 'Alt', 'Shift'].includes(e.key)) return
		if (this.ignoreNonModifiedChars && !e.ctrlKey && !e.altKey && !e.shiftKey) return
		if (!e.key) return
		// log('key pressed', e.key, this.bindings)

		const key = this.normalizeEvent(e)
		const action = this.bindings[key]

		if (action) {
			// log('found action', key)
			try {
				e.preventDefault()
				e.stopPropagation()
				action()
			} catch (err) {
				log.error('Error executing key binding', key, err)
			}
		}
	}
}

interface KeyBinderProps {
	ignoreNonModifiedKeys?: boolean
	ref?: any
	children?: ReactNode
}
export const KeyBinderService = function KeyBinderService(props: KeyBinderProps) {
	const { ignoreNonModifiedKeys, children } = props
	const [bindings] = useState(new KeyBindings({ ignoreNonModifiedKeys }))

	const keyDown = useCallback(
		(e: any) => {
			bindings.execute(e)
		},
		[bindings]
	)

	useEffect(() => {
		document.addEventListener('keydown', keyDown)
		return () => document.removeEventListener('keydown', keyDown)
	}, [keyDown])

	const bindKey = useCallback(
		(key: string, action: KeyBindingHandler) => {
			bindings.add(key, action)
		},
		[bindings]
	)
	const unbindKey = useCallback(
		(key: string, action: KeyBindingHandler) => {
			bindings.remove(key, action)
		},
		[bindings]
	)
	const bindKeys = useCallback(
		(list: KeyBindingList) => {
			list.forEach((item) => {
				bindings.add(item[0], item[1])
			})
		},
		[bindings]
	)
	const unbindKeys = useCallback(
		(list: KeyBindingList) => {
			list.forEach((item) => {
				bindings.remove(item[0], item[1])
			})
		},
		[bindings]
	)
	return (
		<UseKeyBinderContext.Provider value={{ bindKey, unbindKey, bindKeys, unbindKeys, useKeyBinding }}>
			{children}
		</UseKeyBinderContext.Provider>
	)
}
