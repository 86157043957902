import { useMediaQuery, useTheme, Breakpoint } from '@mui/material'

/**
 * You can use this to check if useMediaQuery matches a particular breakpoint
 * @param breakpoint Your custom breakpoint name
 * @returns A boolean indicating if the current breakpoint is in effect
 */
export function useBreakpoint(breakpoint: Breakpoint) {
	const theme = useTheme()
	return useMediaQuery(theme.breakpoints.only(breakpoint))
}
