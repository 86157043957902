import algoliaClient, { SearchClient } from 'algoliasearch/lite'
import anylogger from '@app/anylogger'
import { createContext, useCallback, useContext, useState } from 'react'
import { InstantSearch, useSearchBox } from 'react-instantsearch'

const log = anylogger('Search')

/**
 * ! Need to fix navigate back so that you are returned to the search results.  Perhaps can use a custom Algolia router as per
 * https://github.com/algolia/instantsearch/blob/master/packages/react-instantsearch-router-nextjs/src/index.ts
 * And Perhaps here: https://www.algolia.com/doc/guides/building-search-ui/widgets/create-your-own-widgets/js/#interact-with-routing
 * Should hopefully be able to use shallow routing, but NextLink does not honour the ~:~text= hash for highlighting the results
 */

const Search = (props: any) => {
	const [searchClient] = useState(() => {
		if (!process.env.NEXT_PUBLIC_AlgoliaId || !process.env.NEXT_PUBLIC_AlgoliaSearchKey)
			throw new Error(`AlgoliaId or search key not set`)

		const client = algoliaClient(process.env.NEXT_PUBLIC_AlgoliaId, process.env.NEXT_PUBLIC_AlgoliaSearchKey)
		const searchClient: SearchClient = {
			...client,
			search(requests: any) {
				if (
					requests.every(({ params }: { params: any }) => {
						return params?.query?.length < 3
					})
				) {
					return Promise.resolve({
						results: requests.map(() => ({
							hits: [],
							nbHits: 0,
							nbPages: 0,
							page: 0,
							processingTimeMS: 0,
							hitsPerPage: 0,
							exhaustiveNbHits: false,
							query: '',
							params: ''
						}))
					})
				}
				return client.search(requests)
			}
		}

		return searchClient
	})

	const stateChange = useCallback(({ uiState, setUiState }: { uiState: any; setUiState: any }) => {
		// log('uiState', uiState)
		// if (uiState.MdxContent.query?.length < 3) return setUiState({})
		// this is too restrictive.  The api rangs phrase matches at the top anyway, even if it also lists docs with all the words, but not the phrase
		// uiState.MdxContent.query = `"${uiState.MdxContent.query}"`
		setUiState(uiState)
	}, [])
	const beforeDispose = useCallback(() => {
		// log('DISPOSING router')
	}, [])
	const beforeStart = useCallback((onUpdate: any) => {
		// log('beforeStart')
	}, [])
	const beforePopState = useCallback((options: any) => {
		return false
	}, [])
	return (
		<InstantSearch
			searchClient={searchClient}
			onStateChange={stateChange}
			indexName="MdxContent"
			future={{ preserveSharedStateOnUnmount: true }}
			// routing={true}
		>
			<SearchService>{props.children}</SearchService>
		</InstantSearch>
	)
}
export default Search

interface Context {
	clear: () => void
	search(str: string): void
	searchText: string
	isSearching: boolean
	// navigate(url: string): void
}

export const SearchContext = createContext<Context>(undefined!)

export const useSearch = () => {
	const val = useContext(SearchContext)
	return val
}
export const SearchService = (props: any) => {
	const { query, refine, clear: resetSearch } = useSearchBox({})
	// log('query', query)

	// const [searchText, setSearchText] = useState(query)

	const clear = useCallback(() => {
		// setSearchText('')
		refine('')
	}, [refine])
	const search = useCallback(
		(str: string) => {
			// log('search', str)

			// setSearchText(str)
			refine(str)
		},
		[refine]
	)
	// const { pathname, params } = parseUrl(router.asPath)

	// log('pathname', pathname)

	// log('params.query', params.query)

	// useEffect(() => {
	// 	if (params.query) {
	// 		log('searching', params.query)

	// 		// router.replace(pathname, pathname, { shallow: true })
	// 		// search(params.query)
	// 	}
	// }, [params.query, pathname, router, search])

	// const navigate = useCallback(
	// 	(url: string) => {
	// 		// let path = router.asPath
	// 		// const { pathname } = parseUrl(path)
	// 		// path = pathname + `?query=${searchText}`
	// 		// log('path', path)

	// 		// router.replace(path, path, { shallow: true })
	// 		log('url', url)
	// 		clear()
	// 		router.push(url) //, undefined, { shallow: true })
	// 	},
	// 	[clear, router]
	// )

	const ctx = { clear, search, searchText: query, isSearching: query?.length >= 3 }

	return <SearchContext.Provider value={ctx}>{props.children}</SearchContext.Provider>
}
