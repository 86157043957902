import anylogger from '@app/anylogger'

const log = anylogger('utils')

export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>

export type Assign<T, U> = {
	[P in keyof (T & U)]: P extends keyof T ? T[P] : P extends keyof U ? U[P] : never
}
// This can be used in place of .filter(Boolean) (i.e. .filter(TypesafeBoolean)) to get rid of typescript errors
// indicating that undefined is not assignable to the type.
// i.e. It coerces the result to NOT contain undefined in the list so that the list can be used as T[] instead of (T|undefined)[]
export const TypesafeBoolean = Boolean as any as <T>(x: T | false | undefined | null | '' | 0) => x is T

export function AssignMatchingProps(dst: any, src: any): void {
	// * NOTE: when passing in a newly initialized object as dst, all the properties must be assigned a value (even if undefined)
	// * even though the property names may be defined in typescript. i.e. Given the following class
	// * MyObject {
	// *     prop1?: boolean
	// *     prop2?: string }
	// * prop1 and prop2 will NOT be visible by hasOwnProperty below if the module containing MyObject is only compiled with tsc
	// * When the object was compiled with Next.js (probably with some babel transform), then the prop1 and prop2 properties WERE visible
	// * To get arouned this (without requiring the babel transform) declare the object as follows:
	// * MyObject {
	// *     prop1?: boolean = undefined
	// *     prop2?: string = undefined }
	for (const key in src) {
		if (Object.prototype.hasOwnProperty.call(dst, key)) {
			dst[key] = src[key]
		}
	}
}

export function isServer() {
	return typeof window === 'undefined'
}

export function deriveDateFromFileName(fn: string): string | undefined {
	const re = /(20[0-9]{6})[_-]?([0-2][0-9][0-5][0-9]([0-5][0-9])?)/
	const re2 = /([0-9]{4}20[0-9]{2})[_-]?(([0-2])[0-9][0-5]{1}[0-9]([0-5]{1}[0-9])?)/
	let y = undefined
	let m = undefined
	let d = undefined
	let tm = undefined
	const matches = fn.match(re)
	if (matches && matches?.length >= 2) {
		const dt = matches[1]
		y = dt.slice(0, 4)
		m = dt.slice(4, 6)
		d = dt.slice(6, 8)
		tm = matches[2]
	} else {
		const matches = fn.match(re2)
		log('matches', matches)

		if (matches && matches?.length >= 2) {
			log('matches', matches)

			const dt = matches[1]
			m = dt.slice(0, 2)
			d = dt.slice(2, 4)
			y = dt.slice(4, 8)
			tm = matches[2]
		}
	}

	if (y && m && d && tm) {
		const hh = tm.slice(0, 2)
		const mm = tm.slice(2, 4)
		const ss = tm.slice(4, 6)

		const str = `${y}-${m}-${d} ${hh}:${mm}:${ss}Z`
		console.log(str)
		return new Date(str).toJSON()
	}
	return undefined
}

// export function getEnvironmentVariableSize() {
// 	let len = 0
// 	Object.entries(process.env).map(([key, val]) => {
// 		len = len + key.length + val.length + 1
// 	})
// 	return len
// }
