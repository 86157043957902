import { openDB, wrap } from 'idb'
import anylogger from '@app/anylogger'
import { TypesafeBoolean, equals } from '@app/utils'

const log = anylogger('DB')

export class GenericDB {
	db: any
	dbName: string
	version: number
	static async createDB(dbName: string, version: number) {
		const db = new GenericDB(dbName, version)
		await db.initDB()
		return db
	}
	static async getDatabases() {
		return await indexedDB.databases()
	}
	constructor(dbName: string, version: number) {
		this.dbName = dbName
		this.version = version
	}
	async initDB() {
		this.db = await openDB(this.dbName, this.version, {})
	}
	tables() {
		const list = this.db.objectStoreNames as string[]
		return [...list]
	}
	async getAll(table: any, count?: number) {
		const tran = this.db.transaction(table, 'readonly')
		const store = tran.objectStore(table)
		const records = await store.getAll(undefined, count)
		await tran.done
		return records
	}
	getIndexes(table: string) {
		const tran = this.db.transaction(table, 'readonly')
		const store = tran.objectStore(table)
		return [...store.indexNames]
	}
	async getFromIndex(table: string, indexName: string, values: any) {
		if (values.length == 1) values = values[0]
		let vals

		if (indexName == 'Primary') {
			vals = await this.db.getAll(table, values)
			// if (vals) vals = vals
		} else vals = await this.db.getAllFromIndex(table, indexName, values)

		return vals
	}
	findValue(value: any, searchTerm: any) {
		if (typeof value == 'string') {
			if (!value.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) return false
		} else {
			if (!equals(value, searchTerm)) return false
		}
		return true
	}
	async searchByIndex(table: string, indexName: string, values: any) {
		// if (values.length == 1) values = values[0]
		let items

		if (indexName == 'Primary') {
			items = await this.db.getAll(table)
		} else items = await this.db.getAllFromIndex(table, indexName)

		const idx = this.getIndex(table, indexName)
		let keyPath = idx.keyPath
		if (!Array.isArray(keyPath)) keyPath = [keyPath]
		log('items', items)

		const res = items
			.map((item: any) => {
				for (let ii = 0; ii < keyPath.length; ii++) {
					const key = keyPath[ii]
					const val = item[key]
					let foundVal = false

					if (Array.isArray(val)) {
						log('val', val)

						for (let jj = 0; jj < val.length; jj++) {
							const item = val[jj]
							log('item', item)

							if (this.findValue(item, values[ii])) {
								log('found', item)

								foundVal = true
								break
							}
						}
					} else foundVal = this.findValue(val, values[ii])

					if (!foundVal) return undefined
				}
				return item
			})
			.filter(TypesafeBoolean)

		return res
	}
	getIndex(table: string, indexName: string) {
		const tran = this.db.transaction(table, 'readonly')
		const store = tran.objectStore(table)
		if (indexName == 'Primary') return store
		if (!store || ![...store.indexNames].includes(indexName)) return

		const idx = store.index(indexName)
		return idx
	}
}
