import anylogger from '@app/anylogger'
import { useCallback } from 'react'
import { HTMLRef, MouseTouchEvent, MouseTouchEventHandler, createMouseEvent, createTouchEvent } from './mouseTouchUtils'
import { useEvent } from './useEvent'
import { useIsTouch } from './useIsTouch'

const log = anylogger('useDownEvent')

/**
 * This hook amalgamates the mouseDown and touchStart events so that you can use one hook to control both
 * desktop and mobile devices.
 * @param ref - The ref of the object you want the downEvent for.
 * @param downEvent - This callback gets fired when there is a mouseDown or touchStart event.  The callback passes a {@link MouseTouchEvent} parameter.
 * @param allowPreventDefault - Whether returning true from the callback will call preventDefault on the originating event handler.
 * @param data - This data will be passed in the callback function parameter.
 */
export const useDownEvent = (ref: HTMLRef, downEvent: MouseTouchEventHandler, allowPreventDefault?: boolean, data?: any) => {
	const isTouch = useIsTouch()

	const touchStart = useCallback(
		(e: any) => {
			if (!isTouch) return
			if (!e.touches.length) return
			// log('touchStart', e)

			if (downEvent(createTouchEvent(e, data))) {
				if (allowPreventDefault) e.preventDefault()
				e.stopPropagation()
			}
		},
		[allowPreventDefault, data, downEvent, isTouch]
	)
	const mouseDown = useCallback(
		(e: any) => {
			if (isTouch) return
			if (downEvent(createMouseEvent(e, data))) {
				if (allowPreventDefault) e.preventDefault()
				e.stopPropagation()
			}
		},
		[allowPreventDefault, data, downEvent, isTouch]
	)
	const options = allowPreventDefault ? { passive: false } : undefined
	useEvent(ref, 'touchStart', touchStart, options)
	useEvent(ref, 'mouseDown', mouseDown, options)
}
