interface ParsedUrl {
	protocol: string
	host: string
	port: string
	pathname: string
	search: string
	params: any
	hash: string
}
export function parseUrl(url: string): ParsedUrl {
	const obj = new URL(decodeURI(url), 'http://test')
	const path = decodeURIComponent(obj.pathname)
	let params: any = {}

	obj.searchParams.forEach((value, name, sp) => {
		params[name] = decodeURIComponent(value)
	})
	return { protocol: obj.protocol, host: obj.host, port: obj.port, hash: obj.hash, pathname: path, search: obj.search, params }
}
