import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import React, { useState } from 'react'
import useResizeObserver from './useResizeObserver'

/**
 * This component returns the height of the referenced component.
 * It also watches if the referenced component changes in size and triggers a state change.
 * This causes any components that call this hook to re-render if the referenced component changes in height
 *
 */
export function useComponentHeight(ref: any): number {
	const [height, setHeight] = useState(0)

	const { height: h } = useResizeObserver({ ref })
	if (h && h !== height) {
		setHeight(h)
	}

	// This is now all done in useResizeObserver
	// const [observerEntry, setObserverEntry] = useState<ResizeObserverEntry>()
	// const observer = useRef<ResizeObserver>()
	// const disconnect = useCallback(() => observer.current?.disconnect(), [])
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// const observed = (entry: ResizeObserverEntry) => {
	// 	const r = entry.contentRect

	// 	if (r.height !== height) {
	// 		setHeight(r.height)
	// 		//console.log(`Element's size: ${width}px x ${height}px`)
	// 	}
	// }
	// const observe = useCallback(() => {
	// 	observer.current = new ResizeObserver(([entry]) => observed(entry))
	// 	if (ref?.current) observer.current.observe(ref.current)
	// }, [observed, ref])
	// useEffect(() => {
	// 	observe()
	// 	return () => {
	// 		disconnect()
	// 	}
	// }, [disconnect, observe])

	return height
}
