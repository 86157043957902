import { useCallback, useEffect, useState } from 'react'
import anylogger from '@app/anylogger'

const log = anylogger('useArrayRefs')

type SetArrayRef = (ref: HTMLElement, index: number) => void

/**
 * This hook will return an indexed array of refs.  The array is populated by calling the setRef function
 * that is also returned by this hook.  setRef must be called with a ref and the index of that ref in the array
 * @param dependencies The dependencies that cause the refs to be reset
 * @returns An array containing a list of the refs that were set using setRef, and the setRef function
 * that is called to populate the array
 */
export function useArrayRefs(dependencies: any[], debug?: string): [refs: HTMLElement[], setRef: SetArrayRef] {
	const [refs, setRefs] = useState<HTMLElement[]>([])
	useEffect(() => {
		setRefs([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...dependencies])
	const setRef = useCallback(
		(ref: any, idx: number) => {
			if (!ref) return

			setRefs((prev) => {
				if (prev[idx] == ref) return prev
				if (debug) log('refChanged', idx, ref.textContent)

				let res = [...prev]
				res[idx] = ref
				return res
			})
		},
		[debug]
	)

	return [refs, setRef]
}
