import anylogger from '@app/anylogger'

const log = anylogger('StrUtils')

export const AddTo = (source: string, separator: string, value: string, skipIfValueEmpty: boolean = false) => {
	let res = source
	if (!value && skipIfValueEmpty) return res
	if (res.length) res = res + separator
	res = res + value
	return res
}

/** This takes any value and converts it to a string using JSON.stringify for objects and arrays */
export function toString(val: any, undefinedValue: string = 'undefined') {
	if (typeof val == 'string') return val
	if (typeof val == 'undefined') return undefinedValue
	if (Array.isArray(val)) {
		try {
			return JSON.stringify(val)
		} catch (err) {
			return '[array]'
		}
	}
	if (['object'].includes(typeof val)) {
		try {
			return JSON.stringify(val)
		} catch (err) {
			return '[object]'
		}
	}
	return val.toString()
}
export function FirstCap(str: string) {
	return str.charAt(0).toUpperCase() + str.slice(1)
}

export function arrayBufferToBase64(buf: ArrayBuffer): string {
	if (typeof window == 'undefined') return Buffer.from(buf).toString('base64')
	return window.btoa(new Uint8Array(buf).reduce((data, byte) => data + String.fromCharCode(byte), ''))
}
export function base64ToArrayBuffer(base64: string): ArrayBuffer {
	if (typeof window == 'undefined') return Buffer.from(base64, 'base64')

	var binary_string = window.atob(base64)
	var len = binary_string.length
	var bytes = new Uint8Array(len)
	for (var i = 0; i < len; i++) {
		bytes[i] = binary_string.charCodeAt(i)
	}
	return bytes.buffer
}
export function arrayBufferToString(buf: ArrayBuffer) {
	return String.fromCharCode.apply(null, new Uint8Array(buf) as unknown as number[])
}
export function zeroPad(val: number, num: number) {
	return val.toString().padStart(num, '0')
}
export function isWhiteSpace(str: string): boolean {
	return str.trim() === ''
}
export function isBase64(str: string): boolean {
	if (!str || typeof str != 'string') return false
	var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
	// we are only going to check the first 1000 characters or so.  If you check a 16MB file we get a maximum call stack exceeded error
	return base64regex.test(str.substring(0, 1000))
}
/**
 * This returns true if the str (even if the str happens to be a single character)
 * is a number.
 * */
export function isNum(str: string): boolean {
	let s = str.trim().replaceAll(',', '')
	if (s.length && s[0] == '+') s = s.slice(1)
	const parsed = parseInt(str).toString()
	return parsed == s
}
export function isFloat(str: string): boolean {
	let s = str.trim().replaceAll(',', '')
	if (s.length && s[0] == '+') s = s.slice(1)
	const parsed = parseFloat(str).toString()
	return parsed == s
}
export interface ContainedWord {
	word: string
	index: number
}
export type ContainedWordList = ContainedWord[]
/**
 * This returns an array of ContainedWord objects, each of which contains the word and the (starting) index
 * within the containing string. This ignores any whitespace.
 */
export function createWordIndex(str: string | undefined): ContainedWordList {
	let idx = 0
	let res: ContainedWordList = []
	if (!str) return res

	const updateWord = (endIdx: number) => {
		if (!res.length) return
		let item = res[res.length - 1]

		// this check is only in case there is whitespace at the end of the string.
		// i.e. calling updateWord at the end of the loop would overwrite the "clipped" word
		// that was created when we encountered the first whitespace character after the word
		if (item.word) return

		item.word = str.slice(item.index, endIdx)
	}
	let inWhitespace = true
	while (idx < str.length) {
		if (isWhiteSpace(str[idx])) {
			inWhitespace = true
			updateWord(idx)
		} else {
			if (inWhitespace) res.push({ index: idx, word: '' })
			inWhitespace = false
		}
		idx += 1
	}
	updateWord(str.length)
	return res
}
export function extractWords(str: string | undefined): string[] {
	const wordList = createWordIndex(str)
	return wordList.map((w) => w.word)
}
export function numbersOnly(str: string) {
	if (typeof str == 'undefined') return ''
	var num = str.match(/\d/g)
	if (!num) return ''
	return num.join('')
}
export function cleanPhone(phone: string) {
	let str = numbersOnly(phone)

	// let str = phone
	// 	.replaceAll(' ', '')
	// 	.replaceAll('.', '')
	// 	.replaceAll('-', '')
	// 	.replaceAll('(', '')
	// 	.replaceAll(')', '')
	// 	.replaceAll('+', '')
	if (str.length && str[0] == '1') str = str.slice(1)

	if (str.length == 10) return `${str.slice(0, 3)}-${str.slice(3, 6)}-${str.slice(6)}`
	else if (str.length == 7) return `${str.slice(0, 3)}-${str.slice(3)}`
	else return str
}
export function formatFileSize(size: number | number) {
	if (!size) return ''
	if (typeof size == 'string') size = parseInt(size)

	if (typeof size == 'undefined') return ''
	const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
	let res = size
	let unitIdx = 0
	// if it is less than 1K, don't show decimal
	let dec = 0
	while (res > 1000) {
		dec = 1
		res = res / 1024
		unitIdx = unitIdx + 1
	}
	return `${res.toFixed(dec)} ${units[unitIdx]}`
}
export function incrementFileName(name: string) {
	const reg = /(.*-)([0-9]*)$/
	const matches = name.match(reg)
	let res
	if (matches && matches.length >= 3) {
		let num = Number.parseInt(matches[2])
		num = num + 1
		res = name.replace(reg, `$1${num}`)
	} else res = name + '-1'
	return res
}
export function isTrue(val: any) {
	return (typeof val == 'boolean' && val) || (typeof val == 'string' && val == 'true')
}
export function isFalse(val: any) {
	return (typeof val == 'boolean' && !val) || (typeof val == 'string' && val == 'false')
}
export function strToList(str: string, sep: string, allowBlankEntries: boolean = false): string[] {
	if (typeof str == 'undefined') return []
	let list = str.split(sep)
	if (!allowBlankEntries) {
		for (let ii = list.length - 1; ii >= 0; ii--) {
			if (list[ii].trim() == '') list.splice(ii, 1)
		}
	}
	return list
}
export function listToStr(list: string[], sep: string, allowBlankEntries: boolean = false): string {
	if (typeof list == 'undefined') return ''
	let res = ''
	for (let ii = 0; ii < list.length; ii++) {
		if (!allowBlankEntries && list[ii].trim() == '') continue
		res = AddTo(res, sep, list[ii])
	}
	return res
}
