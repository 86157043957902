import { createContext, useContext } from 'react'

export interface IMenu {
	caption: string
	data: string
	children?: IMenuList
}
export type IMenuList = IMenu[]

class Context {
	items: IMenuList = []
}
export const MenuContext = createContext(new Context())

export const useMenu = () => {
	const val = useContext(MenuContext)
	return val
}
