import * as React from 'react'
import { Flex } from './Flex'
import { Text } from './Text'
import { useCarousel, CarouselOptions } from './useCarousel'
import anylogger from '@app/anylogger'
import { Box } from '@mui/material'
import { styled } from '@mui/material'

const log = anylogger('Carousel')

interface NavButtonProps {
	onClick?: () => void
	children?: React.ReactNode
}
const NavButton: React.FC<NavButtonProps> = function NavButton(props) {
	return (
		<NavButtonStyle onClick={props.onClick} cursor="pointer">
			{/* <Container css={{ position: 'absolute', right: '0' }} sx={p}> */}
			<Box m="auto">{props.children}</Box>
		</NavButtonStyle>
	)
}
export interface CarouselChildProps {
	width?: number
	mx?: string
	children?: React.ReactNode
}
export const CarouselChild: React.FC<CarouselChildProps> = function CarouselChild({ children, width, mx }) {
	return (
		<Box
			// normally each slide (in this container) is sized properly by useCarousel,
			// but some of the slides are shrunk a bit when the parent container is constrained
			// causes mis-alignment of frames on mobile devices
			// Setting this width prevents that misalignment
			width={width}
			height="100%"
			mx={mx}
		>
			{children}
		</Box>
	)
}

export interface CarouselProps {
	interval?: number
	slidesPresented?: number
	slideWidth?: number
	[key: string]: any
}

export const Carousel: React.FC<CarouselProps> = function Carousel({
	children,
	slidesPresented = 1,
	slideWidth = 500,
	interval = 5000,
	...rest
}) {
	const slides = React.Children.toArray(children)
	const length = slides.length
	slidesPresented = Math.min(slidesPresented, length)
	// const numActive = Math.min(length, slidesPresented)
	const [active, setActive, jumpNext, jumpPrev, handlers, style] = useCarousel(length, interval, {
		slidesPresented
	})
	const preSlides = slides.slice(length - slidesPresented, length)
	const postSlides = slides.slice(0, slidesPresented)
	const adjustedSlides = preSlides.concat(slides).concat(postSlides)

	const createIndicator = (index: number) => {
		return (
			<CarouselIndicator
				key={index}
				onClick={() => setActive(index)}
				className={`${active === index ? 'active' : ''}`}
			/>
		)
	}
	const IndicatorContainer = () => {
		return (
			<CarouselIndicatorContainer my="10px" justifyContent="center">
				{slides.map((umm, index) => createIndicator(index))}
			</CarouselIndicatorContainer>
		)
	}
	const getIndex = (step: number) => {
		const res = (active + length + step) % length
		return res
	}

	const nextClick = () => {
		jumpNext()
		// setActive(getIndex(1))
	}
	const prevClick = () => {
		jumpPrev()
		// setActive(getIndex(-1))
	}
	const Next = () => {
		return <NavButton onClick={nextClick}>&#10095;</NavButton>
	}
	const Prev = () => {
		return <NavButton onClick={prevClick}>&#10094;</NavButton>
	}

	const viewWidth = `${slideWidth * slidesPresented}px`
	const getSlides = () => {
		return adjustedSlides.map((slide, index) => (
			<CarouselChild width={slideWidth} key={index}>
				{slide}
			</CarouselChild>
		))
	}
	return (
		<Flex {...rest}>
			<Prev />
			<Flex
				f="c"
				// flex="1 0 content" or flex="1 0 auto",  the nav buttons overwrites the content.
				flex="1 0 0"
				// this is the viewport that views the "current" portion of the slides
				// this property hides anything that is outside the size of this control
				overflow="hidden"
				// this constrains the viewport to the width of the number of slides that we want to display at one time
				maxWidth={viewWidth}
			>
				<Flex
					alignItems="stretch"
					// this is required so that we can specify the starting position of this component,
					// which contains the wide list of slides, including the pre and post slides for transitions
					// past the end of the frames
					position="relative"
					{...handlers}
					style={style}
					// this is to get rid of the default gap in the Flex component
					gap="0px"
				>
					{getSlides()}
				</Flex>
				<IndicatorContainer />
			</Flex>
			<Next />
		</Flex>
	)
}

export const CarouselIndicator = styled('span', {
	name: 'Carousel',
	slot: 'Indicator',
	overridesResolver: (props, styles) => {
		return styles.indicator
	}
})(({ theme }) => {
	// for some reason, Storybook (even with a @mui/styles ThemeProvider) does not provide a theme here, so we have silly default values
	// console.log('theme', theme)

	return {
		backgroundColor: theme.palette.grey[500],
		borderRadius: '50%',
		cursor: 'pointer',
		mx: '7px',
		'&.active': {
			backgroundColor: theme.palette.primary.main,
			cursor: 'default'
			// transition: '0.25s linear'
		},
		'&:hover': {
			backgroundColor: theme.palette.secondary.main
		},
		width: '10px',
		height: '10px'
	}
})
export const CarouselIndicatorContainer = styled(Flex, {
	name: 'Carousel',
	slot: 'IndicatorContainer',
	overridesResolver: (props, styles) => {
		return styles.indicatorContainer
	}
})(({ theme }) => {
	return {}
})
export const NavButtonStyle = styled(Flex, {
	name: 'Carousel',
	slot: 'NavButton',
	overridesResolver: (props, styles) => {
		return styles.navButton
	}
})(({ theme }) => {
	return {
		// width: '20px',
		flex: '0 0 content',
		fontWeight: 'bold',
		fontSize: '36px',
		cursor: 'pointer',
		opacity: 0.2,
		'&:hover': {
			opacity: 1,
			transition: '0.5s linear'
		}
	}
})
