import { compare, getValueByPointer, applyOperation, Operation } from '@app/jsonpatch'
import { AddTo, toString } from './StrUtils'
import { TypesafeBoolean } from './utils'
import anylogger from '@app/anylogger'

const log = anylogger('reconcileLists')

export interface ICompareField {
	field: string
	ignoreCase: boolean // defaults to true
}
export type CompareField = string | ICompareField
export type CompareFieldList = CompareField[]

export type LocateFunction<T = any> = (item: T, list: T[]) => T | undefined
export function reconcileList<T = any>(
	toList: T[],
	fromList: T[],
	locateFunc: LocateFunction<T>,
	compareFields?: CompareFieldList
): ReconciledItemList<T> {
	const reconciled = toList.map((toItem) => {
		const fromItem = locateFunc(toItem, fromList)
		return new ReconciledItem(toItem, fromItem, compareFields)
	})

	const deleted = fromList
		.map((itemB) => {
			const itemA = locateFunc(itemB, toList)
			if (!itemA) {
				return new ReconciledItem(undefined, itemB, compareFields)
			}
			return undefined
		})
		.filter(TypesafeBoolean)
	if (deleted.length) reconciled.unshift(...deleted)
	return reconciled
}

export type ReconcileStatus = 'Added' | 'Deleted' | 'Changed' | ''
export type ReconcileUpdateStatus = 'Updated' | 'Updating...' | 'Error' | ''

export class ReconciledItem<T = any> {
	newItem?: T
	oldItem?: T
	status: ReconcileStatus = ''
	updateStatus: ReconcileUpdateStatus = ''
	changes: Operation[] = []
	changeText: string = ''
	error: string = ''
	changeHint: string = ''

	constructor(newItem?: T, oldItem?: T, compareFields?: CompareFieldList) {
		if (!newItem && !oldItem) throw new Error(`You must specify at least one of newItem or oldItem`)
		this.newItem = newItem
		this.oldItem = oldItem

		if (!oldItem) {
			this.status = 'Added'
			// this.changeText = textMap[this.updateStatus]
		} else if (!newItem) {
			this.status = 'Deleted'
			// this.changeText = textMap[this.updateStatus]
		} else {
			// this.changeText = textMap[this.updateStatus]

			// we need to only show the changes of the pertinent fields.  We can't blindly ignore all the "removals"
			// some of them may be legitimate, such as home phone.
			// we also convert all strings to lowercase for comparison, since compare below does not give us that flexibility
			const filteredOldUser = this.filterFields(this.oldItem!, compareFields)
			const filteredNewUser = this.filterFields(this.newItem!, compareFields)

			// now we have to put all the original changed values back

			this.changes = compare(filteredOldUser, filteredNewUser)
			this.changes.forEach((change) => {
				const op = change.op
				if (op == 'add') {
					change.value = getValueByPointer(this.newItem!, change.path)
				} else if (op == 'remove') {
					change.oldVal = getValueByPointer(this.oldItem!, change.path)
				} else if (op == 'replace') {
					change.value = getValueByPointer(this.newItem!, change.path)
					change.oldVal = getValueByPointer(this.oldItem!, change.path)
				}
			})

			if (this.changes.length) {
				this.status = 'Changed'
				this.changeText = this.getDiff()
			}
		}

		if (this.error) {
			this.changeText = this.error + '\n' + this.changeText
		}
	}
	filterFields<T>(item: T, compareFields?: CompareFieldList): T {
		if (!compareFields?.length) return item
		const res: T = {} as T
		compareFields.forEach((fld) => {
			const fldName = typeof fld == 'string' ? fld : fld.field
			const ignoreCase = typeof fld == 'string' ? true : fld.ignoreCase
			// @ts-ignore
			const val = item[`${fldName}`]
			// @ts-ignore
			res[`${fldName}`] = ignoreCase && typeof val == 'string' ? val.toLowerCase() : val
		})
		return res
	}
	Item = (): T => {
		if (this.oldItem) return this.oldItem
		else if (this.newItem) return this.newItem
		else throw new Error(`Neither newItem or oldItem are defined`)
	}

	private getDiff() {
		if (!this.changes.length) return ''

		const res = this.changes.reduce((res, change) => {
			const op = change.op
			let line
			if (op == 'add') {
				line = `Add ${change.path}: ${toString(change.value)}`
			} else if (op == 'remove') {
				line = `Remove ${change.path}: was ${toString(change.oldVal)}`
			} else if (op == 'replace') {
				line = `Change ${change.path} from '${toString(change.oldVal)}' to '${toString(change.value)}'`
			} else {
				const action = op == 'move' ? 'Moved' : op == 'copy' ? 'Copied' : op == 'test' ? 'Test' : op == '_get' ? 'Get' : 'Unknown'
				line = `${action} ${change.path}`
			}
			res = AddTo(res, '\n', line)
			return res
		}, '')
		return res
	}
}
export type ReconciledItemList<T = any> = ReconciledItem<T>[]
