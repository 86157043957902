import { Box } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { Flex, FlexC } from './Flex'
import { looksLikeHtml } from '@app/utils'

interface ErrorViewProps {
	error: any
}
export const ErrorView = React.forwardRef(function Exception(props: ErrorViewProps, ref: any) {
	let { error } = props
	if (!error.message && error.error) error = error.error
	const [expanded, setExpanded] = useState(false)

	if (typeof error != 'object') return error.toString()

	const toggle = () => {
		setExpanded(!expanded)
	}

	const name = error.name ? error.name : 'Error'

	let title = `${name}: `
	let message
	if (looksLikeHtml(error.message)) {
		message = <div dangerouslySetInnerHTML={{ __html: error.message }} />
	} else if (error.message?.includes('\n')) {
		message = error.message
	} else if (error.message) {
		title = title + error.message
	}
	const nested = error.cause ? <ErrorView error={error.cause} /> : null

	if (title) title = title.slice(0, 500)
	if (message) message = message.slice(0, 500)

	const indicator = expanded ? <Minus /> : <Plus />
	return (
		<FlexC>
			<Flex onClick={toggle} cursor="pointer">
				{indicator}
				{title}
			</Flex>
			{message && <Box ml="0.5em">{message}</Box>}
			{expanded && error.stack && <CallStackView callStack={error.stack} />}
			{nested}
		</FlexC>
	)
})
function Minus() {
	return <Box width="0.5em">-</Box>
}
function Plus() {
	return <Box width="0.5em">+</Box>
}

interface CallStackViewProps {
	callStack: string
}
const CallStackView = React.forwardRef(function CallStack(props: CallStackViewProps, ref: any) {
	const { callStack } = props

	const lines = callStack.split('\n')
	return (
		<FlexC>
			{lines
				.map((line, idx) => {
					if (idx == 0) return null
					return (
						<Box key={idx} width="100%" ml={idx > 0 ? '1em' : '0px'} whiteSpace="pre" textAlign="left">
							{line}
						</Box>
					)
				})
				.filter(Boolean)}
		</FlexC>
	)
})
