export function sendKey(key: string, keyCode: number, ref: Element) {
	const keyboardEvent = new KeyboardEvent('keydown', {
		code: key,
		key: key,
		charCode: keyCode,
		keyCode: keyCode,
		view: window,
		bubbles: true
	})

	ref.dispatchEvent(keyboardEvent)
}
