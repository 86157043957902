import { Point } from './Point'

export interface IRect {
	x?: number
	y?: number
	width?: number
	height?: number
}
export class Rect implements IRect {
	x: number = 0
	y: number = 0
	width: number = 0
	height: number = 0
	constructor(objOrX?: any | number, y?: number, w?: number, h?: number) {
		if (typeof objOrX == 'object') {
			const { x = 0, y = 0, width = 0, height = 0 } = objOrX
			this.x = x
			this.y = y
			this.width = width
			this.height = height
		} else {
			if (objOrX) this.x = objOrX
			if (y) this.y = y
			if (w) this.width = w
			if (h) this.height = h
		}
	}
	contains(p: Point): boolean {
		return p.x >= this.x && p.x <= this.x + this.width && p.y >= this.y && p.y <= this.y + this.height
	}
}
