import anylogger from '@app/anylogger'

const log = anylogger('arrayUtils')

/**
 * What item to return if the currentItem is undefined
 */
type InitItem = '' | 'first' | 'last'
/**
 * Gets the next item in the array, based on the currentItem that is passed in.
 * @param arr The array
 * @param currentItem The current item
 * @param initItem (default: 'first') The item to return if currentItem is undefined ('first', 'last', or '' for undefined)
 * @param wrap (default: false) Whether to wrap to the beginning if at the end
 * @returns the next item in the array, undefined, or the initItem
 */
export function getNextArrayItem(arr: any[], currentItem: any, initPos: InitItem = 'first', wrap: boolean = false) {
	if (!arr?.length) return undefined
	if (!currentItem) return getInitPos(arr, initPos)
	let idx = arr.indexOf(currentItem)
	if (idx < 0) {
		console.error('Could not find current item in getNextArrayItem')
		return undefined
	}
	if (idx >= arr.length - 1) {
		log('oor', idx, wrap, arr)

		if (wrap) return arr[0]
		else return undefined
	}
	return arr[idx + 1]
}
/**
 * Gets the previous item in the array, based on the current item that is passed in.
 * @param arr The array
 * @param currentItem The current item
 * @param initItem (default: 'last') The item to return if currentItem is undefined ('first', 'last', or '' for undefined)
 * @param wrap (default: false) Whether to wrap to the end if at the beginning
 * @returns the previous item in the array, undefined, or the initItem
 */
export function getPrevArrayItem(arr: any[], currentItem: any, initPos: InitItem = 'last', wrap: boolean = false) {
	if (!arr?.length) return undefined
	if (!currentItem) return getInitPos(arr, initPos)
	let idx = arr.indexOf(currentItem)
	if (idx < 0) {
		console.error('Could not find current item in getPrevArrayItem')
		return undefined
	}
	if (idx <= 0) {
		log('oor', idx, wrap, arr)

		if (wrap) return arr[arr.length - 1]
		else return undefined
	}
	return arr[idx - 1]
}
function getInitPos(arr: any[], initPos: InitItem) {
	if (!arr?.length || initPos == '') return undefined
	if (initPos == 'first') return arr[0]
	else return arr[arr.length - 1]
}

export function transpose(matrix: any[][]) {
	if (!matrix.length) return []
	return matrix[0].map((col, i) => matrix.map((row) => row[i]))
}

export function getXLongestStrings(arr: (string | undefined)[], num: number) {
	let res: any[] = []
	if (!arr?.length) return res
	let threshold = 0

	const getIndexOfShortest = () => {
		let shortest = 1000000000
		let idx = -1
		for (let ii = 0; ii < res.length; ii++) {
			const el = res[ii]
			if (el.length < shortest) {
				idx = ii
				shortest = el.length
			}
		}
		return idx
	}
	const addElement = (el: string) => {
		if (res.length < num) res.push(el)
		else {
			// replace the shortest item
			let idx = getIndexOfShortest()
			if (idx < 0) throw new Error(`Could not find shortest`)

			res[idx] = el
		}
		// now reset the threshold with the new shortest
		let newIdx = getIndexOfShortest()
		if (newIdx < 0) throw new Error(`Could not find shortest`)
		threshold = res[newIdx].length
	}

	for (let ii = 0; ii < arr.length; ii++) {
		const el = arr[ii]

		if (!el || typeof el.length == 'undefined') continue
		if (el.length >= threshold) addElement(el)
	}
	return res
}
