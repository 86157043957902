import { CreateMenuItemFunction, MenuItemClick, TopMenuItems } from './TopMenuItems'
import anylogger from '@app/anylogger'
import React from 'react'
import { BoxProps } from '@mui/material'
import { IMenu, IMenuList, useMenu } from './MenuContext'

const log = anylogger('TopMenu')

interface TopMenuProps extends BoxProps {
	createMenuItem?: CreateMenuItemFunction
	items: IMenuList
	onMenuItemClick?: MenuItemClick
}

const TopMenu = React.forwardRef(function TopMenu(props: TopMenuProps, ref: any) {
	const { onMenuItemClick, ...rest } = props

	const menuItemClick = (item: IMenu) => {
		// log('ClickedMenu', item)

		if (onMenuItemClick) onMenuItemClick(item)
	}
	return <TopMenuItems onMenuItemClick={(item: IMenu) => menuItemClick(item)} {...props} />
})
export { TopMenu }
