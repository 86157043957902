export const enum FunctionEnum {
	CopyCode,
	Evaluate,
}
export interface CloneOptions {
	functions: FunctionEnum
}

export const clone = (o: any, options: CloneOptions = { functions: FunctionEnum.CopyCode }): any => {
	let res: any = undefined

	const type = typeof o
	if (type == 'object' && o != null) {
		res = cloneObject(o, options)
	} else if (type == 'function') {
		res = cloneFunction(o, options)
	} else {
		res = o
	}
	return res
}
export const cloneObject = (o: any, options: CloneOptions): any => {
	if (o == null || typeof o != 'object') throw new Error('o is null in cloneObject.  How did this happen?!')
	let res: any = {}
	if (Array.isArray(o)) {
		res = cloneArray(o, options)
	} else {
		Object.keys(o).forEach((key) => {
			res[key] = clone(o[key], options)
		})
	}
	return res
}
export const cloneArray = (o: any, options: CloneOptions): any => {
	if (!Array.isArray(o)) throw new Error('o is not an array.  How did this happen?!')
	const res = o.map((item) => {
		return clone(item, options)
	})
	return res
}
export const cloneFunction = (o: any, options: CloneOptions): any => {
	if (typeof o != 'function') throw new Error('o is not a function.  How did this happen?!')

	if (options.functions == FunctionEnum.CopyCode) {
		return o
	} else {
		if (o.length > 0)
			throw new Error(
				'You cannot clone an object that contains functions that require parameters if you use the Evaluate option',
			)

		return clone(o(), options)
	}
}
