import anylogger from '@app/anylogger'
import { useCallback } from 'react'
import { HTMLRef, MouseTouchEvent, MouseTouchEventHandler, createMouseEvent } from './mouseTouchUtils'
import { useEvent } from './useEvent'

const log = anylogger('useClickEvent')

/**
 * This hook calls the clickEvent when either the mouse is clicked or the device is tapped so that you can use one hook to control both
 * desktop and mobile devices.  This hook is not strictly necessary as the click HTML event already works for both mouse and touch,
 * but this hook returns the same {@link MouseTouchEvent} as the other Down/Up/Move events.
 * @param ref - The ref of the object you want the clickEvent for.
 * @param clickEvent - This callback gets fired when there is a click or a tap event.  The callback passes a {@link MouseTouchEvent} parameter.
 * @param allowPreventDefault - Whether returning true from the callback will call preventDefault on the originating event handler.
 * @param data - This data will be passed in the callback function parameter.
 */
export const useClickEvent = (ref: HTMLRef, clickEvent: MouseTouchEventHandler, allowPreventDefault?: boolean, data?: any) => {
	const click = useCallback(
		(e: any) => {
			if (clickEvent(createMouseEvent(e, data))) {
				if (allowPreventDefault) e.preventDefault()
				e.stopPropagation()
			}
		},
		[clickEvent, data, allowPreventDefault]
	)
	const options = allowPreventDefault ? { passive: false } : undefined
	useEvent(ref, 'click', click, options)
}
