import React, { useCallback, useEffect, useState } from 'react'
import anylogger from '@app/anylogger'
const log = anylogger('useCurrentRef')

/**
 * This hook returns the browser document in browserDocument
 * and the browser window in browserWindow if executing on the client,
 * and undefined otherwise.  It is useful when passing document or window
 * to another hook so that you do not have to add conditional logic when
 * creating the hook.  You just pass the returned values, which will be
 * undefined if executing on the server, and the target hook can bypass the functionality.
 */
export function useBrowserDocWin(): { browserDocument: Document | null; browserWindow: Window | null } {
	const isBrowser = typeof window !== 'undefined'
	const browserDocument = isBrowser ? document : null
	const browserWindow = isBrowser ? window : null
	return { browserDocument, browserWindow }
}
