import React, { memo } from 'react'
import anylogger from '@app/anylogger'
import { Typography, TypographyProps } from '@mui/material'

const log = anylogger('Text')

interface TextProps extends TypographyProps {}

export const Text = memo(
	React.forwardRef(function Text(props: TextProps, ref: any) {
		return <Typography ref={ref} variant="inherit" {...props} />
	})
)
export const H1 = React.forwardRef(function H1(props: TextProps, ref: any) {
	return <Typography ref={ref} variant="h1" {...props} />
})
export const H2 = React.forwardRef(function H2(props: TextProps, ref: any) {
	return <Typography ref={ref} variant="h2" {...props} />
})
export const H3 = React.forwardRef(function H3(props: TextProps, ref: any) {
	return <Typography ref={ref} variant="h3" {...props} />
})
export const H4 = React.forwardRef(function H4(props: TextProps, ref: any) {
	return <Typography ref={ref} variant="h4" {...props} />
})
export const H5 = React.forwardRef(function H5(props: TextProps, ref: any) {
	return <Typography ref={ref} variant="h5" {...props} />
})
export const H6 = React.forwardRef(function H6(props: TextProps, ref: any) {
	return <Typography ref={ref} variant="h6" {...props} />
})
