import { useMediaQuery, useTheme, Breakpoint } from '@mui/material'
import { useEffect, useState } from 'react'
import { useWindowSize } from '@app/hooks'
import anylogger from '@app/anylogger'
import { getCurrentBreakpoint } from './getCurrentBreakpoint'

const log = anylogger('useCurrentBreakpoint')

/**
 * You can use this to check if useMediaQuery matches a particular breakpoint
 * @param breakpoint Your custom breakpoint name
 * @returns A boolean indicating if the current breakpoint is in effect
 */
export function useCurrentBreakpoint(): Breakpoint | undefined {
	const theme = useTheme()

	const [breakpoint, setBreakpoint] = useState<Breakpoint | undefined>(undefined)
	const size = useWindowSize()
	useEffect(() => {
		if (!size.width) return
		const bkp = getCurrentBreakpoint(theme.breakpoints, size.width)
		setBreakpoint(bkp)
	}, [theme.breakpoints, size])

	return breakpoint
}
