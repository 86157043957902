export interface IPoint {
	x?: number
	y?: number
}
export class Point implements IPoint {
	constructor(objOrX?: any | number, y?: number) {
		if (typeof objOrX == 'object') {
			const { x = 0, y = 0 } = objOrX
			this.x = x
			this.y = y
		} else {
			if (objOrX) this.x = objOrX
			if (y) this.y = y
		}
	}
	x: number = 0
	y: number = 0
}
