import { zeroPad } from './StrUtils'
import anylogger from '@app/anylogger'

const log = anylogger('dateUtils')

/***
 * date.toISOString() will produce an RFC3339 date
 * new Date(str) will parse the date string (especially an ISO date, but also other formats) to a date object
 */

export interface DateTime {
	yyyy: number
	yy: number
	mm: number
	mmm: string
	mmmm: string
	dd: number
	hh: number
	nn: number
	ss: number
	zzz: number
	wd: number
	wds: string
	wdl: string
}

export const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export const longMonths = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
]
export const shortDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const longDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export function normalizeTime(date: Date | string | number) {
	if (typeof date == 'string') {
		if (date.indexOf('T') < 0) date = date + 'T00:00'
	}
	return new Date(date)
}
export const parseDateTime = (date: Date | string): DateTime => {
	date = normalizeTime(date)
	const dateTime = {
		mmmm: longMonths[date.getMonth()],
		mmm: shortMonths[date.getMonth()],
		yyyy: date.getFullYear(),
		yy: date.getFullYear() % 100,
		mm: date.getMonth(),
		dd: date.getDate(),
		hh: date.getHours(),
		nn: date.getMinutes(),
		ss: date.getSeconds(),
		ms: date.getMilliseconds(),
		zzz: date.getMilliseconds(),
		wds: shortDays[date.getDay()],
		wdl: longDays[date.getDay()],
		wd: date.getDay()
	}
	return dateTime
}

export const formatDateTime = (date: Date | string | number, format: string = 'yyyy-mm-dd hh:nn:ss') => {
	if (!date) return ''
	let dt: Date
	dt = normalizeTime(date)
	// I've never seen this syntax before, but it seems to work
	if (!dt) return ''
	const dateTime = parseDateTime(dt)
	let res = format.toLocaleLowerCase()
	for (let [key, value] of Object.entries(dateTime)) {
		if (key == 'mm') value = value + 1
		// log('key,value', key, value)

		// if (key == 'yy') {
		// 	if (res.search('yyyy') >= 0) res = res.replace('yyyy', zeroPad(value, 4))
		// 	else if (res.search('yy') >= 0) res = res.replace('yy', zeroPad(value % 100, 2))
		// }
		res = res.replace(key, zeroPad(value, 2))
		// log('res', res)
	}
	return res
}

export function daysDiff(first: Date, second: Date) {
	// Take the difference between the dates and divide by milliseconds per day.
	// Round to nearest whole number to deal with DST.
	const d1 = first as unknown as number
	const d2 = second as unknown as number
	return Math.round((d2 - d1) / (1000 * 60 * 60 * 24))
}
export function secsDiff(first: Date, second: Date) {
	// Take the difference between the dates and divide by milliseconds per day.
	// Round to nearest whole number to deal with DST.
	const d1 = first as unknown as number
	const d2 = second as unknown as number
	return Math.round((d2 - d1) / 1000)
}
export function adjustDate(date: Date | string, days: number) {
	const res = normalizeTime(date)
	res.setDate(res.getDate() + days)
	return res
}
export function adjustMonth(date: Date | string, months: number) {
	const res = normalizeTime(date)
	res.setMonth(res.getMonth() + months)
	return res
}

export function datesAreEqual(date1: DateTime, date2: DateTime) {
	return date1.yy == date2.yy && date1.mm == date2.mm && date1.dd == date2.dd
}
