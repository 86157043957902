import anylogger from '@app/anylogger'

const log = anylogger('ObjectAdapter')

export interface FieldTranslation {
	srcField: string
	dstField: string
	adapterType?: typeof ObjectAdapter
}
export type FieldTranslationList = FieldTranslation[]

export class ObjectAdapter {
	fieldTranslations: FieldTranslationList = []
	cachedAdapters: any = {}
	constructor(cache?: any) {
		if (cache) this.cachedAdapters = cache
	}
	adaptList(items: any[]): any[] {
		return items
			.map((item) => {
				return this.adaptItem(item)
			})
			.filter(Boolean)
	}
	canAdapt(src: any) {
		return true
	}
	adaptItem(src: any): any {
		let dst: any = {}
		if (!this.canAdapt(src)) return undefined
		const translations = this.fieldTranslations
		translations.forEach((tran) => {
			this.translateField(tran, src, dst)
		})
		return dst
	}
	translateField(tran: FieldTranslation, src: any, dst: any) {
		const srcVal = this.getSourceVal(src, tran)
		// we don't want to adapt empty strings or objects, but we do want to adapt 0
		if (srcVal || typeof srcVal == 'number') {
			let val = undefined
			if (tran.adapterType) {
				const adapter = this.createAdapter(tran.adapterType)

				if (Array.isArray(srcVal)) val = adapter.adaptList(srcVal)
				else val = adapter.adaptItem(srcVal)
			} else val = srcVal
			dst[tran.dstField] = val
		}
	}
	getSourceVal(src: any, tran: FieldTranslation): any {
		let res = src
		const flds = tran.srcField.split('.')
		flds.forEach((fld) => {
			if (res) res = res[fld]
		})
		return res
	}
	createAdapter(adapterType: typeof ObjectAdapter) {
		let res = this.cachedAdapters[adapterType.toString()]
		if (!res) {
			res = new adapterType(this.cachedAdapters)
			this.cachedAdapters[adapterType.toString()] = res
		}
		return res
	}
	addFieldTranslations(items: any[][]) {
		items.forEach((item) => {
			if (item.length < 2) throw new Error(`Translation items must have at least 2 members: ${item}`)
			let tran = this.createTran(item[0], item[1])
			if (item.length > 2) tran.adapterType = item[2]
			this.fieldTranslations.push(tran)
		})
	}

	createTran(srcField: string, dstField: string): FieldTranslation {
		return { srcField: srcField, dstField: dstField }
	}
}
