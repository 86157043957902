import anylogger from '@app/anylogger'
import { NestedError } from './NestedError'
import { simpleFetch } from './simpleFetch'

const log = anylogger('ClientProxy')

export class ClientProxyHandler {
	apiRoot: string
	constructor(apiRoot: string) {
		this.apiRoot = apiRoot
	}
	// this overrides the getter of the api methods.
	// It returns any function that accepts a params object and then makes an api call
	// with the method name as the path of the api request and passes the params object as part of the body
	get(target: any, prop: any, receiver: any) {
		return (...params: any) => {
			const api = this.apiRoot + '/' + prop
			return this.getData(api, params)
		}
	}

	async getData(url: string, params?: any, returnType: string = 'json'): Promise<any> {
		const options = {
			method: 'POST',
			body: JSON.stringify(params)
		}
		let logParams = undefined
		if (process.env.NODE_ENV == 'development' && params) {
			logParams = params.map((val: any) => {
				let res = val
				if (typeof val == 'string') {
					res = val.slice(0, 50)
					if (val.length > 50) res += '...'
				}
				return res
			}, [])
		}

		log(url, logParams)

		try {
			return await simpleFetch(url, options).then((results) => {
				// log.debug(url, 'returned', results)
				return results
			})
		} catch (err) {
			const str = `Error placing the client request for url: ${url}`
			log.error(str, err)
			throw new NestedError(str, err)
		}
	}
}

export class ClientProxy {
	constructor(target: any, apiRoot: string) {
		return new Proxy(target, new ClientProxyHandler(apiRoot))
	}
}
