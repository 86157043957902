import React, { RefObject, useCallback, useEffect, useState } from 'react'
import anylogger from '@app/anylogger'
import { Point, Rect, logRenderReason } from '@app/utils'

const log = anylogger('useMouseMoveVsibility')

type SetArrayRef = (ref: HTMLElement, index: number) => void

interface MouseMoveVisibilityOptions {
	/// The number of milleseconds after which to set visible to false
	hideAfter?: number
	stayVisibleIfOnComponent?: boolean
}

export function useMouseMoveVisibility(
	containerRef: HTMLElement | undefined,
	refs: (HTMLElement | undefined)[],
	options: MouseMoveVisibilityOptions = {}
): boolean {
	// default to true so that if this refreshes and the mouse is on the container it will work.
	// If the mouse is not on the container, then nothing will happen as it will not get mouse move messages
	const [hasMouse, setHasMouse] = useState(true)

	const [visible, setVisible] = useState(false)
	const [mousePos, setMousePos] = useState(new Point())
	const { hideAfter = 0, stayVisibleIfOnComponent = false } = options

	const touchStart = useCallback((e: TouchEvent) => {
		if (!e.targetTouches.length) return
		const t = e.targetTouches[0]
		setVisible(true)
	}, [])

	const touchEnd = useCallback((e: TouchEvent) => {
		if (!e.targetTouches.length) setVisible(false)
	}, [])

	const mouseMove = useCallback((e: MouseEvent) => {
		// log('mouseMove', new Point(e.clientX, e.clientY))
		setMousePos(new Point(e.clientX, e.clientY))
	}, [])
	const mouseEnter = useCallback((e: MouseEvent) => {
		setHasMouse(true)
	}, [])
	const mouseLeave = useCallback((e: MouseEvent) => {
		// log('---mouseLeave', new Point(e.clientX, e.clientY))
		// it is not enough to just set visible to false because there could be latent mouseMove messages coming in after the mouseLeave
		// so we also have to indicate that we left the container and ignore any mouse messages if we do not haveMouse
		setHasMouse(false)
		setVisible(false)
	}, [])

	useEffect(() => {
		if (!containerRef) return () => {}

		if ('ontouchstart' in window) {
			containerRef.addEventListener('touchstart', touchStart, { passive: true })
			containerRef.addEventListener('touchend', touchEnd)
		} else {
			containerRef.addEventListener('mousemove', mouseMove)
			containerRef.addEventListener('mouseleave', mouseLeave)
			containerRef.addEventListener('mouseenter', mouseEnter)
		}
		return () => {
			if (!containerRef) return
			if ('ontouchstart' in window) {
				containerRef.removeEventListener('touchstart', touchStart)
				containerRef.removeEventListener('touchend', touchEnd)
			} else {
				containerRef.removeEventListener('mousemove', mouseMove)
				containerRef.removeEventListener('mouseleave', mouseLeave)
				containerRef.removeEventListener('mouseenter', mouseEnter)
			}
		}
	}, [containerRef, mouseEnter, mouseLeave, mouseMove, touchEnd, touchStart])

	useEffect(() => {
		if (!hasMouse) return undefined
		const isOnElement = (p: Point) => {
			for (let ii = 0; ii < refs.length; ii++) {
				const ref = refs[ii]
				if (!ref) continue
				const r: Rect = new Rect(ref.getBoundingClientRect())
				if (r.contains(p)) return true
			}
			return false
		}

		setVisible(true)
		if (hideAfter) {
			const id = setTimeout(() => {
				if (stayVisibleIfOnComponent && isOnElement(mousePos)) {
					return
				}
				setVisible(false)
			}, hideAfter)
			return () => clearTimeout(id)
		}
		return undefined
	}, [hasMouse, hideAfter, mousePos, refs, stayVisibleIfOnComponent])

	return visible
}
