import { useEffect, useState, useCallback } from 'react'
import anylogger from '@app/anylogger'

const log = anylogger('useDependency')

export type SetterFunction<T> = () => Promise<T> | T
export function useDependencyWithRefresh<T>(
	dependencies: any[],
	setter: SetterFunction<T | undefined>,
	initialValue: T | undefined = undefined,
	name: string = ''
): [T | undefined, () => Promise<T | undefined>] {
	const [state, setState] = useState<T | undefined>(initialValue)

	const getValue = useCallback(async (): Promise<T | undefined> => {
		// if any of the dependencies are undfined, then set the return value to undefined
		for (let ii = 0; ii < dependencies.length; ii++) {
			if (!dependencies[ii]) return initialValue
		}
		const res = setter()

		if (res instanceof Promise) return await res
		else return res
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...dependencies, initialValue, setter])

	useEffect(() => {
		getValue().then((val) => setState(val))
	}, [getValue])

	const doRefresh = async () => {
		const val = await getValue()
		setState(val)
		// log('refresh got value', val)

		return val
	}
	// if (name == 'branches') log('branches state', state)

	return [state, doRefresh]
}
