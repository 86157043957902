import anylogger from '@app/anylogger'

const log = anylogger('saveFile')

export function saveFile(filename: string, data: string | ArrayBuffer) {
	const blob = new Blob([data], { type: 'text/csv' })
	const elem = window.document.createElement('a')
	const url = window.URL.createObjectURL(blob)
	elem.href = url
	elem.download = filename
	document.body.appendChild(elem)
	elem.click()
	setTimeout(function () {
		document.body.removeChild(elem)
		window.URL.revokeObjectURL(url)
	}, 0)
}
export function saveUrlToFile(filename: string, url: string) {
	const elem = window.document.createElement('a')
	elem.href = url
	elem.download = filename
	document.body.appendChild(elem)
	elem.click()
	setTimeout(function () {
		document.body.removeChild(elem)
		window.URL.revokeObjectURL(url)
	}, 0)
}
