export interface EqualsOptions {
	allowTruthy?: boolean
	evaluateFunctions?: boolean
	showAllChanges?: boolean
	errorCallback?: (error: string) => void
}

interface PropertyMatchOptions extends EqualsOptions {
	allowMissingProps?: boolean
}
/**
 * This functions returns true if both objects have equal values, recursively.
 * @param obj1 - the first object
 * @param obj2 - the second object
 * @param options - {@link EqualsOptions}
 * * allowTruthy - (default: true) if true, allows similar object to compare as true.  i.e. 0=="0"
 * * evaluateFunctions - (default: false) if true, evaluates a function before doing the comparison
 * * showAllChanges - (default: false) if true, evaluates all props and objects. Normally, the function returns as soon as
 *  a difference is found.  This can be used to report all  the differences between two objects in the errorCallback function
 * * errorCallback - A function that is called every time a difference is found.  Normally it is just called the first time a difference
 * is found if showAllChanges is false.
 * @returns true if the objects are equal and false otherwise
 */
export const equals = (
	obj1: any,
	obj2: any,
	options: EqualsOptions = { allowTruthy: true, showAllChanges: false }
): boolean => {
	options = { allowTruthy: true, showAllChanges: false, ...options }

	let o1 = obj1
	let o2 = obj2
	const t1 = typeof o1
	const t2 = typeof o2

	if (options.evaluateFunctions) {
		if (t1 == 'function') {
			if (o1.length > 0) throw new Error('functions that require parameters cannot be evaluated')
			o1 = obj1()
		}
		if (t2 == 'function') {
			if (o2.length > 0) throw new Error('functions that require parameters cannot be evaluated')
			o2 = obj2()
		}
	}

	if (o1 === o2) return true
	if (options.allowTruthy && o1 == o2) return true

	let res = false
	if (t1 == 'object' && t2 == 'object') {
		// let reason = ''
		// const fr = (str: string) => {
		// 	reason = str
		// }
		// res = objectEquals(o1, o2, { ...options, failureReason: fr })
		res = objectEquals(o1, o2, { ...options })
		// if (!res && options.)
		return res
	}
	addError(options, `o1 (${o1}) does not equal o2 (${o2})`)
	return false
}

export const propertiesMatch = (
	propNamesToMatch: string[],
	o1: any,
	o2: any,
	options: PropertyMatchOptions = { allowTruthy: true, allowMissingProps: false }
) => {
	if (!propertiesMatch) throw new Error(`You must specify at least one value in the propNamesToMatch parameter`)

	let result = true
	for (let ii = 0; ii < propNamesToMatch.length; ii++) {
		const pn = propNamesToMatch[ii]
		// console.log('propVals', o1[pn], o2[pn])
		// we just have to check one object for a missing prop because even if the other is not null, it will not match
		if (!options.allowMissingProps && (o1[pn] == null || typeof o1[pn] in ['undefined'])) {
			addError(options, `Cannot find property "${pn}" on object(s)`)
			result = false
			if (!options.showAllChanges) return false
		}
		if (!equals(o1[pn], o2[pn], options)) {
			result = false
			if (!options.showAllChanges) return false
		}
	}
	return result
}

const objectEquals = (o1: any, o2: any, options: EqualsOptions): boolean => {
	// No need to check for arrays, because arrays can be treated like objects, where their "properties" or keys are the indexes of the array
	/*
			if either object is an array, we will either check arrays
			if (Array.isArray(o1) || Array.isArray(o2)) {
				// if either object is NOT an array, then we will not compare an array to a non-arrayEquals
				if (!Array.isArray(o1) || !Array.isArray(o2)) {
					addError(options, `type of o1: '${typeof o1}' does not match typeof o2: '${typeof o2}'`)
					return false
				}
				return arrayEquals(o1, o2, options)
			}
	*/

	let result = true
	// at this point the 2 objects are not even thruthy and they are not arrays, so if one is null or undefined, the other must not be.
	if (!o1) {
		addError(options, `o1: '${o1}' does not match o2: '${o2}'`)
		result = false
		if (!options.showAllChanges) return false
	}

	const o1Keys = Object.keys(o1)
	if (o1Keys.length != Object.keys(o2).length) {
		addError(
			options,
			`property count of o1: '${o1Keys.length}' does not match property count of o2: '${Object.keys(o2).length}'`
		)
		result = false
		if (!options.showAllChanges) return false
	}
	for (let ii = 0; ii < o1Keys.length; ii++) {
		const key = o1Keys[ii]
		const failure = (error: string) => {
			if (Array.isArray(o1)) addIndexError(options, `[${key}]`, error)
			else addIndexError(options, `.${key}`, error)
		}
		if (!equals(o1[key], o2[key], { ...options, errorCallback: failure })) {
			result = false
			if (!options.showAllChanges) return false
		}
	}
	if (options.showAllChanges) {
		const o2Keys = Object.keys(o2)
		for (let ii = 0; ii < o2Keys.length; ii++) {
			const key = o2Keys[ii]
			const failure = (error: string) => {
				if (Array.isArray(o1)) addIndexError(options, `[${key}]`, error)
				else addIndexError(options, `.${key}`, error)
			}
			if (typeof o1[key] == 'undefined') failure(`: o1 does not have property ${key}`)
		}
	}
	return result
}

const addError = (options: EqualsOptions, error: string) => {
	if (!options.errorCallback) return
	options.errorCallback(': ' + error)
}
const addIndexError = (options: EqualsOptions, separator: string, error: string) => {
	if (!options.errorCallback) return
	options.errorCallback(separator + error)
}
