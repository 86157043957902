import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import anylogger from '@app/anylogger'
import {
	Dialog as MuiDialog,
	useThemeProps,
	DialogProps as MuiDialogPros,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Paper,
	PaperProps
} from '@mui/material'
import { Flex } from './Flex'
import { Guid } from '@app/utils'
import Draggable from 'react-draggable'

const log = anylogger('Dialog')

interface DialogProps extends Omit<MuiDialogPros, 'title' | 'content'> {
	/** The title text or component */
	title?: string | ReactNode
	/** The content text or component */
	content?: string | ReactNode
	/**
	 * Whether there should be dividers between the title and the action buttons. You can specify it here on the main
	 * component, instead of on the DialogContent component which is hidden because of the content prop.
	 */
	dividers?: boolean
	/** Whether the title is draggable (Uses react-draggable) */
	draggable?: boolean
	//
	children?: ReactNode
}
/**
 * A simple wrapper around the Mui Dialog component.  It provides title and content props, both of which can be text
 * or components.  Html ids are generated for both of these and linked to the Dialog's aria-labelledby and aria-describedby respectively.
 *
 * This purely a convenience for specifying the title and content in props.  It does not prevent you from adding as many DialogTitle
 * and DialogContent children as you want as children in the dialog.
 *
 * It uses the same theme css overrides as the standard Mui Dialog component
 */
export const Dialog = React.forwardRef(function Dialog(inProps: DialogProps, ref: any) {
	const props = useThemeProps({ props: inProps, name: 'Dialog' })
	const { title, content, open, onClose, dividers, draggable, PaperComponent, children, ...rest } = props

	if (draggable && !title) console.warn('draggable will not work if no title is specified for the dialog')
	const [titleId] = useState('id' + Guid())
	const [contentId] = useState('id' + Guid())

	const draggableProps = draggable ? { style: { cursor: 'move' } } : {}
	const titleComp = title && (
		<DialogTitle id={titleId} {...draggableProps}>
			{title}
		</DialogTitle>
	)

	const contentComp = content && (
		<DialogContent dividers={dividers}>
			{typeof content == 'string' ? <DialogContentText id={contentId}>{content}</DialogContentText> : content}
		</DialogContent>
	)

	const [paperProp, setPaperProp] = useState<any>({})

	useEffect(() => {
		if (!draggable && !PaperComponent) return

		if (draggable) {
			setPaperProp({
				PaperComponent: (props: PaperProps): any => {
					const pc = PaperComponent ? PaperComponent : <Paper {...props} />
					return (
						// @ts-ignore
						<Draggable handle={'#' + titleId} cancel={'[class*="MuiDialogContent-root"]'}>
							{/* @ts-ignore  Added this when moving to React 18/Next 14*/}
							{pc}
						</Draggable>
					)
				}
			})
		} else if (PaperComponent) setPaperProp({ PaperComponent: PaperComponent })
	}, [draggable, PaperComponent, titleId])

	return (
		<MuiDialog open={open} onClose={onClose} aria-labelledby={titleId} aria-describedby={contentId} {...rest} {...paperProp}>
			{titleComp}
			{contentComp}
			{children}
		</MuiDialog>
	)
})
