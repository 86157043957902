import { styled } from '@mui/material'
import anylogger from '@app/anylogger'
import { Flex } from './Flex'

const log = anylogger('SplitFlexStyle')

export const SplitFlexRoot = styled(Flex, {
	name: 'SplitFlex',
	slot: 'Root',
	shouldForwardProp: (propName) => {
		// ToDo: Need to verify this is correct
		return propName !== 'minPrimary' && propName !== 'minSecondary' && propName !== 'initialSize'
	},
	// This takes the theme's styleOverrides and applies them to this styled comp
	// in this case, it is root, but can be other styleOverrides key

	overridesResolver: (props, styles) => [styles.root]
	// overridesResolver: (props, styles) => {
	// 	const { ownerState } = props

	// 	return [
	// 		styles.root,
	// 		styles[ownerState.variant],
	// 		!ownerState.square && styles.rounded,
	// 		ownerState.variant === 'elevation' && styles[`elevation${ownerState.elevation}`],
	// 	]
	// },
})(({ theme }) => ({
	// we can only use css primitives here, not shortcuts such ass fill or scroll, mx, etc
	// we don't need to use these primitives here as we are using defaultProps to control
	// fill, scroll and f for orientation
	// flex: '1 1 auto',
	// overflow: 'auto',
}))

export const SplitFlexSplitter = styled(Flex, {
	name: 'SplitFlex',
	slot: 'Splitter',
	overridesResolver: (props, styles) => styles.splitter
})(({ theme }) => {
	// for some reason, Storybook (even with a @mui/styles ThemeProvider) does not provide a theme here, so we have silly default values
	// console.log('theme', theme)

	return {
		backgroundColor: theme.palette?.primary?.light ?? 'gray',
		border: `1px solid ${theme.palette?.primary?.dark ?? 'black'}`
	}
})

export const SplitFlexPrimary = styled(Flex, {
	name: 'SplitFlex',
	slot: 'Primary',
	overridesResolver: (props, styles) => styles.primary
})(({ theme }) => {
	return {}
})
export const SplitFlexSecondary = styled(Flex, {
	name: 'SplitFlex',
	slot: 'Secondary',
	overridesResolver: (props, styles) => styles.secondary
})(({ theme }) => {
	return {}
})
