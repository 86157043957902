import { useCallback } from 'react'
import { useEvent } from './useEvent'
import anylogger from '@app/anylogger'
import { HTMLRef } from './mouseTouchUtils'

const log = anylogger('useContextMenu')

export function useContextMenu(element: HTMLRef, callback: (e: any) => void) {
	if (!callback) throw new Error(`You must specify a callback handler`)
	const handler = useCallback(
		(e: any) => {
			callback(e)
			e.preventDefault()
			e.stopPropagation()
			return false
		},
		[callback]
	)
	useEvent(element, 'oncontextmenu', handler, { passive: false })
}
