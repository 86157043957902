import anylogger from '@app/anylogger'

const log = anylogger('Observable')

export class Observable<T> {
	subscriptions: any[]
	constructor() {
		this.subscriptions = []
	}

	notify = (val: T) => {
		this.subscriptions.forEach((sub) => {
			try {
				return sub(val)
			} catch (err) {
				// if there is an exception calling an observer, remove it from the collections
				log.error('Removed observer due to exception:', err)
				this.unsubscribe(sub)
			}
		})
	}
	unsubscribe(callback: any) {
		this.subscriptions = this.subscriptions.filter((cb) => cb !== callback)
	}

	subscribe(callback: any) {
		this.subscriptions.push(callback)
		// this function is returned from the subscribe method.  Calling the returned method removes the subscription
		return () => {
			this.unsubscribe(callback)
		}
	}
}
