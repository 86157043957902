import React from 'react'
import anylogger from '@app/anylogger'
import { Flex } from './Flex'
import { FormControlLabel, Switch as MuiSwitch, SwitchProps as MuiSwitchProps, useThemeProps } from '@mui/material'

const log = anylogger('Switch')

interface SwitchProps extends MuiSwitchProps {
	label?: string | number | React.ReactElement
	labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
}
export const Switch = React.forwardRef(function Switch(inProps: SwitchProps, ref: any) {
	const props = useThemeProps({ props: inProps, name: 'Switch' })
	const { label, labelPlacement = 'end', ...rest } = props

	const cb = <MuiSwitch {...rest} />
	if (label) return <FormControlLabel label={label} labelPlacement={labelPlacement} control={cb} />
	return cb
})
