// changing the default name causes the buttonGroups and the AutoComplete drop doens not to be styled properly
// ClassNameGenerator.configure((componentName: string) => componentName.replace('Mui', ''))

import { simpleFetch } from '@app/clientserverutils'
import { KeyBinderService, useWindowSize } from '@app/hooks'
import { AppRoot, EditorService, ModalOverlay, PopupMenuOverlay, ToolTipOverlay, adjustFontSizes } from '@app/muiplus'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { PaletteMode, ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import anylogger from '@app/anylogger'
import { ColorModeContext } from 'components/ColorModeContext'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { SessionProvider } from 'next-auth/react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import * as React from 'react'
import Search from 'src/services/Search/Search'
import createEmotionCache from '../createEmotionCache'
import getTheme from '../theme/theme'
import { PermissionService } from 'src/services/usePermission'
import { PHASE_PRODUCTION_BUILD } from 'next/constants'
const log = anylogger('_app')

// NOTE: This does not work because the Next "root" element negates setting the body and html height to 100%
//       Therefore, we
// import GlobalStyles from '@mui/material/GlobalStyles'
// const globalStylesConst = (
// 	<GlobalStyles
// 		styles={{ html: { minHeight: '100%', height: '100%' }, body: { minHeight: '100%', height: '100%' } }}
// 	/>
// )

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

const switchableTheme = (mode: PaletteMode) => ({
	palette: {
		mode
	}
})
interface MyAppProps extends AppProps {
	emotionCache?: EmotionCache
}

let needsCheck = true

export default function MyApp(props: MyAppProps) {
	const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
	const [mode, setMode] = React.useState<PaletteMode>('light')
	const windowSize = useWindowSize()
	const environment = typeof window == 'undefined' ? 'server' : 'browser'
	const isBrowser = typeof window !== 'undefined'

	if (
		environment == 'server' &&
		needsCheck &&
		process.env.NODE_ENV != 'development' &&
		process.env.NEXT_PHASE != PHASE_PRODUCTION_BUILD
	) {
		log('Need to check')
		simpleFetch(`${process.env.NEXTAUTH_URL}/api/indexManager/checkIndexes`, { method: 'POST', body: '[]' })
			.then(() => {
				log('checked OK')

				needsCheck = false
			})
			.catch((err) => {
				log('Error checking indexes', err)
			})
		log('return from check')
	}
	const colorMode = React.useMemo(
		() => ({
			mode: mode,
			// The dark mode switch would invoke this method
			toggleColorMode: () => {
				setMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light'))
			}
		}),
		[mode]
	)
	// const bkp = useCurrentBreakpoint()
	// console.log('app bkp', bkp)

	// const themed = React.useMemo(() => createTheme(switchableTheme(mode), theme), [mode])
	const themed = React.useMemo(() => {
		let t = getTheme(mode)

		t = adjustFontSizes(t, windowSize.width, { mobile: 1.2, tablet: 1.2, laptop: 1.3, desktop: 1.3 })
		// console.log('t', t)

		return t
	}, [mode, windowSize.width])

	const logValue = '*=all'
	const logFormat = 'lvl:1 name:10 message perf'

	// console.log('rendering app', environment)
	log('rendering app', environment)

	if (isBrowser && localStorage && localStorage.getItem('log') !== logValue) {
		console.log('setting log to all')
		localStorage.setItem('log', logValue)
	}
	if (isBrowser && localStorage && localStorage.getItem('log_format') !== logFormat) {
		console.log('setting log Format to ', logFormat)
		localStorage.setItem('log_format', logFormat)
	}
	// @ts-ignore
	// log('pageProps.session', pageProps.session)
	// log('theme', themed)

	return (
		<CacheProvider value={emotionCache}>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<link rel="icon" href="/favicon.png" />
				<link rel="icon" href="/favicon-32.png" sizes="32x32" />
				<link rel="icon" href="/favicon-128.png" sizes="128x128" />
				<link rel="apple-touch-icon" href="/favicon-152.png" sizes="152x152" />
				<link rel="apple-touch-icon" href="/favicon-180.png" sizes="180x180" />
				<link rel="shortcut icon" href="/favicon-196.png" sizes="196x196" />
			</Head>
			<ColorModeContext.Provider value={colorMode}>
				<ThemeProvider theme={themed}>
					{/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
					{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
					<CssBaseline />
					<ToolTipOverlay>
						<AppRoot>
							<KeyBinderService>
								<ErrorBoundary>
									<EditorService>
										<ModalOverlay>
											<PopupMenuOverlay>
												{/* @ts-ignore */}
												<SessionProvider session={pageProps.session}>
													<Search>
														<PermissionService>
															{/* @ts-ignore */}
															<Component {...pageProps} />
														</PermissionService>
													</Search>
												</SessionProvider>
											</PopupMenuOverlay>
										</ModalOverlay>
									</EditorService>
								</ErrorBoundary>
							</KeyBinderService>
						</AppRoot>
					</ToolTipOverlay>
					{/* </LocalizationProvider> */}
				</ThemeProvider>
			</ColorModeContext.Provider>
		</CacheProvider>
	)
}
