import { Point } from '@app/utils'
import anylogger from '@app/anylogger'
import { useCallback, useRef, useState } from 'react'
import { ButtonType, HTMLRef, MouseTouchEvent, MouseTouchEventHandler, PrimaryButton } from './mouseTouchUtils'
import { useBrowserDocWin } from './useBrowserDocWin'
import { useClickEvent } from './useClickEvent'
import { useDownEvent } from './useDownEvent'
import { useMoveEvent } from './useMoveEvent'
import { useUpEvent } from './useUpEvent'

const log = anylogger('useClickController')

export interface MouseTouchUpMoveEvent extends MouseTouchEvent {
	downPos: Point
}
export type MouseTouchUpMoveEventHandler = (e: MouseTouchUpMoveEvent) => boolean

export interface PointerControllerProps {
	/**
	 * The ref that triggers the events based on the initial mouse down.  i.e. mouseDown/click events outside of this ref will not trigger any events.
	 */
	ref: HTMLRef
	/**
	 * If a double click occurs within this number of MS, then a click event will NOT be fired.  If it occurs outside of this time frame
	 * a click event WILL be fired, but a double click MAY not be, depending on the environment and actual delay between the clicks.
	 * Default: 250
	 */
	doubleClickInterval?: number
	/**
	 * This is called when the ref is clicked once
	 */
	click?: MouseTouchEventHandler
	/**
	 * This is called when the ref is double clicked.  The click event will not be called IF the doubleClick occurs within the {@link ButtonType}.doubleClickInterval from the props
	 */
	doubleClick?: MouseTouchEventHandler
	/**
	 * This is called when the mouse is clicked on the ref.  It does not matter what the client returns from this event
	 * because we have to return false to the useDownEvent handler.  If we return true, then touch scrolling will be completely disabled.
	 * The client has to use the return value of the moveEvent to dictate whether it wants to capture the move events or not.
	 */
	downEvent?: MouseTouchEventHandler
	/**
	 * This is fired whenever the mouse moves AFTER the user clicks on the ref, up until the mouse button is released.  If the client detects or intiates
	 * a drag operation, it should return true.  This can be used to trigger
	 * a drag or resize operation, where you do not want to initiate a drag until the item has been dragged past a certain threshold.
	 * It passes a {@link MouseTouchUpMoveEvent}  parameter.  You must return true if you want to prevent the default touch scrolling on mobile devices.
	 */
	moveEvent?: MouseTouchUpMoveEventHandler
	/**
	 * This is fired when the mouse button touch is released is released.  It passes a {@link MouseTouchUpMoveEvent}  parameter
	 */
	upEvent?: MouseTouchUpMoveEventHandler
}

/**
 * This hook combines mouse and touch events to provide a single amalgameted interface for click/touch events.
 * It uses the {@link useDownEvent},  {@link useMoveEvent}, {@link useUpEvent} and {@link useClickEvent} hooks internally so returns the same amalgamated MouseTouchEvent
 * for all of the callbacks, with the exception of moveEvent and UpEvent which return the {@link MouseTouchUpMoveEvent} which includes the downPos property,
 * which is the pos {@link Point} of the original downEvent.
 * Please see {@link PointerControllerProps} for the parameters and callback events.
 * NOTE: Mouse events are only fired for the primary buttion.
 * The benefits of using this over the above events individually are that some events are prevented from firing
 * so that only the appropriate events are fired, depending on the usage scenario. For example:
 * * The Click event is not fired if it is a DoubleClick
 * * The Click event is not fired if the upEvent returns true, meaning that the client has used the down/move events for something such as dragging/resizing.
 * * The Move and Up events are not fired if the down event has not fired yet.
 *
 * NOTE: The down and click handlers are attached to the passed in ref, but the move and up handlers are attached to the document because the mouse may move off the target control (ref) before the mouse is released.
 *
 * NOTE: for click and doubleClick events, a MouseEvent is returned in the callback parameter in all cases, instead of a TouchEvent for touch devices.
 */
export function usePointerController(props: PointerControllerProps): void {
	const { ref, doubleClickInterval = 250 } = props

	const { click, doubleClick, downEvent, moveEvent, upEvent } = props

	const [downPos, setDownPos] = useState<Point | undefined>()

	const downTimer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)
	const clickTimer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)
	const [cancelClick, setCancelClick] = useState(false)

	const doDownEvent = useCallback(
		(e: MouseTouchEvent) => {
			if (downEvent) return downEvent(e)
			return false
		},
		[downEvent]
	)
	const doMoveEvent = useCallback(
		(e: MouseTouchEvent, downPos: Point) => {
			if (moveEvent) {
				const ev = { ...e, downPos }
				return moveEvent(ev)
			}
			return false
		},
		[moveEvent]
	)
	const doUpEvent = useCallback(
		(e: MouseTouchEvent, downPos: Point) => {
			if (upEvent) {
				const ev = { ...e, downPos }
				return upEvent(ev)
			}
			return false
		},
		[upEvent]
	)
	const doClick = useCallback(
		(e: MouseTouchEvent) => {
			if (click) return click(e)
			return false
		},
		[click]
	)
	const doDoubleClick = useCallback(
		(e: MouseTouchEvent) => {
			if (doubleClick) return doubleClick(e)
			return false
		},
		[doubleClick]
	)

	const down = useCallback(
		(e: MouseTouchEvent) => {
			if (!e.isTouch && e.button != PrimaryButton) return false

			// becaues drag/resize operations (especially on touch devices) do not know if they want to capture the move
			// operations until a delay or some mouse movement, we can't determine from the mouseDown return value if
			// we should be capturing the mouse or not.  Therefore there is no point in trying to control the delay here, and that
			// only messes up any delays that the client may initiate.
			// In any case, we have to return false from this event, or the default touch scroll will not happen,
			// even if the client later decides that it does not want to capture the move events.
			// const timerId = setTimeout(() => {
			// 	downTimer.current = undefined
			// 	downLongerThanClickInterval(e)
			// }, 50)
			// if (downTimer.current) clearTimeout(downTimer.current)
			// downTimer.current = timerId

			if (doDownEvent(e)) {
				setDownPos(e.pos)
				// we MUST return false to or touch scrolling will be disabled
				// return true
			}
			return false
		},
		[doDownEvent]
	)

	const move = useCallback(
		(e: MouseTouchEvent) => {
			if (!downPos) return false
			if (doMoveEvent(e, downPos)) {
				// if the client is using the down and move events, it MUST return true, because if we don't return true here,
				// the touch scroll events will still proceed and it will mess with any drag operations
				return true
			}
			return false
		},
		[doMoveEvent, downPos]
	)

	const up = useCallback(
		(e: MouseTouchEvent) => {
			if (!e.isTouch && e.button != PrimaryButton) return false
			if (!downPos) return false
			// log('up')

			// We only want to cancel the up click when using a mouse.
			// With a mouse, the mouse click (from the click event) will persist even
			// after a long time. (i.e. after a drag event)
			// if (!e.isTouch) setCancelClick(true)

			const res = doUpEvent(e, downPos)
			setDownPos(undefined)

			// for some reason, even returning true for a non-passive  up (or touchEnd event) does not prevent the click event from happening.
			// so, we will allow the client to control if the click event happens by cancelling the click event if they return true from this handler
			// BUT, touch devices will NOT fire a click event after either a drag or a long click,
			// so we will only setCancelClick if it is NOT a touch device.  Otherwise the cancelClick state
			// will linger and the next touch click will be "cancelled".
			// We could also have auto-cleared cancelClick with a timer, but timing may be an issue on slower devices.
			if (res && !e.isTouch) {
				// log('SETTING cancelClick')

				setCancelClick(true)
			}

			return res
		},
		[doUpEvent, downPos]
	)

	const mouseClick = useCallback(
		(e: MouseTouchEvent) => {
			if (!e.isTouch && e.button != PrimaryButton) return false

			if (downTimer.current) clearTimeout(downTimer.current)
			downTimer.current = undefined

			if (cancelClick) {
				// log('cancelClick')
				setCancelClick(false)
				return false
			}

			// if (downPos) return false

			if (e.mouseEvent) {
				if (e.mouseEvent.detail == 1) {
					const timerId = setTimeout(() => {
						clickTimer.current = undefined
						doClick(e)
					}, doubleClickInterval)
					// this will probaly never happen, but it makes sense to clear an existing timout
					if (clickTimer.current) clearTimeout(clickTimer.current)
					clickTimer.current = timerId
				} else if (e.mouseEvent.detail == 2) {
					// log('dblClick')

					clearTimeout(clickTimer.current)
					doDoubleClick(e)
				}
			} else if (e.isTouch) {
				log('touchEvent', e.touchEvent)
				doClick(e)
			}
			return false
		},
		[cancelClick, doClick, doDoubleClick, doubleClickInterval]
	)

	const { browserDocument } = useBrowserDocWin()
	useDownEvent(ref, down, true)
	useMoveEvent(browserDocument, move, true)
	useUpEvent(browserDocument, up, true)
	useClickEvent(ref, mouseClick, false)
}
