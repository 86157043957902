import React, { Ref, useContext, useEffect, useState } from 'react'
import anylogger from '@app/anylogger'
import { Flex } from './Flex'
import { Point } from '@app/utils'
import { alpha, Box, Popper, styled } from '@mui/material'
import { appendOwnerState } from '@mui/base'

const log = anylogger('ToolTipOverlay')

function captureTip(x: number, y: number): [Element | undefined, string | null, string | null] {
	let el = document.elementFromPoint(x, y)
	while (el) {
		const t = el.getAttribute('tooltip')
		const p = el.getAttribute('tooltipPos')
		if (t) return [el, t, p]
		el = el.parentElement
	}
	return [undefined, '', null]
}

interface ToolTipProps {
	delay?: number
	children: React.ReactNode
}
let mousePos: Point = { x: 0, y: 0 }
let timerId: NodeJS.Timeout | undefined = undefined
let lastElement: Element | undefined = undefined
let capturedTip: string
let longTimerId: NodeJS.Timeout | undefined = undefined
let lastLongElement

/**
 * This component can be placed at the top of your component tree.  As the mouse moves, it looks for components
 * that have a "title" property (which is the default html tooltip prop).  If found, it converts the standard
 * html hover tooltip behaviour to use an MUI Popper to display the tooltip.  This means that you do not have to
 * wrap every element with a <Tooltip> component just to display a tooltip.
 *
 * NOTE: For typescript users, you need to add the following to a declarations.d.ts file that is included in your tsconfig so that
 * it does not complain about adding a tooltip property to any component:
 *
 * import 'react'
 * declare module 'react' {
 *     export interface HTMLAttributes<T> {
 *     tooltip?: any
 * 	}}
 */
export const ToolTipOverlay = React.forwardRef(function ToolTipOverlay(props: ToolTipProps, ref: any) {
	const { children, delay = 300 } = props
	const [tip, setTip] = useState('')
	const [placement, setPlacement] = useState<string | null>()
	const [isTouch] = useState(typeof window != 'undefined' && 'ontouchstart' in window)

	/******* This did not work beccause it only listed top level nodes that were removed, and not each descendant
	// const [ref, setRef] = useCurrentRef<HTMLElement>(passedRef)
	// const isServer: boolean = typeof window === 'undefined'
	// const domChanged = useCallback((mutationList, observer) => {
	// 	if (!lastElement) return

	// 	for (const mutation of mutationList) {
	// 		if (mutation.type == 'childList') {
	// 			const nodes = Array.from(mutation.removedNodes)
	// 			log('nodes', lastElement, nodes)

	// 			if (nodes.includes(lastElement)) {
	// 				log('removing')

	// 				lastElement = undefined
	// 			}
	// 		}
	// 	}
	// }, [])
	// const [observer] = useState(isServer ? undefined : new MutationObserver(domChanged))

	// useEffect(() => {
	// 	if (!ref || !observer) return

	// 	// Start observing the target node for configured mutations
	// 	observer.observe(ref, { subtree: true, childList: true })
	// 	log('connecting')

	// 	return () => {
	// 		log('disconnecting')
	// 		observer.disconnect()
	// 	}
	// }, [observer, ref])
******/

	// This is not doing anything
	// useEffect(() => {
	// 	const mouseEnter = (e: MouseEventInit) => {
	// 		const x = e.clientX
	// 		const y = e.clientY
	// 		if (!x || !y) return
	// 	}
	// 	// Bind the event listener
	// 	document.addEventListener('mouseenter', mouseEnter)
	// 	return () => {
	// 		// Unbind the event listener on clean up
	// 		document.removeEventListener('mouseenter', mouseEnter)
	// 	}
	// }, [])

	useEffect(() => {
		// This constantly keeps track of the mousePos so that we know where the mouse is when we hover
		// BUT, it stores the current location in a global variable, not state, so that it does not cause unnecessary refreshes.
		const mouseMove = (e: MouseEventInit) => {
			const x = e.clientX
			const y = e.clientY
			if (!x || !y) return

			mousePos = new Point({ x, y })
			if (isTouch) return

			if (timerId) clearTimeout(timerId)
			timerId = undefined

			// const [el, t] = captureTip(x, y)
			// if (tip && (!t || el != lastElement)) {
			// 	setTip('')
			// }
			// capturedTip = t
			// lastElement = el

			// if (capturedTip) log('t', capturedTip)

			const [el, t, p] = captureTip(x, y)
			// if (t) log('t', t, el)

			if (tip && (!t || el != lastElement)) {
				setTip('')
			}
			if (t && t != '' && t != tip) {
				lastElement = el
				// log('starting to show', t)
				timerId = setTimeout(() => {
					// log('showing', t)
					setTip(t)
					setPlacement(p)
				}, delay)
			}
		}
		// Bind the event listener
		document.addEventListener('mousemove', mouseMove)
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousemove', mouseMove)
		}
	}, [tip, delay, isTouch])

	useEffect(() => {
		const mouseLeave = (e: MouseEventInit) => {
			if (timerId) clearTimeout(timerId)
			timerId = undefined
			lastElement = undefined
			setTip('')
		}
		// Bind the event listener
		document.addEventListener('mouseleave', mouseLeave)
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mouseleave', mouseLeave)
		}
	}, [])

	// we don't need this because for some reason when you touch and release an element on a touch screen,
	// the mouseMove is triggered, and then shortly thereafter the tip is displayed
	useEffect(() => {
		const touchStart = (e: TouchEvent) => {
			if (!e.targetTouches.length) return
			const touch = e.targetTouches[0]

			if (longTimerId) clearTimeout(longTimerId)
			longTimerId = undefined
			const x = touch.clientX
			const y = touch.clientY
			mousePos = new Point({ x, y })

			const [el, t] = captureTip(x, y)

			if (tip && (!t || el != lastElement)) {
				setTip('')
			}
			if (t && t != '' && t != tip) {
				lastElement = el
				longTimerId = setTimeout(() => {
					setTip(t)
				}, delay)
			}
		}
		const touchEnd = (e: TouchEvent) => {
			if (longTimerId) clearTimeout(longTimerId)
			longTimerId = undefined
			setTip('')
		}

		// Bind the event listener
		document.addEventListener('touchstart', touchStart)
		document.addEventListener('touchend', touchEnd)
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('touchstart', touchStart)
			document.removeEventListener('touchend', touchEnd)
		}
	}, [delay, tip])

	const ownerState = {
		...props
	}
	const popperProps = appendOwnerState(TooltipPopper, {}, ownerState)
	const tooltipProps = appendOwnerState(TooltipTooltip, {}, ownerState)

	function calculatePosProps(x: number, y: number): any {
		let res: any = {
			anchorEl: lastElement
			// {
			// 	getBoundingClientRect: () => ({ width: 0, height: 0, left: x, top: y })
			// }
		}
		// instead of calculating where to place the tip, based on isTouch and pos in the screen,
		// just always show the tip above the element so that even the cursor is not in the way.

		// let px = 'start'
		// let py = isTouch ? 'top' : 'bottom'
		// const w = document.body.clientWidth
		// const h = document.body.clientHeight
		// if (x > w - 200) px = 'end'
		// if (y > h - 50) py = 'top'
		// res = { ...res, placement: `${py}-${px}` }

		if (placement == 'cursor') res = { anchorEl: { getBoundingClientRect: () => ({ width: 0, height: 0, left: x, top: y + 10 }) } }
		else res = { ...res, placement: 'top-start' }

		return res
	}
	function getToolTip() {
		// isConnected checks if the element is still live
		if (!tip || !lastElement || !lastElement.isConnected) return null

		const posProps = calculatePosProps(mousePos.x, mousePos.y)
		const style = window.getComputedStyle(lastElement, null)

		let fontSize = style.fontSize
		const getTip = () => {
			const lines = tip.split('\n')
			return (
				<div>
					{lines.map((line, idx) => {
						return (
							<div key={idx}>
								{line}
								<br />
							</div>
						)
					})}
				</div>
			)
		}
		return (
			<TooltipPopper open={true} {...posProps} {...popperProps}>
				<TooltipTooltip
					{...tooltipProps}
					sx={{
						fontSize: fontSize
					}}
				>
					{getTip()}
				</TooltipTooltip>
			</TooltipPopper>
		)
	}

	return (
		<Box position="relative">
			{children}
			{getToolTip()}
		</Box>
	)
})

const TooltipPopper = styled(Popper, {
	name: 'MuiTooltip',
	slot: 'Popper',
	overridesResolver: (props, styles) => {
		const { ownerState } = props

		return [
			styles.popper,
			!ownerState.disableInteractive && styles.popperInteractive,
			ownerState.arrow && styles.popperArrow,
			!ownerState.open && styles.popperClose
		]
	}
	// @ts-ignore
})(({ theme, ownerState, open }) => ({
	zIndex: theme.zIndex.tooltip,
	pointerEvents: 'none', // disable jss-rtl plugin
	...(!ownerState.disableInteractive && {
		pointerEvents: 'auto'
	}),
	...(!open && {
		pointerEvents: 'none'
	})
}))

const TooltipTooltip = styled('div', {
	name: 'MuiTooltip',
	slot: 'Tooltip',
	overridesResolver: (props, styles) => {
		const { ownerState } = props

		return [
			styles.tooltip,
			ownerState.touch && styles.touch,
			ownerState.arrow && styles.tooltipArrow
			//?? styles[`tooltipPlacement${capitalize(ownerState.placement.split('-')[0])}`],
		]
	}
	// @ts-ignore
})(({ theme, ownerState }) => ({
	backgroundColor: alpha(theme.palette.grey[700], 0.92),
	borderRadius: theme.shape.borderRadius,
	color: theme.palette.common.white,
	fontFamily: theme.typography.fontFamily,
	padding: '4px 8px',
	fontSize: theme.typography.pxToRem(11),
	// maxWidth: 300,
	margin: 2,
	wordWrap: 'break-word',
	fontWeight: theme.typography.fontWeightMedium,
	...(ownerState.arrow && {
		position: 'relative',
		margin: 0
	}),
	...(ownerState.touch && {
		padding: '8px 16px',
		fontSize: theme.typography.pxToRem(14),
		// ??lineHeight: `${round(16 / 14)}em`,
		fontWeight: theme.typography.fontWeightRegular
	})
	// [`.${tooltipClasses.popper}[data-popper-placement*="left"] &`]: {
	// 	transformOrigin: 'right center',
	// 	...(!ownerState.isRtl
	// 		? {
	// 				marginRight: '14px',
	// 				...(ownerState.touch && {
	// 					marginRight: '24px',
	// 				}),
	// 		  }
	// 		: {
	// 				marginLeft: '14px',
	// 				...(ownerState.touch && {
	// 					marginLeft: '24px',
	// 				}),
	// 		  }),
	// },
	// [`.${tooltipClasses.popper}[data-popper-placement*="right"] &`]: {
	// 	transformOrigin: 'left center',
	// 	...(!ownerState.isRtl
	// 		? {
	// 				marginLeft: '14px',
	// 				...(ownerState.touch && {
	// 					marginLeft: '24px',
	// 				}),
	// 		  }
	// 		: {
	// 				marginRight: '14px',
	// 				...(ownerState.touch && {
	// 					marginRight: '24px',
	// 				}),
	// 		  }),
	// },
	// [`.${tooltipClasses.popper}[data-popper-placement*="top"] &`]: {
	// 	transformOrigin: 'center bottom',
	// 	marginBottom: '14px',
	// 	...(ownerState.touch && {
	// 		marginBottom: '24px',
	// 	}),
	// },
	// [`.${tooltipClasses.popper}[data-popper-placement*="bottom"] &`]: {
	// 	transformOrigin: 'center top',
	// 	marginTop: '14px',
	// 	...(ownerState.touch && {
	// 		marginTop: '24px',
	// 	}),
	// },
}))
