import * as React from 'react'
import clsx from 'clsx'
import { useTreeItem } from '@mui/lab'
import { SyntheticEvent } from 'react'

/**
 * @ignore - internal component.
 */
export const TreeItemContentOnlyIconExpand = React.forwardRef(function TreeItemContent(props: any, ref: any) {
	const {
		classes,
		className,
		displayIcon,
		expansionIcon,
		icon: iconProp,
		label,
		nodeId,
		onClick,
		onMouseDown,
		...other
	} = props

	const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } =
		useTreeItem(nodeId)

	const icon = iconProp || expansionIcon || displayIcon

	const handleMouseDown = (event: SyntheticEvent<Element, Event>) => {
		preventSelection(event)

		if (onMouseDown) {
			onMouseDown(event)
		}
	}

	const handleClick = (event: SyntheticEvent<Element, Event>) => {
		// handleExpansion(event)
		handleSelection(event)

		if (onClick) {
			onClick(event)
		}
	}

	const iconClick = (event: SyntheticEvent<Element, Event>) => {
		handleExpansion(event)
	}

	return (
		/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions -- Key event is handled by the TreeView */
		<div
			className={clsx(className, classes.root, {
				[classes.expanded]: expanded,
				[classes.selected]: selected,
				[classes.focused]: focused,
				[classes.disabled]: disabled,
			})}
			ref={ref}
			{...other}
		>
			<div onClick={iconClick} className={classes.iconContainer}>
				{icon}
			</div>
			<div className={classes.label} onClick={handleClick} onMouseDown={handleMouseDown}>
				{label}
			</div>
		</div>
	)
})
