import { clone } from './clone'
const log = (...args: any) => {
	console.log(...args)
}
export function WrapObject<W>(sourceObj: any, wrappingType: new () => W): W {
	const obj = new wrappingType()
	return Object.create(sourceObj, GetFunctionDescriptors(sourceObj, obj))
}

export function filterToSpecificProps(obj: any, specificProps: string[]) {
	return Object.entries(obj).reduce((acc: any, entry) => {
		const [key, val] = entry
		if (specificProps.includes(key)) acc[key] = val
		return acc
	}, {})
}

/***
 * Here are the 3 types of objects you can pass in to getOwnPropertyNames and the results:
 * - an instance of an object - returns the instance properties, which include arrow functions
 * - the prototpye of an instance object (i.e. Object.getPrototypeOf(obj) - returns the instance functions
 * - the class name type itself (i.e. Wrapper) - this returns the static functions of the object
 */
// This function returns only the function properties, depending on the type of obj that is passed in as itemized above
const getFunctionProperties = (obj: any) => {
	const props = Object.getOwnPropertyNames(obj)
	return props.filter((prop) => typeof obj[prop] === 'function')
}
function GetFunctionDescriptors(data: any, obj: any) {
	const res: any = {}

	const addFunctions = (proto: any) => {
		const functions = getFunctionProperties(proto)

		// for (var propName of props) {
		for (var propName of functions) {
			const origDesc = Object.getOwnPropertyDescriptor(proto, propName)
			// make sure to clone the descriptor so that we don't change the original object
			const descriptor = clone(origDesc)
			if (!res[propName]) {
				descriptor.value.bind(data)
				res[propName] = descriptor
			} else {
				// log('skipping prop', propName)
			}
		}
		// iterate up the prototype chain to get ancestor functions, stopping at the source object
		proto = Object.getPrototypeOf(proto)
		if (proto && proto != Object.getPrototypeOf(data)) {
			addFunctions(proto)
		} else {
			// log('stopping at proto', proto)
		}
	}

	const functions = getFunctionProperties(obj)
	if (functions?.length)
		throw new Error(
			'Arrow functions are not supported because they cannot be bound. The following functions must be converted to instance methods:' +
				functions.join()
		)

	// this gets the prototype of the instance
	const proto = Object.getPrototypeOf(obj)

	addFunctions(proto)
	// log('res', res)

	return res
}
