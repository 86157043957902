import { getPrevArrayItem, getNextArrayItem } from '@app/utils'
import anylogger from '@app/anylogger'
import { useCallback, useState } from 'react'

const log = anylogger('useSelectionController')

export type SelectMode = 'none' | 'single' | 'multi'
export interface SelectionProps {
	list: any[]
	selectMode: SelectMode
	focused: any
	selected: any[]
	setFocused: (item: any) => void
	setSelected: (items: any[]) => void
}
export interface SelectionHandlers {
	selItemClick: (item: any, e: any) => void
	selKeyDown: (e: any) => void
}

/**
 *
 * @returns values and event handlers that can be attached to your multi-select control
 */
export function useSelectionController(props: SelectionProps): SelectionHandlers {
	let { list, selectMode, focused, selected, setFocused, setSelected } = props

	const [shiftPos, setShiftPos] = useState(undefined)

	// const doFocusChanged = useCallback(
	// 	(item: any) => {
	// 		focusChanged(item)
	// 	},
	// 	[focusChanged]
	// )
	// const doSelectionChanged = useCallback(
	// 	(items: any[]) => {
	// 		selectionChanged(items)
	// 	},
	// 	[selectionChanged]
	// )
	const selectRange = useCallback((list: any[], startItem: any, endItem: any) => {
		let startIdx = list.indexOf(startItem)
		let endIdx = list.indexOf(endItem)
		const start = Math.min(startIdx, endIdx)
		const end = Math.min(Math.max(startIdx, endIdx), list.length - 1)

		let sel = []
		for (let ii = start; ii <= end; ii++) {
			sel.push(list[ii])
		}
		return sel
	}, [])

	const selItemClick = useCallback(
		(item: any, e: any) => {
			if (!list.length) return
			e.preventDefault()
			e.stopPropagation()

			if (!e.shiftKey) {
				setShiftPos(undefined)
			}

			if (selectMode == 'none') return

			if (selectMode == 'multi' && (e.ctrlKey || e.shiftKey)) {
				if (e.ctrlKey) {
					if (selected.includes(item)) {
						setSelected(selected.filter((value: any) => value != item))
					} else {
						setSelected([...selected, item])
					}
				} else if (e.shiftKey) {
					const tmpShift = shiftPos ?? focused ?? list[0]
					if (!shiftPos) setShiftPos(tmpShift)

					const vals = selectRange(list, tmpShift, item)
					setSelected(vals)
				}
			} else {
				setSelected([item])
			}
			setFocused(item)
		},
		[focused, list, selectMode, selectRange, selected, setFocused, setSelected, shiftPos]
	)
	const selKeyDown = useCallback(
		(e: any) => {
			if (!list?.length) return
			if (!['ArrowUp', 'ArrowDown', 'Home', 'End'].includes(e.key)) return
			if (!e.shiftKey) {
				setShiftPos(undefined)
			}

			if (selectMode == 'multi' && e.shiftKey) {
				const tmpFocused = focused ?? list[0]
				const tmpShift = shiftPos ?? tmpFocused
				if (!shiftPos) setShiftPos(tmpShift)

				let newVal = undefined
				if (e.ctrlKey) {
					if (e.key == 'Home') newVal = list[0]
					else if (e.key == 'End') newVal = list[list.length - 1]
				} else if (e.key == 'ArrowUp') {
					newVal = getPrevArrayItem(list, tmpFocused, 'last')
				} else if ((e.key = 'ArrowDown')) {
					newVal = getNextArrayItem(list, tmpFocused, 'first')
				}

				if (!newVal) return
				setFocused(newVal)
				const vals = selectRange(list, newVal, tmpShift)
				setSelected(vals)
				return
			}

			if (e.key == 'ArrowUp') {
				const prev = getPrevArrayItem(list, focused, 'last')

				if (!prev) return
				setFocused(prev)
				if (selectMode != 'none') {
					setSelected([prev])
				}
			} else if (e.key == 'ArrowDown') {
				const next = getNextArrayItem(list, focused, 'first')
				if (!next) return
				setFocused(next)
				if (selectMode != 'none') {
					setSelected([next])
				}
			} else if (e.key == 'Home') {
				const next = list[0]
				setFocused(next)
				if (selectMode != 'none') {
					setSelected([next])
				}
			} else if (e.key == 'End') {
				const next = list[list.length - 1]
				setFocused(next)
				if (selectMode != 'none') {
					setSelected([next])
				}
			}
		},
		[list, selectMode, focused, shiftPos, selectRange, setSelected, setFocused]
	)

	return { selItemClick, selKeyDown }
}
