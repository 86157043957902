export interface ISize {
	width?: number
	height?: number
}
export class Size implements ISize {
	constructor(obj: any = null) {
		if (obj) {
			const { width = 0, height = 0 } = obj
			this.width = width
			this.height = height
		}
	}
	width: number = 0
	height: number = 0
}
