import { useEffect } from 'react'
import anylogger from '@app/anylogger'
import { Guid } from './Guid'

const log = anylogger('loadScript')

const loadScript = async (url: string) => {
	throw new Error(`Use next/script instead`)
	return new Promise<void>((resolve, reject) => {
		const script = document.createElement('script')
		const id = Guid()
		script.id = id
		script.src = url
		script.async = true

		document.body.appendChild(script)
		const elem = document.getElementById(id)
		if (elem)
			elem.addEventListener('load', () => {
				resolve()
			})
		else reject(`Could not load script: ${url}`)
		log('script loaded')
	})
}

export { loadScript }
