import React from 'react'
import anylogger from '@app/anylogger'
import { Paper, PaperProps } from '@mui/material'
import { Flex, FlexProps } from './Flex'

const log = anylogger('PaperFlex')

export type MergeTypes<T, U> = {
	[P in keyof (T & U)]: P extends keyof T ? T[P] : P extends keyof U ? U[P] : never
}

interface PaperFlexProps extends MergeTypes<FlexProps, PaperProps> {}
/**
 * This is a wrapper around the Mui Paper component, but changes it to a flex for easier layout configuration.
 * It also automatically adds the scroll prop, so that it controls scrolling within its borders.
 */
export const PaperFlex = React.forwardRef(function PaperFlex(props: PaperFlexProps, ref: any) {
	return <Paper component={Flex} scroll {...props}></Paper>
})
