import { useEffect } from 'react'

export const useAsyncEffect = (f: any, deps: any[]): void => {
	useEffect(() => {
		const doEffect = () => {
			return f()
		}
		return doEffect()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps])
}
