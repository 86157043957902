import { Flex, FlexC } from './Flex'
import { Box, unstable_composeClasses as composeClasses, generateUtilityClass, generateUtilityClasses, alpha, lighten } from '@mui/material'
import { styled } from '@mui/material/styles'
import anylogger from '@app/anylogger'

const log = anylogger('VirtualListViewRoot')

// These are the different class names that can be applied to a cell OR title
export interface VirtualListViewClasses {
	cell: string
	selected: string
	focused: string
	hover: string
	separator: string
	stripe: string
	rightAligned: string
	centered: string
	isDown: string
}
export type VirtualListViewClassKey = keyof VirtualListViewClasses

// this provides a dictionary that maps the simple class names (in the array) to complex names, based on
// the passed in componentName, and making some class names global, such as Mui-selected
// These class names are shared between the titles and the cells.
const listViewClasses: VirtualListViewClasses = generateUtilityClasses('VirtualListView', [
	'cell',
	'selected',
	'focused',
	'hover',
	'separator',
	'stripe',
	'rightAligned',
	'centered',
	'isDown'
])
export function getVirtualListViewUtilityClass(slot: string): string {
	return generateUtilityClass('VirtualListView', slot)
}
// this generates a list of classes that are applicable given the ownerState.
// i.e. "VirtualListView-hover Mui-selected Mui-focused VirtualListView-separator"
// Mui-selected will not be returned as per below, if selctedMode is 'none' or isSelected is false.
// These class names are then passed to the appropriate components and the "Style" classes
// can test for their existence and apply the appropriate css values as per ListViewRowStyle below
export const getUtilityClasses = (ownerState: any) => {
	const { classes, selectMode, isSelected, hover, isHovered, focus, isFocused, separator, stripe, rightAligned, centered, isDown } =
		ownerState
	const slots = {
		cell: [
			'cell',
			selectMode != 'none' && isSelected && 'selected',
			hover && isHovered && 'hover',
			focus && isFocused && 'focused',
			separator && 'separator',
			stripe && 'stripe',
			rightAligned && 'rightAligned',
			centered && 'centered'
		],
		title: ['title', isDown && 'isDown', rightAligned && 'rightAligned', centered && 'centered']
	}
	const res = composeClasses(slots, getVirtualListViewUtilityClass, classes)
	return res
}

export const VirtualListViewRoot = styled(FlexC, {
	name: 'VirtualListView',
	slot: 'Root',
	overridesResolver: (props, styles) => {
		const { ownerState } = props
		return [styles.root, ownerState.preventTextSelection && { userSelect: 'none' }]
		return [styles.root]
	}
})(({ theme }) => {
	return {
		[`:focus`]: {
			outline: 'none'
		}
		// userSelect: 'none'
	}
})
export const VirtualListViewTitleCell = styled(Box, {
	name: 'VirtualListView',
	slot: 'Title',
	overridesResolver: (props, styles) => {
		const { ownerState, theme, rightAligned, centered } = props
		const { isDown } = ownerState
		return [
			styles.title,
			isDown && { [`&.${listViewClasses.isDown}`]: styles.titleIsDown },
			rightAligned && { [`&.${listViewClasses.rightAligned}`]: styles.titleRightAligned },
			centered && { [`&.${listViewClasses.centered}`]: styles.titleCentered }
		]
	}
})(({ theme }) => {
	return {
		backgroundColor: lighten(theme.palette.primary.light, 0.7),
		border: `outset 1px ${lighten(theme.palette.primary.light, 0.5)}`,
		fontWeight: 'bold',
		cursor: 'pointer',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'clip',
		paddingLeft: '0.25em', // this is overridden if it rightAligned or centered
		[`&.${listViewClasses.rightAligned}`]: {
			paddingLeft: '0px',
			paddingRight: '0.25em',
			textAlign: 'right'
		},
		[`&.${listViewClasses.centered}`]: {
			paddingLeft: '0.125em',
			paddingRight: '0.125em',
			textAlign: 'center'
		},
		[`&.${listViewClasses.isDown}`]: {
			border: `inset 1px ${lighten(theme.palette.primary.light, 0.3)}`
		},
		'&:hover': {
			fontWeight: '800',
			color: theme.palette.primary.main
		}
	}
})
export const VirtualListViewCell = styled(Box, {
	name: 'VirtualListView',
	slot: 'Cell',
	overridesResolver: (props, styles) => {
		// ! FOR REFERENCE: we only require ownerState to be passed in so that if an option is turned on
		// ! we can include the styleOverrides from the theme
		// ! We still have to pass the proper class names to the component in order for the specific css to be invoked
		// ! Is there some way to make this simpler?
		const { ownerState } = props
		const { hover, isHovered, focus, isFocused, selected, isSelected, separator, stripe, rightAligned, centered } = ownerState

		// this is only taking the style overrides from the theme and adding them to the css class list
		// so that when the style classes are used below, the proper them overrides will be used
		return [
			styles.cell,
			hover && isHovered && { [`&.${listViewClasses.hover}`]: styles.hover },
			focus && isFocused && { [`&.${listViewClasses.focused}`]: styles.focused },
			selected && isSelected && { [`&.${listViewClasses.selected}`]: styles.selected },
			stripe && { [`&.${listViewClasses.stripe}`]: styles.stripe },
			separator && { [`&.${listViewClasses.separator}`]: styles.separator },
			rightAligned && { [`&.${listViewClasses.rightAligned}`]: styles.cellRightAligned },
			centered && { [`&.${listViewClasses.centered}`]: styles.cellCentered }
		]
	}
})(({ theme }) => {
	return {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		marginTop: 'auto',
		marginBottom: 'auto',
		// display: 'flex',
		alignItems: 'center',
		paddingLeft: '0.25em', // this is overridden if it rightAligned or centered
		[`&.${listViewClasses.rightAligned}`]: {
			paddingLeft: '0px',
			paddingRight: '0.25em',
			textAlign: 'right'
		},
		[`&.${listViewClasses.centered}`]: {
			paddingLeft: '0.125em',
			paddingRight: '0.125em',
			textAlign: 'center'
		},
		[`&.${listViewClasses.hover}`]: {
			backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity)
		},
		[`&.${listViewClasses.selected}`]: {
			backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
		},
		[`&.${listViewClasses.focused}`]: {
			backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
		},
		[`&.${listViewClasses.separator}`]: {
			borderColor: 'lightgray',
			borderBottomWidth: '1px',
			borderBottomStyle: ' solid'
		},
		[`&.${listViewClasses.stripe}`]: {
			// order is very important here as the last entry takes precedence
			backgroundColor: ` ${alpha(theme.palette.secondary.light, theme.palette.action.hoverOpacity)}`,
			[`&.${listViewClasses.hover}`]: {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity)
			},
			[`&.${listViewClasses.selected}`]: {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
			},
			[`&.${listViewClasses.focused}`]: {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
			}
		}
	}
})
export const VirtualListStickyHeader = styled(FlexC, {
	name: 'VirtualListView',
	slot: 'StickyHeader',
	overridesResolver: (props, styles) => {
		const { ownerState } = props
		// we we are in any kind of single or select mode, then don't allow text selection
		// Actually, we will now leave this in the hands of the preventTextSelection prop, but
		// will default it to true if selectMode is not none
		return []
	}
})(({ theme }) => {
	return {
		position: 'relative'
	}
})
export const VirtualListViewTitleRow = styled(Box, {
	name: 'VirtualListView',
	slot: 'TitleRow',
	overridesResolver: (props, styles) => {
		return []
	}
})(({ theme }) => {
	return {
		position: 'sticky',
		zIndex: 2,
		top: '0px'
	}
})
