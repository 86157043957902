import React, { useState } from 'react'
import anylogger from '@app/anylogger'
import { TitleDefinitionList } from './TitleDefinition'
import TitleDefinitionListView from './TitleDefinitionListView'
import { Buttons, SimpleDialog } from './ModalDialog'
import { DeferredPromise } from '@app/utils'

const log = anylogger('TitleDefinitionEditor')

interface TitleDefinitionEditorProps {
	allTitles: TitleDefinitionList
	selectedTitles: TitleDefinitionList
	onSelectedChanged?: (newSelected: TitleDefinitionList) => void
	deferredPromise?: DeferredPromise
}
export const TitleDefinitionEditor = React.forwardRef(function TitleDefinitionEditor(props: TitleDefinitionEditorProps, ref: any) {
	const { allTitles, selectedTitles, onSelectedChanged, deferredPromise } = props
	const [selected, setSelected] = useState([...selectedTitles])

	const selectionChanged = (selected: TitleDefinitionList) => {
		setSelected([...selected])
	}
	const buttonClick = (deferredPromise: DeferredPromise, id: number) => {
		const res = { result: id, selected }
		deferredPromise?.resolve(res)
	}
	return (
		<SimpleDialog
			deferredPromise={deferredPromise!}
			title="Select the columns you want"
			content={<TitleDefinitionListView allTitles={allTitles} selectedTitles={selected} onSelectedChanged={selectionChanged} />}
			buttons={[Buttons.Save, Buttons.Discard]}
			onButtonClick={buttonClick}
		/>
	)
})
