import anylogger from '@app/anylogger'
import { useCallback } from 'react'
import { MouseTouchEvent, MouseTouchEventHandler, createMouseEvent, createTouchEvent } from './mouseTouchUtils'
import { useEvent } from './useEvent'
import { useIsTouch } from './useIsTouch'

const log = anylogger('useMoveEvent')

/**
 * This hook amalgamates the mouseMove and touchMove events so that you can use one hook to control both
 * desktop and mobile devices.
 * @param ref - The ref of the object you want the downEvent for.
 * @param downEvent - This callback gets fired when there is a mouseMove or touchMove event.  The callback passes a {@link MouseTouchEvent} parameter.
 * @param allowPreventDefault - Whether returning true from the callback will call preventDefault on the originating event handler.
 * @param data - This data will be passed in the callback function parameter.
 */
export const useMoveEvent = (
	ref: HTMLElement | Document | Window | null,
	moveEvent: MouseTouchEventHandler,
	allowPreventDefault?: boolean,
	data?: any
) => {
	const isTouch = useIsTouch()

	const touchMove = useCallback(
		(e: any) => {
			if (!isTouch) return
			if (!e.touches.length) return
			// log('touchMove', e)

			if (moveEvent(createTouchEvent(e, data))) {
				if (allowPreventDefault) e.preventDefault()
				e.stopPropagation()
				e.stopImmediatePropagation()
			}
		},
		[allowPreventDefault, data, isTouch, moveEvent]
	)
	const mouseMove = useCallback(
		(e: any) => {
			if (isTouch) return
			if (moveEvent(createMouseEvent(e, data))) {
				if (allowPreventDefault) e.preventDefault()
				e.stopPropagation()
			}
		},
		[allowPreventDefault, data, isTouch, moveEvent]
	)
	const options = allowPreventDefault ? { passive: false } : undefined
	useEvent(ref, 'touchMove', touchMove, options)
	useEvent(ref, 'mouseMove', mouseMove, options)
}
