import React, { createRef, PropsWithChildren, useContext, useState } from 'react'
import anylogger from '@app/anylogger'
import { DeferredPromise } from '@app/utils'
import {
	AlertDialog,
	AlertDialogProps,
	Cancel,
	CustomDialog,
	CustomDialogProps,
	YesNoDialog,
	YesNoDialogProps,
	TextDialog,
	TextDialogProps,
	TextDialogResult,
	ComboBoxDialogProps,
	ComboBoxDialogResult,
	ComboBoxDialog,
	BusyDialogProps,
	BusyDialog,
	SimpleDialogProps,
	SimpleDialog
} from './ModalDialog'

const log = anylogger('ModalOverlay')

type CancelFunction = () => void
type SetContentFunction = (content: string) => void
type BusyDialogResult = { cancel: CancelFunction; setContent: SetContentFunction }
type AlertDialogType = (params: AlertDialogProps) => Promise<number>
type YesNoPromptType = (params: YesNoDialogProps) => Promise<number>
type SimpleDialogType = (params: SimpleDialogProps) => Promise<number>
type TextDialogType = (params: TextDialogProps) => Promise<TextDialogResult>
type ComboBoxDialogType = (params: ComboBoxDialogProps) => Promise<ComboBoxDialogResult>
type CustomDialogType = (params: CustomDialogProps) => Promise<any>
type BusyDialogType = (params: BusyDialogProps) => BusyDialogResult

class Context {
	/**
	 * Displays an alert dialog using the {@link AlertDialogProps} (title and content)
	 */
	alertDialog!: AlertDialogType
	/**
	 * Displays a Yes or No dialog prompt using the {@link YesNoDialogProps} (title and content).
	 */
	yesNoPrompt!: YesNoPromptType
	/**
	 * Displays a CombBox dialog using {@link ComboBoxDalogProps} (title, provider, value and buttons).
	 * The result is an obect with result indicating the buttonId that was clicked, and value indicating the value that was selected.
	 */
	comboBoxDialog!: ComboBoxDialogType
	/**
	 * Displays an text dialog using {@link TextDalogProps} (title, text and buttons).
	 * The result is an obect with result indicating the buttonId that was clicked, and text indicating the value of the text.
	 */
	textDialog!: TextDialogType
	/**
	 * Displays a custom dialog using the {@link CustomDialogProps} (title, content and buttons).
	 * The content can be any React Node, which can send events back to the a closure in the calling function, storing
	 * the result in a local variable.  Once a button is clicked, the buttonId is returned and the value of the local variable
	 * can be used as necessary.
	 */
	customDialog!: CustomDialogType
	/**
	 * Displays a modal dialog without buttons, which will be continually displayed until the returned 'cancel' function is called.
	 * This function returns an object containing the 'cancel' function as well as a 'setContent' function, which can be used
	 * to update the dialog content for long running processes
	 */
	busyDialog!: BusyDialogType
	simpleDialog!: SimpleDialogType
}
export const ModalContext = React.createContext(new Context())

interface ModalOptions {}
export function useModal(opts?: ModalOptions) {
	const context = useContext(ModalContext)
	return context
}

type DialogType = 'YesNoDialog' | 'AlertDialog' | 'TextDialog' | 'ComboBoxDialog' | 'CustomDialog' | 'BusyDialog' | 'SimpleDialog'
interface DialogInstance {
	deferredPromise: DeferredPromise
	component: DialogType
	params: any
}
const DialogRecords: Record<DialogType, any> = {
	YesNoDialog: YesNoDialog,
	AlertDialog: AlertDialog,
	TextDialog: TextDialog,
	ComboBoxDialog: ComboBoxDialog,
	CustomDialog: CustomDialog,
	BusyDialog: BusyDialog,
	SimpleDialog: SimpleDialog
}

interface ModalOverlayProps {}
export const ModalOverlay: React.FC<ModalOverlayProps> = React.forwardRef(function ModalOverlay(
	props: PropsWithChildren<ModalOverlayProps>,
	ref: any
) {
	const [dialogs, setDialogs] = useState<DialogInstance[]>([])

	const yesNoPrompt = async (params: YesNoDialogProps): Promise<number> => {
		return await initiateDialog('YesNoDialog', params)
	}
	const alertDialog = async (params: AlertDialogProps): Promise<number> => {
		return await initiateDialog('AlertDialog', params)
	}
	const textDialog = async (params: TextDialogProps): Promise<any> => {
		return await initiateDialog('TextDialog', params)
	}
	const comboBoxDialog = async (params: ComboBoxDialogProps): Promise<any> => {
		return await initiateDialog('ComboBoxDialog', params)
	}
	const customDialog = async (params: CustomDialogProps): Promise<any> => {
		return await initiateDialog('CustomDialog', params)
	}
	const busyDialog = (params: BusyDialogProps): BusyDialogResult => {
		const deferredPromise = new DeferredPromise()
		const cancel = () => {
			deferredPromise.resolve(0)
		}
		let setter: SetContentFunction
		const setContent = (content: string) => {
			if (setter) setter(content)
		}
		const hookContent = (setFunction: SetContentFunction) => {
			setter = setFunction
		}
		initiateDialog('BusyDialog', { ...params, hookContent }, deferredPromise)
		return { cancel, setContent }
	}
	const simpleDialog = async (params: SimpleDialogProps): Promise<any> => {
		return await initiateDialog('SimpleDialog', params)
	}

	const initiateDialog = async (comp: DialogType, params: any, deferredPromise?: DeferredPromise): Promise<any> => {
		if (!deferredPromise) deferredPromise = new DeferredPromise()
		const inst = { deferredPromise, component: comp, params }
		dialogs.push(inst)

		setDialogs([...dialogs])
		return await deferredPromise.promise.then((res: any) => {
			dialogs.pop()
			setDialogs([...dialogs])
			return res
		})
	}

	const getDialog = () => {
		if (!dialogs.length) return

		const { deferredPromise, params, component } = dialogs[dialogs.length - 1]
		const comp = DialogRecords[component]

		if (!comp) throw new Error(`Unknown Dialog component: ${comp}`)
		return (
			// <ClickAwayListener onClickAway={closeDialog}>
			// 	<Flex
			// 		sx={{
			// 			ml: '200px',
			// 			backgroundColor: 'red'
			// 		}}
			// 	>
			// {
			React.createElement(comp, { ...params, deferredPromise: deferredPromise })
			// }
			// 	</Flex>
			// </ClickAwayListener>
		)
	}
	// const closeDialog = () => {
	// 	log('closeDialog')

	// 	if (!deferredPromise) return
	// 	deferredPromise.reject('Click Away')
	// }
	return (
		<ModalContext.Provider value={{ yesNoPrompt, customDialog, textDialog, comboBoxDialog, alertDialog, busyDialog, simpleDialog }}>
			{getDialog()}
			{props.children}
		</ModalContext.Provider>
	)
})
