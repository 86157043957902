import { TitleDefinition, TitleDefinitionList } from './TitleDefinition'
import anylogger from '@app/anylogger'
import { ReactNode } from 'react'
import { CellData, ColumnMapper } from './ColumnMapper'

const log = anylogger('IListViewProvider')

export type ProviderRowData = CellData[] | Record<string, CellData>

//! The default implementation uses ColumnMapper.  Should this be separate?
// ! Regardless, it should allow for a "value" field that can be used for sorting and (by default) columns widths, but
// ! should also allow for a "display value" that is separate and does not affect column width or sorting.
// ! ALSO, numeric sort should be enabled for file sizes. AND shouild not depend on the text representation.  i.e. 1 MB should be greater thatn 999 KB.  This would also require useing the original value instaed of the text representation.

export type AdaptItemFunction<T = any> = (item: T, idx: number) => any

export interface IListViewProvider<T = any> {
	/**
	 * The raw list of items that are to be displayed in the ListView
	 * */
	items: T[]
	/**
	 * All the titles, representing all the columns of this object.
	 * If specified, the number of titles must match the number if array items or object properties returned from createColumns
	 * A variable number of title names can be passed to the actual ListView component to display a subset of the available columns
	 */
	getAllTitles?(): TitleDefinitionList
	/**
	 * This must return either an array of all the columns in the list view, or an object with properties specifying all the columns of the object.
	 * If allTitles  is specified, the number of array items or object properties must match the number of allTitles.
	 * This can either return text, or a ReactNode
	 */
	createRow(item: T, index: number): ProviderRowData
	getId?: (item: T) => ReactNode
	customCompare?: (titleDef: TitleDefinition, objA: T, objB: T, valA: ReactNode, valB: ReactNode) => number | undefined
}

export class ListViewProviderBase<T = any> implements IListViewProvider<T> {
	items: T[]
	// fields: ColumnMapper = new ColumnMapper([])

	getId?: (item: T) => string
	constructor(items: T[]) {
		this.items = items
	}
	// registerFields(arr: any[]) {
	// 	this.fields = new ColumnMapper(arr)
	// }

	getAllTitles?(): TitleDefinitionList
	createRow(item: T, index: number): ProviderRowData {
		return []
	}
}
