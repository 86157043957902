import { Guid, sleep } from '@app/utils'
import { NextApiResponse } from 'next'
import anylogger from '@app/anylogger'
import { stringifyError } from './serverProxy'

const log = anylogger('objectStreamer')

export class ObjectStreamWriter {
	boundary: string = ''
	res!: NextApiResponse

	constructor(res: NextApiResponse) {
		this.res = res
		this.boundary = '---' + Guid() + '---'
		// added a new pseudo-contentType to represent status update objects being sent back to the client from the server
		res.setHeader('content-type', `multipart/objectstream; boundary=${this.boundary}`)
	}
	writeObject(obj: any) {
		const str = JSON.stringify(obj)
		this.res.write(str)
		this.res.write(this.boundary)
	}
	writeError(obj: any, err: any) {
		obj.error = stringifyError(err)
		this.writeObject(obj)
	}
}

export async function readObjectStream(res: Response, onObject: (obj: any) => Promise<void>) {
	if (!res || !res.body) throw new Error(`res is null`)
	const contentType = res.headers.get('content-type')
	if (!contentType) throw new Error(`Could not find the ContentType for streaming objects`)
	const strs = contentType.split(';')
	const boundaryStr = strs.find((str: string) => str.toLowerCase().includes('boundary'))
	if (!boundaryStr) throw new Error(`Could not find the boundary in the Content-Type`)
	const boundary = boundaryStr.trim().slice('boundary='.length)

	const reader = res.body.getReader()
	const decoder = new TextDecoder()
	let buffer: string = ''
	while (true) {
		const { done, value } = await reader.read()
		if (done) break
		const text = decoder.decode(value)
		buffer = buffer + text
		let idx
		while ((idx = buffer.indexOf(boundary)) >= 0) {
			const val = buffer.slice(0, idx)
			// log('val', val)
			const obj = JSON.parse(val)
			await onObject(obj)
			buffer = buffer.slice(idx + boundary.length)

			// Since this is called from the client, and its main purpose is to update the client UI,
			// we should do a short pause, which allows the message pump to process any UI updates.
			// The server is likely serving data faster than we can process it, so this loop acts like one long running function,
			// which  does not give the UI time to update,
			await sleep(1)
		}
		if (buffer != '') log('leftover', buffer)
	}
}
