import { useCallback, useEffect, useLayoutEffect, useState } from 'react'

interface Size {
	width: number
	height: number
}

export const useWindowSize = (): Size => {
	const [size, setSize] = useState({ width: 0, height: 0 })
	// const { browserWindow } = useBrowserDocWin()

	const updateSize = useCallback(() => {
		if (window.innerHeight !== size.height || window.innerWidth !== size.width) {
			setSize({ width: window.innerWidth, height: window.innerHeight })
		}
	}, [size.height, size.width])

	useEffect(() => {
		if (typeof window == 'undefined') return undefined
		updateSize()
		window.addEventListener('resize', updateSize)
		return () => window.removeEventListener('resize', updateSize)
	})

	return size
}
