import anylogger from '@app/anylogger'

const log = anylogger('mergeList')

export type Transformer = (val: any, idx: number) => any
export type Comparer = (src: any, dest: any) => boolean

export function transformList(src: any[], dest: any[], compare: Comparer, transform: Transformer) {
	if (src.length != dest.length) return src.map((item, idx) => transform(item, idx))
	return src.map((item, idx) => {
		if (compare(src[idx], dest[idx])) {
			return dest[idx]
		} else {
			return transform(item, idx)
		}
	})
}
