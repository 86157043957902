import { applyOperation, getValueByPointer } from '@app/jsonpatch'
import anylogger from '@app/anylogger'

const log = anylogger('JsonValueProvider')

/**
 * This function is meant to adjust the value that is pulled from the object with the json pointer path.
 * The extracted value is passed to the function, as well as the object and the path that was used to extract the value.
 * The return value will be used as the final value for the JsonValueProvider instance.
 * If the function returns undefined, value will be used, which came from the JSON Pointer path */
export type AdjustValueFunction<T extends object = any> = (value: any, obj: T, path: string) => any

/**
 * This function is used to adjust the value back into the object. It should be complementary to {@link AdjustValueFunction}.
 * It only passes the value that is to be inserted into the object, and not the object and path that were originally used to get the value.
 * If the function returns undefined, value will be used, which came from the JSON Pointer path*/
export type AdjustValueBackFunction<T extends object = any> = (value: any) => any

/**
 * This function is used to pull a value from an object without specifying a path.
 * It is completely open ended and can check the entire object, so can combine several fields and/or check fields to see if others should be converted */
export type GetValueFunction<T extends object = any> = (obj: T) => any

/**
 * This function is used to put a value back into an object.  It is passed the value to be put back as well as the object that the value should be placed in.
 * It is always paired with a GetValueFunction, and so should be complementary to it.
 * It also is open ended, meaning that it does not refer to one field, but accesses the object as a whole. */
export type SetValueFunction<T extends object = any> = (obj: T, value: any) => any

/**
 * This construct creates a provider that extracts a value from an object using the specified JsonPointer path ({@link https://datatracker.ietf.org/doc/html/rfc6901#autoid-1 RFC 6901}). Examples:
 * * 'phone' or ['phone'] - Extracts a value from the object
 * * 'phones/1/value' - Extracts value from the second (index 1) phone in the list
 * * 'phones/{type: mobile, primary: true}/value' - Extracts value from phone in the list that has a type property of mobile and has primary set to true
 */
export type JVPPath = string | [string]
/**
 * This construct creates a provider that extracts a value from the object using a Json Pointer path and also specifies an {@link AdjustValueFunction} that is used
 * to adjust that value, either for presentation, transformation or to vary the returned value based on other fields in the object. Example:
 * * ['phone', (val, obj, path) => cleanPhone(val)] - Extracts the phone field from the object and then returns the result from the cleanPhone function */
export type JVPPathWithAdjust<T extends object = any> = [string, AdjustValueFunction<T>]
/**
 * This construct creates a provider that extracts a value from the object using a path and also specifies a function that is used
 * to adjust that value, either for presentation or for transformation. It also provides an AdjustBack function that is used
 * to put the value back into the object. Example:
 * * ['phones/{type: cell}/value', (val, obj, path) => cleanPhone(val), (val) => numbersOnly(val)] -
 *   Extracts the value from the "cell" phone in the phone list in the object and then returns the result from the cleanPhone function.
 *   When putting the value back (when setValue is called on the instance), all non-number characters are stripped out by the numbersOnly function. */
export type JVPPathWithAdjustAndBack<T extends object = any> = [string, AdjustValueFunction<T>, AdjustValueBackFunction<T>]

/**
 * This construct is used to extract any value from an object using the {@link GetValueFunction}, without specifying a Json Pointer path. Example:
 *  (user) => { return user.middleName ? user.firstName + ' ' + user.middleName : user.firstName}] */
export type JVPGetValueFunction<T extends object = any> = GetValueFunction<T> | [GetValueFunction<T>]
/**
 * This construct is used to extract any value from an object using the {@link GetValueFunction}, without specifying a Json Pointer path as well as to put a value back into an object using the using the {@link SetValueFunction}. Example:
 *  (user) => { return user.isDisabled ? 'Yes' : 'No'}, (val) => val == 'Yes' ? true : false] */
export type JVPGetAndSetValueFunctions<T extends object = any> = [GetValueFunction<T>, SetValueFunction<T>]

/**
 * This is a set of all the ways that a JsonValueProvider can be initialized. */
export type JVPInit<T extends object = any> =
	| JVPPath
	| JVPPathWithAdjust<T>
	| JVPPathWithAdjustAndBack<T>
	| JVPGetValueFunction<T>
	| JVPGetAndSetValueFunctions<T>

export interface IJsonValueProvider<T extends object = any> {
	getValue: (obj: T) => any
	setValue: (obj: T, value: any) => void
}

/**
 * This class allows you to both extract values from a JSON object and set them.
 * This class can be constructed in several ways as per {@link JVPInit}. The primary ways are:
 * 1) With a Json Pointer path ({@link https://datatracker.ietf.org/doc/html/rfc6901#autoid-4 RFC 6901}) and optional adjust and adjustBack functions.
 * * When getValue is called on the provider instance, it returns a value that is retrieved from the passed in object, using the json pointer path.
 *   The value returned from getValue is optionally modified or replaced by the {@link AdjustValueFunction} before being returned
 * * when setValue is called on the instance, the value is optionally adjusted by the  {@link AdjustValueBackFunction}, and then the object is updated with the value, based on the json pointer path.
 * 2) With a GetValueFunction and optionally a SetValueFunction
 * * The GetValueFunction is passed the object, and the function can return any value based on the fields in the object. Such as:
 * ** Combining several fields into one
 * ** Formatting a value (although this can also be done by an AdjustValueFunction, using a json pointer path)
 * ** Returning a value based on other fields
 * ** Amalgamating a group of items into a single string */
export class JsonValueProvider<T extends object = any> implements IJsonValueProvider<T> {
	path?: string
	adjustFunction?: AdjustValueFunction<T>
	adjustBackFunction?: AdjustValueBackFunction<T>
	getValueFunction?: GetValueFunction<T>
	setValueFunction?: SetValueFunction<T>

	static init<T extends object = any>(val: JVPInit) {
		if (typeof val == 'string') return new JsonValueProvider<T>(val)
		else if (typeof val == 'function') return new JsonValueProvider<T>(undefined, undefined, undefined, val)
		else if (Array.isArray(val)) {
			if (typeof val[0] == 'string') {
				if (val.length == 2) {
					return new JsonValueProvider<T>(val[0], val[1])
				} else return new JsonValueProvider<T>(val[0], val[1], val[2])
			} else {
				if (val.length == 1) return new JsonValueProvider<T>(undefined, undefined, undefined, val[0])
				// for some reason, scope narrowing does not understand val[1] to be a set function, so we must cast it.
				else return new JsonValueProvider<T>(undefined, undefined, undefined, val[0], (val as JVPGetAndSetValueFunctions)[1])
			}
		} else throw new Error(`Invalid init value: ${val}`)
	}
	private constructor(
		path?: string,
		adjustFunction?: AdjustValueFunction<T>,
		adjustBackFunction?: AdjustValueBackFunction<T>,
		getValueFunction?: GetValueFunction,
		setValueFunction?: SetValueFunction
	) {
		this.path = path ? this.prefixPath(path) : undefined
		this.adjustFunction = adjustFunction
		this.adjustBackFunction = adjustBackFunction
		this.getValueFunction = getValueFunction
		this.setValueFunction = setValueFunction
	}
	getValue(obj: T) {
		let val = undefined
		if (this.path) {
			val = getValueByPointer(obj, this.path)
			if (this.adjustFunction) {
				const adjusted = this.adjustFunction(val, obj, this.path)
				val = typeof adjusted == 'undefined' ? val : adjusted
			}
		} else if (this.getValueFunction) {
			val = this.getValueFunction(obj)
		}
		return val
	}
	setValue(obj: T, value: any) {
		try {
			if (this.path) {
				if (this.adjustBackFunction) {
					const adjusted = this.adjustBackFunction(value)
					value = typeof adjusted == 'undefined' ? value : adjusted
				}
				applyOperation(obj, { op: 'add', path: this.path, value })
			} else if (this.setValueFunction) {
				this.setValueFunction(obj, value)
			}
		} catch (err) {
			console.error('Error applying', obj, this.path)
			throw err
		}
	}
	private prefixPath(str: string) {
		if (!str.startsWith('/')) return '/' + str
		else return str
	}
}
