import React, { ReactNode, SetStateAction, useCallback, useEffect, useState } from 'react'
import anylogger from '@app/anylogger'
import { Flex, FlexProps } from './Flex'
import { Button, Tab, Tabs, TabsProps, ToggleButtonGroup, ToggleButton, useTheme, TabProps } from '@mui/material'
import { useLocalStorageOrState } from '@app/hooks'
import { ThemeContext } from '@emotion/react'
import { TypesafeBoolean } from '@app/utils'

const log = anylogger('TabFlex')

interface TabPage {
	name: string
	label?: string
	content: ReactNode
	tabVisible?: boolean
}

export type ChangeActiveTab = (tab: string) => void

export class TabPageList extends Array<TabPage> {
	constructor() {
		super()
	}
	addTab(name: string, content: ReactNode, label?: string) {
		this.push({ name, label, content })
	}
}
interface TabFlexProps extends FlexProps {
	/**
	 * The list of TabPage objects to render */
	tabs: TabPageList

	/**
	 * Default: undefined. Specifies the tab that is to be active by default.  If not specified, it defaults to the first tab */
	initialTab?: string

	/**
	 * Default: undefined. Specifies the local storage key to store the active tab in so that it is
	 * retained across browser refreshes. If not specified, the active tab will be reset to the initialTab
	 * on every browser refresh. */
	localStorageKey?: string
	onTabChange?: (tab: string) => void
	/**
	 * Default: true. Set this to false to hide the tabs at the top, but still be able to see the tab content*/
	tabsVisible?: boolean

	/**
	 * This callback will be called as soon as this component is rendered. It will pass a function
	 * that can be called to change the active tab. */
	setChangeActiveTab?: (changeActiveTab: SetStateAction<ChangeActiveTab>) => void
}
export const TabFlex = React.forwardRef(function TabFlex(props: TabFlexProps, ref: any) {
	let { tabs, initialTab = tabs[0].name, localStorageKey, onTabChange, tabsVisible = true, setChangeActiveTab, children, ...rest } = props
	if (!tabs || !tabs.length) throw new Error(`You must specify at least one item in the tabs prop`)

	const [activeTab, setActiveTab] = useLocalStorageOrState(localStorageKey, initialTab)
	const [instantiatedTabs, setInstantiatedTabs] = useState<any>({})
	const theme = useTheme()

	const changeTab = useCallback(
		(tab: string) => {
			setActiveTab(tab)
			if (onTabChange) onTabChange(tab)
		},
		[onTabChange, setActiveTab]
	)
	useEffect(() => {
		if (!setChangeActiveTab) return
		setChangeActiveTab(changeTab)
	}, [changeTab, setActiveTab, setChangeActiveTab])

	const renderButtons = useCallback(() => {
		return tabs
			.map((tab) => {
				if (typeof tab.tabVisible != 'undefined' && !tab.tabVisible) return null
				const name = tab.name
				const label = tab.label ? tab.label : name
				const bg = activeTab == name ? theme.palette.primary.light : theme.palette.action.disabledBackground
				return (
					<ToggleButton
						key={name}
						value={name}
						color="primary"
						sx={{
							backgroundColor: bg
						}}
					>
						{label}
					</ToggleButton>
				)
			})
			.filter(TypesafeBoolean)
	}, [activeTab, tabs, theme.palette.action.disabledBackground, theme.palette.primary.light])

	function tabChange(e: EventInit, value: string) {
		changeTab(value)
	}

	const renderActiveTab = useCallback(() => {
		if (!activeTab) return null
		let t = instantiatedTabs[activeTab]
		// if (!t) {
		const tab = tabs.find((tab) => tab.name == activeTab)
		if (!tab) return null
		t = tab.content
		// setInstantiatedTabs((prev: any) => {
		// 	prev[activeTab] = t
		// 	return prev
		// })
		// }
		return <Flex fill>{t}</Flex>
	}, [tabs, instantiatedTabs, activeTab])

	// const renderInstantiatedTabs = useCallback(() => {
	// 	return tabs
	// 		.map((tab) => {
	// 			const name = tab.name
	// 			let t = instantiatedTabs[name]
	// 			if (!t && name == activeTab) {
	// 				t = tab.content
	// 				setInstantiatedTabs((prev: any) => {
	// 					prev[name] = t
	// 					return prev
	// 				})
	// 			}
	// 			if (!t) return null

	// 			return (
	// 				<Flex
	// 					position="absolute"
	// 					left="0px"
	// 					top="0px"
	// 					width="100%"
	// 					height="100%"
	// 					fill
	// 					visibility={name == activeTab ? 'visible' : 'hidden'}
	// 					key={tab.name}
	// 				>
	// 					{t}
	// 				</Flex>
	// 			)
	// 		})
	// 		.filter(Boolean)
	// }, [tabs, instantiatedTabs, activeTab])

	// const renderTabs = useCallback(() => {
	// 	if (onlyRenderVisibleTab) return renderActiveTab()
	// 	else return renderInstantiatedTabs()
	// }, [onlyRenderVisibleTab, renderActiveTab, renderInstantiatedTabs])

	return (
		<Flex f="c" fill {...rest}>
			{tabsVisible && (
				<Flex>
					<ToggleButtonGroup size="small" value={activeTab} onChange={tabChange} exclusive>
						{renderButtons()}
					</ToggleButtonGroup>
					<Flex fill>{children}</Flex>
				</Flex>
			)}
			<Flex fill position="relative">
				{renderActiveTab()}
			</Flex>
		</Flex>
	)
})
