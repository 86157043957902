import { useState } from 'react'
import { Text } from './Text'
import anylogger from '@app/anylogger'
import { Box, useTheme } from '@mui/material'
import { IMenu, IMenuList } from './MenuContext'
import { Flex } from './Flex'

const log = anylogger('TopMenuItems')

export type CreateMenuItemFunction = (item: IMenu) => React.ReactNode
export type MenuItemClick = (item: IMenu) => void

const SubMenu = (props: any) => {
	const theme = useTheme()
	const { leftSize, topPos, isVisible, children, ...rest } = props

	return (
		<Box
			{...rest}
			// __themeKey="topMenu"
			// variant="subMenu"
			sx={{
				// flexDirection: 'column',
				width: 'auto',
				position: 'absolute',
				left: leftSize,
				top: topPos,
				display: isVisible ? 'block' : 'none'
			}}
		>
			{children}
		</Box>
	)
}

const MenuItemView = (props: any) => {
	const { item, onMenuItemClick, variant, createMenuItem, ...rest } = props

	const [childrenVisible, setChildrenVisible] = useState(false)
	const { leftSize, topPos } = props

	const getChildItems = (childItems: IMenu[]) => {
		return (
			childItems &&
			childItems.map((child: IMenu, index) => {
				return (
					<MenuItemView
						variant="subMenuItem"
						createMenuItem={createMenuItem}
						onMenuItemClick={(child: IMenu) => onMenuItemClick(child)}
						item={child}
						key={index}
						leftSize="100%"
						topPos="0px"
					/>
				)
			})
		)
	}
	const mouseEnter = () => {
		setChildrenVisible(true)
	}
	const mouseLeave = () => {
		setChildrenVisible(false)
	}

	return (
		<Box sx={{ position: 'relative', display: 'block' }} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
			<Box
				onClick={() => onMenuItemClick(item)}
				sx={{
					display: 'inline-block',

					width: '100%', // this makes all the sub menu items the same size so a hover will highlight the entire width of the container
					whiteSpace: 'nowrap'
				}}
			>
				{/* {item.caption} */}
				{createMenuItem ? createMenuItem(item) : item.caption}
			</Box>
			<SubMenu isVisible={childrenVisible} leftSize={leftSize} topPos={topPos}>
				{getChildItems(item.children)}
			</SubMenu>
		</Box>
	)
}

const TopMenuItemList = (props: any) => {
	// NOTE: we HAD to extract createMenuItem from the props so that it would not be in the ...rest variable
	// if we passed rest (containing createMenuItem) to the main flex component below, it change the display property from flex to block.
	// not sure _why_ this happened, but it was tricky to figure out.
	// Same thing happened with the menu prop.
	const { onMenuItemClick, createMenuItem, items, menu, ...rest } = props

	const getChildren = (items: IMenu[] | undefined) => {
		const res =
			items &&
			items.map((item: IMenu, index) => {
				return (
					<MenuItemView
						variant="topMenuItem"
						createMenuItem={createMenuItem}
						onMenuItemClick={(item: any) => onMenuItemClick(item)}
						item={item}
						key={index}
						left="0px"
						{...rest}
					/>
				)
			})
		return res
	}

	return (
		<Flex
			{...rest}
			sx={{
				pl: '0px',
				cursor: 'pointer'
			}}
		>
			{getChildren(items)}
		</Flex>
	)
}

export interface TopMenuProps {
	items: IMenuList
	onMenuItemClick: MenuItemClick
	createMenuItem?: CreateMenuItemFunction
}

export const TopMenuItems = (props: TopMenuProps) => {
	const { items } = props
	// // NOTE: we HAD to extract createMenuItem from the props so that it would not be in the ...rest variable
	// // if we passed rest (containing createMenuItem) to the main flex component below, it change the display property from flex to block.
	// // not sure _why_ this happened, but it was tricky to figure out.
	// const leftItems = items.filter((item) => item.position && item.position == 'left')
	// const centerItems = items.filter((item) => !item.position)
	// const rightItems = items.filter((item) => item.position && item.position == 'right')

	const getProps = (items: any) => {
		return { ...props, items }
	}
	// const leftItem = <TopMenuItemList {...getProps(leftItems)} />
	// const centerItem = <TopMenuItemList {...getProps(centerItems)} />
	// const rightItem = <TopMenuItemList {...getProps(rightItems)} />

	return <TopMenuItemList {...getProps(items)} />
}
