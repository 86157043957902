import { useState } from 'react'
import { Size, equals } from '@app/utils'
import useResizeObserver from './useResizeObserver'

/**
 * This component returns the size of the referenced component.
 * It also watches if the referenced component changes in size and triggers a state change.
 * This causes any components that call this hook to re-render if the referenced component size changes
 */
export function useComponentSize(ref: any): Size {
	const [size, setSize] = useState<Size>(new Size())

	const { width = 0, height = 0 } = useResizeObserver({ ref })
	const newSize = new Size({ width, height })
	if (!equals(size, newSize)) {
		// console.log('SizeTriggered', size, newSize)
		setSize(newSize)
	}
	return size
}
