import { applyOperation, getValueByPointer } from '@app/jsonpatch'
import { IJsonAdapter, JsonAdapter } from './JsonAdapter'
import { AdjustValueFunction, IJsonValueProvider, JVPInit, JsonValueProvider, SetValueFunction } from './JsonValueProvider'

/**
 * This interface is used to transform one or more properties in the src object to one or more properties in the dst object.
 * It differs from IJsonAdapter in that it is only meant to transfer one or more properties from the src object to the dst object,
 * whereas an adapter adapts an entire object.  An adapter accepts a src object and returns a dst object.
 * An IJsonTransform, however accepts both src and dst parameters.  The dst object can be altered or transformed, but should not be replaced
 * because an IJsonTransform is only one step in the process of adapting an object, and other transforms may have been already called before this single transform has.
 */
export interface IJsonTransform<S extends object = any, D extends object = any> {
	transform(src: S, dst: D): void
	transformBack?(src: D, dst: S): void
}
export type IJsonTransformList<S extends object = any, D extends object = any> = IJsonTransform<S, D>[]

export class JsonTransformFromJsonValueProviders<S extends object = any, D extends object = any> implements IJsonTransform<S, D> {
	srcProvider: IJsonValueProvider<S>
	dstProvider: IJsonValueProvider<D>
	adapter: IJsonAdapter<S, D> | undefined
	constructor(src: JVPInit<S>, dst: JVPInit<S> | JVPInit<D>, adapter?: IJsonAdapter) {
		this.srcProvider = JsonValueProvider.init<S>(src)
		this.dstProvider = JsonValueProvider.init<D>(dst)
		this.adapter = adapter
	}
	transform(src: S, dst: D): void {
		this.transformProp(src, dst, this.srcProvider, this.dstProvider, false)
	}
	transformBack(src: D, dst: S): void {
		// this seems asymetrical, but when src and dst are passed in, they are already reversed, but our references to src and dst providers are not.
		this.transformProp(src, dst, this.dstProvider, this.srcProvider, true)
	}
	private transformProp(src: any, dst: any, srcProvider: IJsonValueProvider, dstProvider: IJsonValueProvider, isAdaptBack: boolean) {
		let val = srcProvider.getValue(src)
		if (this.adapter) {
			val = isAdaptBack ? (this.adapter.adaptBack ? this.adapter.adaptBack(val) : undefined) : this.adapter.adapt(val)
		}

		if (typeof val == 'undefined') return
		if (typeof val == 'string') val = val.trim()
		if (val === '') return

		dstProvider.setValue(dst, val)
	}
	// adapt(src: S, dst: D): void {
	// 	const val = this.srcProvider.getValue(src)
	// 	let adapted = val
	// 	if (this.adapter) {
	// 		if (Array.isArray(val)) adapted = this.adapter.adapt(val)
	// 		else adapted = this.adapter.adaptItem(val)
	// 	}
	// 	this.dstProvider.setValue(dst, adapted)
	// }
	// adaptBack?(src: D, dst: S): void {
	// 	const val = this.dstProvider.getValue(src)
	// 	let adapted = val
	// 	if (this.adapter) {
	// 		if (Array.isArray(val) && this.adapter.adaptBack) adapted = this.adapter.adaptBack(val)
	// 		else if (this.adapter.adaptItemBack) adapted = this.adapter.adaptItemBack(val)
	// 	}

	// 	this.srcProvider.setValue(dst, adapted)
	// }
}
