import { ReactNode } from 'react'
import { TitleDefinitionList } from './TitleDefinition'
import { IListViewProvider, ProviderRowData } from './IListViewProvider'
import { equals, objectSort, SortField, valueCompare } from '@app/utils'
import anylogger from '@app/anylogger'
import { CellData, RowData } from './ColumnMapper'

const log = anylogger('ListViewAdapter')

export class ListViewAdapter {
	provider: IListViewProvider<any> = { items: [], createRow: () => [] }
	// sourceItems: any[] = []
	displayTitles: TitleDefinitionList = []
	// rawData: ProviderRowData[] = []
	private adapted: DataRecord<any>[] = []
	private sorted: DataRecord<any>[] = []
	// items are available to the client are are the result of adapting, sorting and filtering
	items: DataRecord<any>[] = []
	filterText: string = ''
	sortFields: SortField[] = []
	refresh: () => void
	timerId: ReturnType<typeof setTimeout> | undefined = undefined
	filterDelay: number = 0
	// constructor(provider?: IListViewProvider, displayTitles?: TitleDefinitionList, refresh?: () => void) {
	// 	this.provider = provider ?? { items: [], createColumns: () => [] }
	// 	this.refresh = refresh
	// 	this.sourceItems = this.provider.items
	// 	this.displayTitles = displayTitles
	// 	this.processData()
	// }
	constructor(refresh: () => void) {
		this.refresh = refresh
	}
	public setProvider(provider: IListViewProvider, displayTitles: TitleDefinitionList, calculateColumnSizes: (rawData: any[][]) => void) {
		if (provider == this.provider && displayTitles == this.displayTitles) return

		this.provider = provider
		// this.sourceItems = provider.items
		const forceRecalculate = !equals(this.displayTitles, displayTitles)
		this.displayTitles = displayTitles
		this.processData(calculateColumnSizes, forceRecalculate)
		this.sort()
	}

	public get allTitles(): TitleDefinitionList {
		return this.provider.getAllTitles ? this.provider.getAllTitles() : []
	}
	public get titles(): TitleDefinitionList {
		return this.displayTitles?.length ? this.displayTitles : this.allTitles
	}
	public get rowCount(): number {
		return this.items.length
	}
	public get colCount(): number {
		return this.titles.length ? this.titles.length : this.adapted?.length ? this.adapted[0].colCount : 0
	}
	public setFilterText(text: string, delay: number) {
		this.filterDelay = delay
		const lc = text?.length ? text.toLocaleLowerCase() : ''
		if (this.filterText == lc) return
		this.filterText = lc
		if (this.timerId) clearTimeout(this.timerId)
		this.timerId = setTimeout(() => {
			this.timerId = undefined
			this.filter()
		}, delay)
	}
	public setSort(sortFields: SortField[]) {
		this.sortFields = sortFields
		this.sort()
	}

	getItemByIndex(rowIdx: number) {
		const item = this.items[rowIdx]
		return item
	}
	getItemById(id: any) {
		return this.items.find((item) => item.id == id)
	}
	getIndexOfId(id: any) {
		const item = this.getItemById(id)
		return item ? this.items.indexOf(item) : -1
	}
	getValue(rowIdx: number, colIdx: number) {
		// when filtering, there may be delayed rendering that may be outside of the filtered range
		// so we will return nothing if the idx is outside the row range
		if (rowIdx >= this.items.length) return
		const val = this.getItemByIndex(rowIdx).getValue(colIdx, this.titles)
		// log('val', typeof val, val)

		return val
	}
	getDisplayValue(rowIdx: number, colIdx: number) {
		// when filtering, there may be delayed rendering that may be outside of the filtered range
		// so we will return nothing if the idx is outside the row range
		if (rowIdx >= this.items.length) return
		const val = this.getItemByIndex(rowIdx).getDisplayValue(colIdx, this.titles)
		// log('val', typeof val, val)

		return val
	}
	sort() {
		if (!this.titles || !this.sortFields) {
			this.sorted = this.adapted
			this.filter()
			return
		}

		this.sorted = [...this.adapted]

		this.sorted.sort((a, b) => {
			let res: number = 0
			// log('this.sortFields', this.sortFields)

			for (let ii = 0; ii < this.sortFields.length; ii++) {
				// if a previous compare is already definitive, just break
				if (res) break
				const srt = this.sortFields[ii]
				const idx = this.titles.findIndex((t) => t.name == srt.field)
				if (idx < 0) continue
				const titleDef = this.titles.find((t) => t.name == srt.field)
				const valA = a.getValue(idx, this.titles)
				const valB = b.getValue(idx, this.titles)
				let customRes
				if (this.provider.customCompare && titleDef) {
					customRes = this.provider.customCompare(titleDef, a.item, b.item, valA, valB)
				}
				if (typeof customRes == 'undefined') res = valueCompare(valA, valB, srt?.isNumeric)
				else res = customRes
				if (srt.isDesc) res = res * -1
			}
			return res
		})
		this.filter()
	}
	filter() {
		if (!this.filterText.length) this.items = this.sorted
		else this.items = this.sorted.filter((row, idx) => row.matches(this.titles, this.filterText))
		this.refresh()
	}

	private processData(calculateColumnSizes: (rawData: any[][]) => any, forceRecalculate: boolean) {
		let rawData: any = []
		const titles = this.provider.getAllTitles ? this.provider.getAllTitles() : []

		const adapted = this.provider.items.map((item, idx) => {
			// if (this.adapted.length > idx && this.adapted[idx].item == item) {
			// 	return this.adapted[idx]
			// }

			const row = this.provider.createRow(item, idx)
			// log('row', row)

			rawData.push(row)

			// if there is no id specified by the provider, use the index of the original data.
			// The index will not change, even if the list is sorted, as the sorted values are stored in a separate list.
			const id = this.provider.getId ? this.provider.getId(item) : idx

			const obj = Array.isArray(row) && titles?.length ? this.convertArrayToObject(row, titles) : row
			const data = new DataRecord(id, item, obj)
			return data
		})
		if (this.adapted.length != adapted.length || forceRecalculate) calculateColumnSizes(rawData)
		this.adapted = adapted
	}
	private convertArrayToObject(columns: CellData[], titles?: TitleDefinitionList) {
		if (titles?.length) {
			if (titles.length < columns.length) throw new Error(`Too many columns for the number of titles`)
			return columns.reduce((res: any, col, idx) => {
				const title = titles[idx].name
				res[title] = col
				return res
			}, {})
		} else
			return columns.reduce((res: any, col, idx) => {
				res[idx] = col
			}, {})
	}
}

export class DataRecord<T = any> {
	id: any
	item: T
	obj: any
	constructor(id: any, item: T, obj: any) {
		this.id = id
		this.item = item
		this.obj = obj
	}
	public get colCount(): number {
		return Object.keys(this.obj).length
	}

	getValue(colIdx: number, titles?: TitleDefinitionList) {
		// log('colIdx, titles', colIdx, titles)
		const val = titles?.length ? this.obj[titles[colIdx].name] : this.obj[colIdx]
		if (typeof val == 'boolean') return val ? 'true' : 'false'
		else if (typeof val == 'object' && val.value) return val.value
		else return val
	}
	getDisplayValue(colIdx: number, titles?: TitleDefinitionList) {
		// log('colIdx, titles', colIdx, titles)

		const val = titles?.length ? this.obj[titles[colIdx].name] : this.obj[colIdx]
		if (typeof val == 'boolean') return val ? 'true' : 'false'
		else if (typeof val == 'object' && typeof val.displayValue != 'undefined') return val.displayValue
		else return val
	}

	matches(titles: TitleDefinitionList, filterText: string) {
		// const vals = typeof this.obj == 'object' ? Object.values(this.obj) : this.obj

		for (let titleIdx = 0; titleIdx < titles.length; titleIdx++) {
			const title = titles[titleIdx]
			const val = this.obj[title.name]
			if (typeof val != 'string') continue
			if (val.toLocaleLowerCase().includes(filterText)) return true
		}
		return false
	}
}
export type DataRecordList<T = any> = DataRecord<T>[]
