import { Breakpoint, Theme } from '@mui/material'
import { convertLength } from './cssUtils'
import { getCurrentBreakpoint } from './hooks/getCurrentBreakpoint'
import anylogger from '@app/anylogger'

const log = anylogger('adjustFontSizes')

type BreakpointFactors = {
	[key in Breakpoint]?: number
}

export function adjustFontSizes(themeInput: Theme, width: number, breakpointFactors: BreakpointFactors): Theme {
	const bkp = getCurrentBreakpoint(themeInput.breakpoints, width)

	const factor = breakpointFactors[bkp]
	if (!factor) return themeInput

	const variants = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'button', 'overline']

	const theme = { ...themeInput }
	theme.typography = { ...theme.typography }
	const typography: any = theme.typography

	// Convert between CSS lengths e.g. em->px or px->rem
	// Set the baseFontSize for your project. Defaults to 16px (also the browser default).

	const convert = convertLength(typography.htmlFontSize)

	variants.forEach((variant) => {
		const style = typography[variant]

		const remFontSize = parseFloat(convert(style.fontSize, 'rem'))
		style.fontSize = remFontSize * factor + 'rem'
	})

	return theme
}
