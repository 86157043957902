import { getCssStyle, getElementFont, Point } from '@app/utils'
import { ListItemIcon, Menu, MenuItem, MenuList } from '@mui/material'
import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import { useContext } from 'react'
import anylogger from '@app/anylogger'
import { HTMLRef } from '@app/hooks'

const log = anylogger('PopupMenuOverlay')

type MenuPosition = 'ClickPoint' | 'TopLeft' | 'BottomRight' | 'TopRight' | 'BottomLeft'

export type MenuAction = (e: any) => void
class Context {
	/**
	 * menuItems can be an array of items created with createMenuItem, or any that displays a menu (or not).
	 */
	showPopup!: (menuItems: ReactElement[], anchorRef: HTMLRef, menuPos?: MenuPosition) => void
	createMenuItem!: (text: string, action?: MenuAction, icon?: ReactNode) => any
	closePopup!: () => void
}
export const PopupMenuContext = React.createContext(new Context())

export function usePopupMenu() {
	const context = useContext(PopupMenuContext)
	return context
}

interface PopupMenuOverlayProps {
	children: React.ReactNode
}
let mousePos: Point = { x: 0, y: 0 }

export const PopupMenuOverlay = React.forwardRef(function PopupMenuOverlay(props: PopupMenuOverlayProps, ref: any) {
	const { children } = props
	const [menuItems, setMenuItems] = useState<ReactElement[] | undefined>()
	const [menuPos, setMenuPos] = useState<MenuPosition | undefined>()
	const [anchorRef, setAnchorRef] = useState<HTMLRef>(null)
	const [fontSize, setFontSize] = useState('inherit')

	useEffect(() => {
		// This constantly keeps track of the mousePos so that we know where the mouse is when we click
		const mouseMove = (e: any) => {
			const x = e.clientX
			const y = e.clientY
			mousePos = new Point({ x, y })
		}
		// Bind the event listener
		document.addEventListener('mousemove', mouseMove)
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousemove', mouseMove)
		}
	}, [])
	useEffect(() => {
		if (!anchorRef) return
		const fontSize = getCssStyle(anchorRef as Element, 'font-size') || '16px'
		setFontSize(fontSize)
	}, [anchorRef])

	const createMenuItem = useCallback((text: string, action?: MenuAction, icon?: ReactNode) => {
		return (
			<MenuItem onClick={action}>
				{icon && <ListItemIcon>{icon}</ListItemIcon>}
				{text}
			</MenuItem>
		)
	}, [])

	const showPopup = useCallback((menuItems: ReactElement[], anchorRef: HTMLRef, menuPos: MenuPosition = 'ClickPoint') => {
		if (!anchorRef) throw new Error(`anchorRef is not assigned`)
		if (anchorRef === window) throw new Error(`You cannot use window as the anchorRef`)
		if (anchorRef === window.document) throw new Error(`You cannot use document as the anchorRef`)
		setMenuItems(menuItems)
		setMenuPos(menuPos)
		setAnchorRef(anchorRef)
	}, [])

	const closeMenu = useCallback(() => {
		setMenuItems(undefined)
		setAnchorRef(null)
		setMenuPos(undefined)
	}, [])
	const popupOverlay = useCallback(
		(pos: Point) => {
			if (!menuItems) return null
			let props = {}
			if (!menuPos || !anchorRef || menuPos == 'ClickPoint')
				props = { anchorReference: 'anchorPosition', anchorPosition: { top: pos.y, left: pos.x } }
			else {
				let anchorOrigin
				if (menuPos == 'BottomRight') anchorOrigin = { vertical: 'bottom', horizontal: 'right' }
				else if (menuPos == 'TopLeft') anchorOrigin = { vertical: 'top', horizontal: 'left' }
				else if (menuPos == 'TopRight') anchorOrigin = { vertical: 'top', horizontal: 'right' }
				// BottomLeft
				else anchorOrigin = { vertical: 'bottom', horizontal: 'left' }
				props = { anchorEl: anchorRef, anchorOrigin }
			}
			const alteredClick = (item: ReactNode, ...args: any) => {
				// only dismiss the menu if there is actually an event handler because an item
				// without an action may only be a place holder
				// @ts-ignore
				if (item?.props?.onClick) {
					// @ts-ignore
					item?.props?.onClick(...args)
					setMenuItems(undefined)
				}
			}
			const altered = menuItems.map(
				(m, idx) => m && React.cloneElement(m, { key: idx, onClick: (...args: any) => alteredClick(m, ...args) })
			)

			return (
				<Menu open={Boolean(menuItems)} onClose={closeMenu} {...props} MenuListProps={{ dense: true }} sx={{ fontSize: fontSize }}>
					{altered}
				</Menu>
			)
		},
		[anchorRef, closeMenu, fontSize, menuItems, menuPos]
	)

	return (
		<PopupMenuContext.Provider
			value={{
				showPopup,
				createMenuItem,
				closePopup: closeMenu
			}}
		>
			{popupOverlay(mousePos)}
			{children}
		</PopupMenuContext.Provider>
	)
})
