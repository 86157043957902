import React, { useEffect, useState } from 'react'
import anylogger from '@app/anylogger'

const log = anylogger('useDelayedFilter')

export type SetFilterFunction = (filter: string) => void
export type FilterFunction<T> = (filter: string) => T[]

interface Result<T> {
	filteredItems: T[]
	filter: string
	setFilter: SetFilterFunction
}
export function useDelayedFilter<T>(filterItems: FilterFunction<T>, refreshDelay: number, rawList: T[]): Result<T> {
	const [filteredItems, setFilteredItems] = useState<T[]>([])
	const [filter, setFilter] = useState('')
	const [filterText, setFilterText] = useState('')

	const filterChange = (filter: string) => {
		setFilterText(filter)
	}
	useEffect(() => {
		const id = setTimeout(() => {
			setFilter(filterText)
		}, refreshDelay)
		return () => clearTimeout(id)
	}, [filterText, refreshDelay])

	useEffect(() => {
		// if (!filter) return setFilteredItems(rawList)
		setFilteredItems(filterItems(filter))
	}, [rawList, filter, filterItems])

	return { filteredItems, filter: filterText, setFilter: filterChange }
}
