import React from 'react'
import anylogger from '@app/anylogger'

const log = anylogger('RefCBManager')

export type InterfaceCallback<T> = (intf: T, ref: any) => void
export class RefCallbackManager<T> {
	refs: Map<any, T>
	constructor() {
		this.refs = new Map()
	}
	addRef(ref: React.Ref<HTMLElement>, intf: T) {
		if (ref) this.refs.set(ref, intf)
	}
	forEach(callback: InterfaceCallback<T>) {
		this.refs.forEach((value, ref) => {
			callback(value, ref)
		})
	}
	forOne(ref: React.Ref<HTMLElement>, callback: InterfaceCallback<T>) {
		const val = this.refs.get(ref)
		if (val) callback(val, ref)
	}
	clear() {
		this.refs.clear()
	}
}
