import { useThemeProps } from '@mui/material'
import anylogger from '@app/anylogger'
import {
	MouseTouchEvent,
	MouseTouchUpMoveEvent,
	useBrowserDocWin,
	usePointerController,
	useComponentSize,
	useCurrentRef,
	useEvent,
	useLocalStorageOrState
} from '@app/hooks'
import React, { useCallback, useEffect, useState } from 'react'
import { Point } from '@app/utils'
import { useDownEvent, useUpEvent, useMoveEvent } from '@app/hooks'
import { FlexProps } from './Flex'
import { SplitFlexPrimary, SplitFlexRoot, SplitFlexSecondary, SplitFlexSplitter } from './SplitFlexStyle'

const log = anylogger('SplitFlex')

/**
 * ToDo: It would be nice to have a wider grab area (outside the splitter size), but still show the applicable cursor
 *  in the extr area.  Perhaps a splitter widened by the grap margin, but visually narrowed by a negative padding or margin.
 */

type Orientation = 'v' | 'h'
type SplitFlexProps = FlexProps & {
	/**
	 * Default: content. The initial size of the primary pane. You can set this to a percentage or a fixed number of px.
	 * If you double click the splitter, it will reset the primary panel size to this value. */
	initialSize?: string

	/**
	 * Default: 100px. Specifies the minimum size of the primary (top or left) pane. You can also a percentage. */
	minPrimary?: string

	/**
	 * Default: 100px. Specifies the minimum size of the secondary (bottom or right) pane. You can also a percentage. */
	minSecondary?: string

	/**
	 * Default: 0.5em. Specifies the width or height of the splitter bar. */
	splitterSize?: string

	/**
	 * Default: undefined. Specifies the local storage key to store the splitter position in so that it is
	 * retained across browser refreshes. If not specified, the primary pane will be reset to the initialSize
	 * on every browser refresh.
	 */
	localStorageKey?: string

	/**
	 * Default: true. This is only used if the SplitFlex container changes size. (i.e. If there is a SplitFlex
	 * within a SplitFlex.) If this is true, and you move the splitter,
	 * the primary size will be calculated as a percentage of the SplitFlex size.  Then, if the SplitFlex changes size,
	 * the primary pane will maintain the same percentage size as before it was moved.  If this is not true, then
	 * when you move the splitter, the primary pane will be set to a px width, so that it will keep the same size if the
	 * SplitFlex is resized.
	 */
	resizeByPercentage?: boolean

	/**
	 * This is called when the splitter is moved. The primarySize indicates the size of the primary
	 * position.  It is called on every mouseMove event.
	 */
	onResize?: (primarySize: number) => void

	/**
	 * If set, the primary pane and splitter bar will be hidden, making the secondary use all the content size
	 */
	hidePrimary?: boolean
	/**
	 * If set, the secondary pane and splitter bar will be hidden, making the primary use all the content size
	 */
	hideSecondary?: boolean
}
/**
 * This is a Flex component (row or column) that contains a splitter bar (vertical or horizontal).
 * It can contain 2 and only 2 children, which are placed in the primary and secondary panes
 * respectively, with primary being top or left, depending on the column or row orientation.
 *
 * It sets each pane container to Flex fill and scroll so that each container controls its own scrollbar
 * and does not let any content overflow "bleed" to the parent container.
 *
 * See the {@link SplitFlexProps} for more details.
 */
export const SplitFlex = React.forwardRef(function SplitFlex(inProps: SplitFlexProps, passedRef: any): any {
	// #region Set up Props and validate

	const props = useThemeProps({ props: inProps, name: 'SplitFlex' })
	const {
		initialSize = 'content',
		minPrimary = '100px',
		minSecondary = '100px',
		splitterSize = '5px',
		resizeByPercentage = true,
		localStorageKey,
		onResize,
		children,
		f = 'r',
		fill = true,
		scroll = true,
		hidePrimary = false,
		hideSecondary = false,
		...rest
	} = props
	const flexProps = { f, fill, scroll }

	// after calculating the props, validate them
	if (!Array.isArray(children) || children.length !== 2) throw new Error(`SplitFlex components must have 2 and only 2 children`)

	const primaryContent = children[0]
	const secondaryContent = children[1]

	// #endregion

	// #region Refs, hooks and and States

	const [ref, setRef] = useCurrentRef<HTMLElement>(passedRef)
	const [sepRef, setSepRef] = useCurrentRef<HTMLElement>()
	const [primaryRef, setPrimaryRef] = useCurrentRef<HTMLElement>()
	const [sizeConstraints, setSizeConstraints] = useState({})

	// there is no need to trigger a re-render every time the primary size changes.  We are only using the primary size
	// in the DoMouseDown event, so we calculate it there.
	// const primaryRect = useComponentRect(primaryRef)

	const sepSize = useComponentSize(sepRef)

	// NOTE: We cannot do this because ResizeObeserver does NOT return change notification for position changes
	// In this case (and probably in most) we can calculate the position of the element where we need it.  i.e. in DoMouseDown
	// const sepPos = useComponentPos(sepRef)

	const [primarySize, setPrimarySize] = useLocalStorageOrState(localStorageKey, initialSize)
	const [isVertical, setIsVertical] = useState(true)
	const [isTouch, setIsTouch] = useState<boolean>(false)
	const { browserDocument } = useBrowserDocWin()
	// this is only used to report the size of the primary pane IF the onResize event is specified
	const [calculatedSizeRef, setCalculatedSizeRef] = useState<HTMLElement | null>()
	const calculatedSize = useComponentSize(calculatedSizeRef)

	// this is the initial mouse down location, and used to calculate the dif when the mouse is moved
	const [mouseDownPos, setMouseDownPos] = useState<number>(0)
	// this is the size of the primary panel at the point of mouse down.
	// the primary size is adjusted based on the dif of the mouse pos and the mouseDownSize
	const [mouseDownSize, setMouseDownSize] = useState(0)

	// #endregion

	// #region useEffect

	useEffect(() => {
		if (!sepSize) return
		setIsVertical(sepSize.width < sepSize.height)
	}, [sepSize, sepSize?.width, sepSize?.height])

	useEffect(() => {
		let touch = 'ontouchstart' in window
		if (isTouch !== touch) setIsTouch(touch)
	}, [isTouch])

	useEffect(() => {
		if (!ref || !sepRef) return
		if (!window) return

		let res = {}
		if (minPrimary) {
			res = isVertical ? { minWidth: minPrimary } : { minHeight: minPrimary }
		}
		if (minSecondary) {
			const style = window.getComputedStyle(ref)
			// log('sepSize', sepSize)

			// slack and gap cannot be added together because they may not be in the same units
			const gap = isVertical ? style.rowGap : style.columnGap
			const size = sepRef.getBoundingClientRect()
			let slack = isVertical ? size.width : size.height

			// NOTE: You _need_ the spaces around the - sign or the calc will be invalid
			const val = `calc(100% - ${minSecondary} - ${slack}px - ${gap} - ${gap})`
			const prop = isVertical ? { maxWidth: val } : { maxHeight: val }
			res = {
				...res,
				...prop
			}
		}
		setSizeConstraints(res)
	}, [isVertical, sepRef, ref, minPrimary, minSecondary])

	useEffect(() => {
		if (!onResize) {
			setCalculatedSizeRef(undefined)
			return
		}
		setCalculatedSizeRef(primaryRef)
	}, [onResize, primaryRef])

	useEffect(() => {
		// console.log(calculatedSize)
		if (onResize) onResize(isVertical ? calculatedSize.width : calculatedSize.height)
	}, [onResize, isVertical, calculatedSize])

	// #endregion

	// #region Mouse/TouchEvents
	const downEvent = useCallback(
		(e: MouseTouchEvent): boolean => {
			if (!ref) return false
			if (!sepSize) return false
			if (!primaryRef) return false

			const sepPos = new Point(sepRef?.getBoundingClientRect())
			const rectCentre = isVertical ? sepSize.width / 2 + sepPos.x : sepSize.height / 2 + sepPos.y
			const testPoint = isVertical ? e.pos.x : e.pos.y

			// log('test', isVertical, testPoint, rectCentre, sepSize, sepPos)

			if (Math.abs(testPoint - rectCentre) > e.data) return false

			// log('doMouseDown', e)

			setMouseDownPos(testPoint)

			const primaryRect = primaryRef.getBoundingClientRect()
			setMouseDownSize(isVertical ? primaryRect.width : primaryRect.height)
			return true
		},
		[ref, sepRef, primaryRef, isVertical, sepSize]
	)
	const moveEvent = useCallback(
		(e: MouseTouchUpMoveEvent): boolean => {
			// console.log('mouseDownPos', mouseDownPos)

			if (!mouseDownPos || typeof mouseDownPos == 'undefined') return false

			const mousePos: any = mouseDownPos ? mouseDownPos : 0

			// log('doMouseMove active', mouseDownPos)
			const dif = isVertical ? e.pos.x - mousePos : e.pos.y - mousePos

			let pos = Math.max(mouseDownSize + dif, 0)
			// log('mouseMove', e.clientY, dif, mouseDownPos, pos)

			let size: string
			if (resizeByPercentage && ref) {
				const r = ref.getBoundingClientRect()
				const totalSize = isVertical ? r.width : r.height
				size = Math.round((pos / totalSize) * 100) + '%'
			} else size = `${pos}px`
			if (primarySize !== size) {
				// log('primarySize', size)
				setPrimarySize(size)
				// this is not good here because it does not take the constraints into account
				// if (onResize) onResize(size)
			}
			return true
		},
		[ref, resizeByPercentage, isVertical, mouseDownPos, mouseDownSize, primarySize, setPrimarySize]
	)

	const upEvent = useCallback(
		(e: MouseTouchUpMoveEvent): boolean => {
			// log('mouseUp')
			if (typeof mouseDownPos == 'undefined') return false
			// log('cancelling', mouseDownPos)

			setMouseDownPos(0)
			return true
		},
		[mouseDownPos]
	)

	const doubleClick = useCallback(
		(e: MouseTouchEvent) => {
			setPrimarySize(initialSize)
			return true
		},
		[initialSize, setPrimarySize]
	)
	usePointerController({ ref: sepRef, doubleClick, downEvent, moveEvent, upEvent })
	// #endregion

	return (
		<SplitFlexRoot
			ref={setRef}
			{...flexProps}
			{...rest}
			// onMouseDown={mouseDown}
			// onMouseMove={mouseMove}
			// onTouchStart={touchStart}
			// onTouchEnd={touchEnd}
			// onTouchCancel={touchEnd}
			// onTouchMove={touchMove}
		>
			<Primary
				ref={setPrimaryRef}
				size={primarySize}
				sizeConstraints={sizeConstraints}
				sx={{
					display: hidePrimary ? 'none' : 'inherit'
				}}
			>
				{primaryContent}
			</Primary>
			<Separator
				ref={setSepRef}
				isVertical={isVertical}
				size={splitterSize}
				// onDoubleClick={doubleClick}
				sx={{
					display: hidePrimary || hideSecondary ? 'none' : 'inherit'
				}}
			/>
			{/* <Separator onResize={splitterResized} /> */}
			<Secondary
				sx={{
					display: hideSecondary ? 'none' : 'inherit'
				}}
			>
				{secondaryContent}
			</Secondary>
			{/* {secondaryContent} */}
		</SplitFlexRoot>
	)
})

const Primary = React.forwardRef(function Primary(props: any, passedRef: any) {
	const { size, sizeConstraints, ...rest } = props

	const flex = `0 0 ${size}`
	return <SplitFlexPrimary ref={passedRef} flex={flex} fill {...sizeConstraints} {...rest} />
})

function Secondary(props: any) {
	return <SplitFlexSecondary fill {...props} />
}

const Separator = React.forwardRef(function Separator(props: any, passedRef: any) {
	const { isVertical, size, ...rest } = props

	const [ref, setRef] = useCurrentRef<HTMLElement>(passedRef)
	const cursor = isVertical ? 'col-resize' : 'row-resize'
	const flex = `0 0 ${size}`
	return <SplitFlexSplitter ref={setRef} cursor={cursor} gap="0px" flex={flex} {...rest}></SplitFlexSplitter>
})
