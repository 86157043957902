import { useCallback, useEffect } from 'react'
import { isBrowserServer } from './isBrowserServer'
import anylogger from '@app/anylogger'
import { HTMLRef } from './mouseTouchUtils'

const log = anylogger('useEvent')

export interface EventOptions {
	/**
	 * Whether the event is passive or not. (default: true)
	 */
	passive?: boolean
	/**
	 * Whether to prevent automatic correction of event names.  i.e. from mouseMove to mousemove, and from onMouseMove to mousemove. (default: false)
	 */
	preventEventNameCorrections?: boolean
}
/**
 * This hook creates an event callback for the specified event name.  It also cleans up the events if the ref changes.
 *
 * By default, all events are created as passive to make them more responsive. This means that calling e.preventDefault()
 * does not do anything and causes an error in the browser console.  If you need to call preventDefault(), you can change
 * the event to non-passive by passing passive: false in the {@link EventOptions options}.
 *
 * @param element The element to add the event to (can be undefined, and no event will be attached)
 * @param eventName The name of event to be attached to element.  As a convenience, the event name is changed
 * to lower case before passing it to addEventListener, except for well known event names like DOM* and MS*.
 * To avoid auto-correcting the event name, you can pass preventEventNameCorrections: true in the options.
 * @param callback The callback for the event
 * @param options See {@link EventOptions}
 */
export function useEvent(element: HTMLRef, eventName: string, callback: (e: any) => void, options: EventOptions = {}) {
	const { passive = true, preventEventNameCorrections = false } = options

	const { isServer } = isBrowserServer()

	useEffect(() => {
		if (!element || isServer) return

		let en = eventName
		// as a convenience, we convert all event names to lower case, except for known upper case events such as DOM* and MS*
		// You can prevent this by passing in preventEventNameCorrections: true in the options
		if (!en.startsWith('DOM') && !en.startsWith('MS') && !preventEventNameCorrections) en = en.toLocaleLowerCase()
		if (en.startsWith('on')) en = en.slice(2)

		element.addEventListener(en, callback, { passive: passive })
		return () => {
			element.removeEventListener(en, callback)
		}
	}, [isServer, element, eventName, callback, passive, preventEventNameCorrections])
}
