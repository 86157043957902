import React, { PropsWithChildren, ReactElement } from 'react'
import anylogger from '@app/anylogger'
import { Flex, FlexProps } from './Flex'

const log = anylogger('Centered')

interface CenteredProps extends FlexProps {}

export const Centered = React.forwardRef(function Centered(props: PropsWithChildren<CenteredProps>, ref: any) {
	const { children, ...rest } = props

	return (
		<Flex fill justifyContent="center" alignItems="center" mx="1em" sx={{ textAlign: 'center' }} {...rest}>
			{children}
		</Flex>
	)
})
