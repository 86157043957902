import { Breakpoint, useMediaQuery, useTheme } from '@mui/material'
import anylogger from '@app/anylogger'

const log = anylogger('useBreakpoints')

type BreakpointValue<T> = {
	[key in Breakpoint]?: T
}
/**
 *
 * @param defaultVal This will return either the default value or the value of a specified breakpoint that are passed in.
 * @param breakpointValues A dictionary of breakpoint/value pairs
 * @returns the default value or breakpoint value for the currently active breakpoint
 */
export function useBreakpointValues<T>(defaultVal: T, breakpointValues: BreakpointValue<T>): T {
	const theme = useTheme()

	let retVal: T | undefined = undefined
	const values = Object.entries(breakpointValues)

	for (let ii = 0; ii < values.length; ii++) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const match = useMediaQuery(theme.breakpoints.only(values[ii][0] as Breakpoint))
		if (match && typeof retVal == 'undefined') retVal = values[ii][1]
	}

	return retVal ?? defaultVal
}
