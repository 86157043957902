import { Breakpoint, Breakpoints } from '@mui/material'

export function getCurrentBreakpoint(breakpoints: Breakpoints, width: number): Breakpoint {
	let curBkp: Breakpoint | undefined = undefined
	let curVal: number = -1
	Object.entries(breakpoints.values).forEach((bkp) => {
		const [name, val] = bkp
		if (width > val && val > curVal) {
			curVal = val
			curBkp = name as Breakpoint
		}
	})

	if (curBkp) return curBkp

	curVal = 10000
	// if we did not find a breakpoint, use the smalles overline
	Object.entries(breakpoints.values).forEach((bkp) => {
		const [name, val] = bkp
		if (val < curVal) {
			curVal = val
			curBkp = name as Breakpoint
		}
	})
	return curBkp as unknown as Breakpoint
}
