import React from 'react'
import anylogger from '@app/anylogger'
import { Box, BoxProps, SxProps, Theme } from '@mui/material'

const log = anylogger('Grid')
export interface GridProps extends BoxProps {
	// export interface GridProps extends StyledGridProps {
	cols?: string
	rows?: string
	rowGap?: string
	colGap?: string
	autoRows?: string
	autoCols?: string
	/**
	 * This represents the gap for the Flex, and defaults to {1}, which is a spacing unit of 1 in the MUI theme.
	 * You can override this by specifying gap, gapColumn or gapRow properties.
	 */
	gap?: string | number
	/**
	 * A shorthand for a container that will take up the remaining room of its parent.  i.e flex='1 1 auto'.
	 * This will take up any remaining height or width of the parent Flex container, depending on the main axis of that parent container.
	 * You can easily use the flex, flexGrow, flexShrink and flexBasis props directly for finer tuned control of the child components.
	 *
	 * This also automatically adds the scroll property so that if the child content overflows this flex, it will cause a scroll bar on this flex
	 * instead of passing the scroll requirement up the parent chain.
	 * You can explicity disable automatically adding scroll by explicitly passing scroll="false", * or overflow="xxx".
	 */
	fill?: boolean
	/**
	 * Normally (and you should strive for this), a non-'fill' child will be sized to its content.  But, if you explicitly set the size
	 * of a child, that panel will shrink if required until all the whitespace is eaten up.  This is because flex-shrink is set to 1 by default.
	 * To force a child to retain its height, and not split it with any other flex-shrink siblings, use this property, which is a shorthand for
	 * flex-shrink: 0.  You do not need this unless you explicitly set the size of the flex, which you should try to avoid doing.
	 *
	 */
	fixed?: boolean
	/**
	 * This is a shorthand for overflow: 'auto'.  It is automatically applied with the 'fill' prop, because normally, you would want a 'fill'
	 * pane to scroll itself instead of causing its parent to scroll.
	 *
	 * NOTE:  In order for scroll to work, it seems that all parents must have scroll turned on.
	 */
	scroll?: boolean
	/**
	 * Just adding this because it was not a part of MuiBox
	 */
	cursor?: string
	/**
	 * sx
	 */
	sx?: SxProps
}

export const Grid = React.forwardRef(function Grid(props: GridProps, ref: any) {
	let {
		cols,
		rows,
		gap,
		colGap,
		rowGap,
		autoCols,
		autoRows,
		display,
		children,
		fill,
		scroll,
		fixed,
		sx,
		cursor,
		style,
		...rest
	} = props

	const gridProps = {
		display: display || 'grid',
		gridTemplateColumns: cols,
		gridTemplateRows: rows,
		rowGap: rowGap ? rowGap : gap,
		columnGap: colGap ? colGap : gap,
		gridAutoColumns: autoCols,
		gridAutoRows: autoRows
		// we don't have to pass the rest props because they will be forwarded to our Container ancestor
		// if we ever did have to forward rest, we have to remove children from rest (as above) or it causes
		// a weird runtime error like "Cannot convert a Symbol value to a string"
		// ...rest,
	}

	let adjustedSx: SxProps<Theme> = { ...sx }

	let styleProps: any = {}
	if (fill) {
		styleProps = { ...styleProps, flexGrow: '1' }
		// only add scroll if it is explicitly not specified
		if (typeof scroll == 'undefined') scroll = true
	}
	if (fixed) styleProps = { ...styleProps, flexShrink: '0' }

	if (scroll) adjustedSx = { overflow: 'auto', ...adjustedSx }
	style = { ...style, cursor }

	return (
		<Box {...gridProps} style={style} sx={adjustedSx} {...rest}>
			{children}
		</Box>
	)
})

export interface CellProps extends BoxProps {
	gridRow?: number
	gridColumn?: number
	r?: number
	c?: number
}

export const Cell = React.forwardRef(function Cell(props: CellProps, ref: any) {
	const { r, c, gridRow, gridColumn, ...rest } = props
	const cellProps = {
		gridRow: gridRow ? gridRow : r,
		gridColumn: gridColumn ? gridColumn : c
	}

	return <Box {...cellProps} {...rest}></Box>
})
