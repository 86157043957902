import { arrayBufferToBase64, arrayBufferToString } from './StrUtils'

export function getFileExtension(fileName: string): string {
	if (fileName.length == 0) return ''
	let dot = fileName.lastIndexOf('.')
	if (dot == -1) return ''
	const extension = fileName.slice(dot, fileName.length)
	return extension.toLocaleLowerCase()
}

export function hasExtension(fileName: string, extList: string | string[]): boolean {
	if (!Array.isArray(extList)) extList = [extList]

	const fn = fileName.toLocaleLowerCase()

	for (const ext of extList) {
		if (fn.endsWith(ext)) return true
	}

	return false
}

export function removeExtension(fileName: string) {
	return fileName.replace(/\.[^/.]+$/, '')
}

export async function readAsBase64(file: File) {
	const buf = await file.arrayBuffer()
	return arrayBufferToBase64(buf)
}
export async function readAsString(file: File) {
	const buf = await file.arrayBuffer()
	return arrayBufferToString(buf)
}
