import React, { ReactNode, useEffect } from 'react'
import anylogger from '@app/anylogger'
import { useThemeProps, FormControlLabel, Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps } from '@mui/material'

const log = anylogger('CheckBox')

interface CheckboxProps extends MuiCheckboxProps {
	label?: string | number | React.ReactElement
	labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
}
export const Checkbox = React.forwardRef(function CheckBox(inProps: CheckboxProps, ref: any) {
	const props = useThemeProps({ props: inProps, name: 'CheckBox' })

	const { label, labelPlacement = 'end', ...rest } = props
	let padding = {}
	if (labelPlacement == 'end') padding = { paddingRight: '0.25em' }
	else if (labelPlacement == 'start') padding = { paddingLeft: '0.25em' }
	const cb = (
		<MuiCheckbox
			// @ts-ignore inherit is not part of size, but it works to use the inherited fontSize
			size="inherit"
			sx={{ padding: '0.25em' }}
			{...rest}
		/>
	)
	if (label)
		return (
			<FormControlLabel
				ref={ref}
				label={label}
				labelPlacement={labelPlacement}
				control={cb}
				componentsProps={{ typography: { fontSize: 'inherit', fontWeight: 'inherit' } }}
				sx={{
					margin: '0px',
					...padding
				}}
			/>
		)
	return cb
})
