import { TextField } from '@mui/material'
import { FieldEditorProps } from './EditorService'
import { Checkbox } from './CheckBox'
import { ItemSelector } from './ItemSelector'
import { useCheckboxFieldEditor, useItemSelectorFieldEditor, useTextFieldEditor } from './hooks/useTextFieldEditor'
import anylogger from '@app/anylogger'
import { useCallback } from 'react'
import { useCurrentRef, useEvent } from '@app/hooks'
import { sendKey } from '@app/utils'

const log = anylogger('DefaultFieldEditors')

export function StringEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const editProps = useTextFieldEditor(props)
	const keyDown = useCallback((e: any) => {
		const k = e.key
		if (['Home', 'End', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'PageDown', 'PageUp'].includes(k)) {
			// this prevents any parent controls from taking over the keystrokes.
			// We can't preventDefault(), but we can stopPropagation so that the navigation keys are processed by this control.
			e.stopPropagation()
		}
	}, [])
	return <TextField variant="outlined" {...editProps} onKeyDown={keyDown} />
}
export function MultilineEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const editProps = useTextFieldEditor(props)
	const { parentRef } = props
	let { minRows, maxRows } = props.fieldDef.editor.context
	const [ref, setRef] = useCurrentRef<HTMLElement>()

	const keyDown = useCallback(
		(e: any) => {
			const k = e.key
			if (k == 'Enter') {
				if (e.ctrlKey) {
					if (parentRef) sendKey('Enter', 13, parentRef)
				} else e.stopPropagation()
			} else if (['Home', 'End', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'PageDown', 'PageUp'].includes(k)) {
				// this prevents any parent controls from taking over the keystrokes.
				// We can't preventDefault(), but we can stopPropagation so that the navigation keys are processed by this control.
				e.stopPropagation()
			}
		},
		[parentRef]
	)
	return (
		<TextField inputRef={setRef} multiline variant="outlined" {...editProps} minRows={minRows} maxRows={maxRows} onKeyDown={keyDown} />
	)
}
export function CheckboxEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const editProps = useCheckboxFieldEditor(props)
	return <Checkbox {...editProps} />
}
export function NumberEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const editProps = useTextFieldEditor(props)
	const keyDown = useCallback((e: any) => {
		const k = e.key
		if (['Home', 'End', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'PageDown', 'PageUp'].includes(k)) {
			// this prevents any parent controls from taking over the keystrokes.
			// We can't preventDefault(), but we can stopPropagation so that the navigation keys are processed by this control.
			e.stopPropagation()
		}
	}, [])
	return <TextField type="number" variant="outlined" {...editProps} onKeyDown={keyDown} />
}

export function DateEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const editProps = useTextFieldEditor(props)
	const keyDown = useCallback((e: any) => {
		const k = e.key
		if (['Home', 'End', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'PageDown', 'PageUp'].includes(k)) {
			// this prevents any parent controls from taking over the keystrokes.
			// We can't preventDefault(), but we can stopPropagation so that the navigation keys are processed by this control.
			e.stopPropagation()
		}
	}, [])
	return <TextField type="date" variant="outlined" {...editProps} onKeyDown={keyDown} />
}

export function ItemSelectorEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const editProps = useItemSelectorFieldEditor(props)
	const { allItems, allowNew, multiple = true } = props.fieldDef.editor.context
	return <ItemSelector multiple={multiple} freeSolo={allowNew} options={allItems} {...editProps} />
}
