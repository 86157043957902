import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import anylogger from '@app/anylogger'
import { Flex } from './Flex'
import { useCurrentRef } from '@app/hooks'

const log = anylogger('DLWrapper')

export interface ISrcControlCallback {
	name(): string
	clear(): void
	load(): void
}

interface DelayedLoadSrcWrapperProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
	refCallback: (ref: any, callback: ISrcControlCallback) => void
}
/**
 * This component can be used to wrap a React Element that normally has a src property. Instead of specifying the src property
 * on the wrapped component (i.e. img, etc) pass the source property to this wrapper.  When the ref for this wrapper is created
 * it will pass
 */
export const DelayedLoadSrcWrapper = React.forwardRef(function DelayedLoadSrcWrapper(props: DelayedLoadSrcWrapperProps, passedRef: any) {
	const { src, refCallback, children, ...rest } = props

	if (!children || children == null) throw new Error(`A single child component must be specified for DelayedLoadSrcWrapper`)
	if (Array.isArray(children)) throw new Error(`DelayedLoadSrcWrapper children cannot be an array`)
	if (children instanceof Element) throw new Error(`DelayedLoadSrcWrapper must be an Element`)

	const [ref, setRef] = useCurrentRef<HTMLDivElement>(passedRef)
	const [url, setUrl] = useState<string | undefined>('')

	useEffect(() => {
		if (url && src != url) setUrl(src)
	}, [src, url])

	useEffect(() => {
		if (!ref) return

		const load = () => {
			if (src != url) setUrl(src)
		}
		const clear = () => {
			return setUrl('')
		}
		const name = () => {
			return src ?? ''
		}
		refCallback(ref, { load, clear, name })
	}, [refCallback, ref, src, url])

	const res = React.cloneElement(children as ReactElement, { src: url, ref, ...rest })
	return <Flex ref={setRef}>{res}</Flex>
})
