'use client'
import { useEffect, useRef } from 'react'

export function usePreviousState(value: any) {
	const ref = useRef<any>()
	useEffect(() => {
		ref.current = value
	})
	return ref.current
}
