import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer'
import { RefCallback, RefObject, useEffect, useState } from 'react'
import orig from 'use-resize-observer'
import anylogger from '@app/anylogger'

const log = anylogger('useResizeObserver')

function hasBorderBox(): Promise<boolean> {
	return new Promise<boolean>((resolve) => {
		const ro = new ResizeObserver((entries) => {
			resolve(entries.every((entry) => 'borderBoxSize' in entry))
			ro.disconnect()
		})
		ro.observe(document.body, { box: 'border-box' })
	}).catch((err) => {
		return Promise.resolve(false)
	})
}

if (typeof window != 'undefined') {
	if (!window.ResizeObserver) {
		window.ResizeObserver = ResizeObserverPolyfill
	} else {
		hasBorderBox().then((res) => {
			window.ResizeObserver = ResizeObserverPolyfill
		})
	}
}

export type ObservedSize = {
	width: number | undefined
	height: number | undefined
}
export type ResizeHandler = (size: ObservedSize) => void
export type RoundingFunction = (n: number) => number
type HookResponse<T extends Element> = {
	ref: RefCallback<T>
} & ObservedSize

function useResizeObserver<T extends Element>(
	opts: {
		ref?: RefObject<T> | T | null | undefined
		onResize?: ResizeHandler
		box?: ResizeObserverBoxOptions
		round?: RoundingFunction
	} = {}
): HookResponse<T> {
	const { box, ...rest } = opts
	return orig({ ...rest, box: box || 'border-box' })
}

export default useResizeObserver
