import React, { useCallback } from 'react'
import anylogger from '@app/anylogger'
import { styled, useThemeProps, alpha, lighten } from '@mui/material'
import { Flex, FlexProps } from './Flex'

const log = anylogger('Edge')

type HoveredFunction = (isHovered: boolean) => void

interface EdgeProps extends FlexProps {
	/**
	 * This is the position of the edge. left, right, top or bottom
	 */
	pos: 'left' | 'top' | 'bottom' | 'right'
	/**
	 * This is the thickness of the edge.  Either the height if it is top or bottom, or the width if it is left or right
	 * Default is '100px'
	 */
	thickness?: string
	/**
	 * This is the length of the edge.  Either the width if it is top or bottom, or the height if it is left or right
	 * Default is '50%'
	 */
	length?: string
	/**
	 *
	 */
	setHovered?: HoveredFunction
}
/***
 * This creates an "edge" flex container (top, left, right or bottom) in the containing component.
 * It uses absolute positioning and centers the component  along the edge
 * NOTE: The containing component must have position set to "relative"
 * Typically the Edge itself is transparent, so that it is only the content of the Edge component that is visible.  But, the entire
 * area of the Edge component causes hover effects (for its content) and is clickable.
 * This can be combined with useMouseMoveVisibility to show or hide the edges upon mouse movement
 */
export const Edge = React.forwardRef(function Edge(inProps: EdgeProps, ref: any) {
	const props = useThemeProps({ props: inProps, name: 'Edge' })
	const { pos, thickness = '100px', length = '50%', setHovered, children, sx, ...rest } = props
	const l = pos == 'left'
	const r = pos == 'right'
	const t = pos == 'top'
	const b = pos == 'bottom'
	let p: any = { padding: '2em ' }
	if (l || r) {
		p = { ...p, width: thickness, height: length }
	} else {
		p = { ...p, height: thickness, width: length }
	}
	if (l) p = { ...p, left: 0, justifyContent: 'start', alignItems: 'center', top: '50%', transform: 'translate(0, -50%)' }
	if (r) p = { ...p, right: 0, justifyContent: 'end', alignItems: 'center', top: '50%', transform: 'translate(0, -50%)' }
	if (t) p = { ...p, top: 0, justifyContent: 'center', alignItems: 'start', left: '50%', transform: 'translate(-50%, 0)' }
	if (b) p = { ...p, bottom: 0, justifyContent: 'center', alignItems: 'end', left: '50%', transform: 'translate(-50%, 0)' }
	const mouseEnter = useCallback(
		(props: any) => {
			if (setHovered) setHovered(true)
		},
		[setHovered]
	)
	const mouseLeave = useCallback(
		(props: any) => {
			if (setHovered) setHovered(false)
		},
		[setHovered]
	)
	return (
		<EdgeRoot
			ref={ref}
			onMouseEnter={mouseEnter}
			onMouseLeave={mouseLeave}
			position="absolute"
			{...rest}
			sx={{
				...p,
				...sx
			}}
		>
			{children}
		</EdgeRoot>
	)
})
export const EdgeRoot = styled(Flex, {
	name: 'Edge',
	slot: 'Root',
	overridesResolver: (props, styles) => styles.root
})((obj) => {
	return {
		margin: 'auto'
	}
})
