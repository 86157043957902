import React from 'react'
import anylogger from '@app/anylogger'

const log = anylogger('listUpdater')

export class ListUpdater<T = any> {
	updater: React.Dispatch<React.SetStateAction<T[]>>
	getId: (item: T) => any

	constructor(updater: React.Dispatch<React.SetStateAction<T[]>>, getId: (item: T) => any) {
		this.updater = updater
		this.getId = getId
	}
	updateItem(item: T) {
		this.updateItems([item])
	}
	updateItems(items: T[]) {
		this.updater((prev: T[]) => {
			let res = [...prev]
			items.forEach((item) => {
				const id = this.getId(item)
				const existingItem = prev.find((item) => this.getId(item) == id)
				if (!existingItem) {
					return log('Could not find existing item with id:', id)
				}
				const idx = prev.indexOf(existingItem)
				if (idx >= 0) {
					res[idx] = { ...item }
				} else {
					log('Coud not find index for existing item', existingItem)
				}
			})
			return res
		})
	}
}
