import anylogger from '@app/anylogger'
import { looksLikeJson } from '@app/utils'

const log = anylogger('simpleFetch')

export type ErrorHandler = (err: any) => void
export class HttpStatusError extends Error {
	status: number
	constructor(status: any, mess: string) {
		super(mess)
		this.status = status
	}
}
export function handleStatusError(req: RequestInfo, status: any, errStr: string) {
	if (looksLikeJson(errStr)) {
		console.log('jsonException')
		const err = JSON.parse(errStr)
		throw err
	}
	console.log('text exception:', errStr)
	throw new HttpStatusError(
		status,
		`Error getting request: ${req}-
		Error:${errStr}`
	)
}

/** This is a simple wrapper around fetch.  It:
 *  * throws an error if the http response is not OK
 *  * checks to see if the response looks like json, and if so parses and returns it. If there is an error parsing it returns text.
 *  * It does NOT return the status code of the request, but if there is an error, the Error object will return the status
 *
 */

export async function simpleFetch(req: RequestInfo, init: RequestInit = {}): Promise<string | any> {
	return await fetch(req, init).then(async (res) => {
		if (!res.ok) {
			const text = await res.text()
			return handleStatusError(req, res.status, text)
		}

		const contentType = res.headers.get('content-type')
		const isRawResponse = res.headers.get('IsRawResponse')
		if (isRawResponse) {
			if (!res.body) return null
			return res
			// const b = await res.blob()
			// const arrayBuf = await b.arrayBuffer()
			// const buf = Buffer.from(arrayBuf)
			// return buf
		} else if (contentType?.toLowerCase().startsWith('multipart/objectstream')) {
			return res
		}
		const text = await res.text()

		if (looksLikeJson(text)) {
			try {
				let json = JSON.parse(text)
				return json
			} catch (err) {
				log('Error parsing JSON. Returning string data')
			}
		}
		return text
	})
	// any fetch errors will be returned to the client
	// .catch((err) => {
	// 	log('catch')
	// 	return handleError(err)
	// 	// return Promise.reject(err)
	// })
}
