let canvas: HTMLCanvasElement | undefined = undefined
let divElement: HTMLDivElement | undefined = undefined
import anylogger from '@app/anylogger'

const log = anylogger('textSize')

export function getTextWidth(text: string, font: string) {
	if (!canvas) {
		if (!document) return 0
		canvas = document.createElement('canvas')
		canvas.style.whiteSpace = 'pre'
	}
	const context = canvas.getContext('2d')
	if (!context) return 0
	context.font = font
	const metrics = context.measureText(text)

	return metrics.width
}
export function getTextSizeForElement(el: HTMLElement, text: string) {
	el.textContent = text
	const r = el.getBoundingClientRect()
	return r
}

// this is limited as it does not take padding, etc into account
// best off to create a fixed, visibility: hidden component in the same container that you need to measure
// and then use getTextSizeForElement above
// export function getDivWidth(text: string, font: string) {
// 	if (!divElement) {
// 		if (!document) return 0
// 		divElement = document.createElement('div')
// 		document.body.appendChild(divElement)
// 	}
// 	// clear any existing styles
// 	divElement.removeAttribute('style')
// 	divElement.style.visibility = 'hidden'
// 	divElement.style.position = 'fixed'
// 	divElement.style.display = 'block'
// 	divElement.style.font = font

// 	if (style) {
// 		Object.entries(style).forEach(([key, val]) => {
// 			// @ts-ignore
// 			divElement.style[key] = val
// 		})
// 	}
// 	divElement.textContent = text
// 	const r = divElement.getBoundingClientRect()

// 	return r.width
// }

export function getCssStyle(element: Element, prop: string) {
	return window.getComputedStyle(element, null).getPropertyValue(prop)
}

export function getElementFont(el: Element) {
	const fontWeight = getCssStyle(el, 'font-weight') || 'normal'
	const fontSize = getCssStyle(el, 'font-size') || '16px'
	const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman'

	return `${fontWeight} ${fontSize} ${fontFamily}`
}

export function getPixelSize(el: Element, input: string | number) {
	if (typeof input == 'number') return input
	if (input.endsWith('px')) {
		return parseInt(input)
	}

	if (input.endsWith('rem')) {
		const fs = getCssStyle(document.documentElement, 'font-size') || '16px'
		const fPx = parseInt(fs)
		const val = parseFloat(input)
		return val * fPx
	} else if (input.endsWith('em')) {
		const fs = getCssStyle(el, 'font-size') || '16px'
		const fPx = parseInt(fs)
		const val = parseFloat(input)
		return val * fPx
	}
	return 16
}
