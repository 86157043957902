import React from 'react'
import { FlexProps } from './Flex'
// import { Property } from 'csstype'
import { Flex } from './Flex'
// export interface AppRootProps {
// 	justifyContent?: Property.JustifyContent
// }

/**
 * This base component is configured as a Flex component that can be used at the root of your component tree.
 * It adds several properties that make it useful as a root component
 * * It sets the width and height to be 100vw and 100vh respectively. (See following note)
 * * It sets overflow to auto.  Without doing this, you often get a horizontal scroll bar if your content
 * causes a vertical scroll bar.
 * * It sets the flex direction to column, which is useful to create a page that has a header bar at the top.
 * This can easily be changed to row with an f="r" prop, as per the {@link Flex}
 *
 * Note: Normally, you could just set width and height to 100%, but because both the root html and body elements, by default,
 * are not set to * 100%, you would only be making your container 100% of the body element, which just sizes to its content.
 * You could set the root body and html to 100% as well and this would fix the problem, but some libraries (such as Next) do not set _their_
 * root app element to be 100%, and in fact effectively override the html and body with and height that you may have set.
 * Using this at the root of your component tree simplifies using the entire viewport, without having to worry about tweaking
 * the html/body css or worrying about which library you are using.
 */
export function AppRoot(props: FlexProps) {
	const w = '100vw'
	const h = '100vh'
	return <Flex f="c" width={w} height={h} overflow="auto" {...props}></Flex>
}
