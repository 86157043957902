import anylogger from '@app/anylogger'

const log = anylogger('multipart')

type Header = { [key: string]: string }
export type Part = {
	headers: any
	data: string | Buffer
}

export class MultiPart {
	boundary: string
	parts: Part[] = []
	content?: Buffer = undefined
	constructor(boundary: string) {
		this.boundary = boundary
	}
	addPart(headers: any, data: string | Buffer) {
		this.parts.push({ headers, data })
	}
	getContent(): Buffer {
		if (this.content) return this.content
		const res: Buffer[] = []
		res.push(Buffer.from('--' + this.boundary))
		this.parts.forEach((part) => {
			res.push(Buffer.from('\n'))
			Object.entries(part.headers).forEach(([key, value]) => {
				const str = `${key}: ${value}\n`
				res.push(Buffer.from(str))
			})
			res.push(Buffer.from('\n'))
			res.push(Buffer.from(part.data))
			res.push(Buffer.from(`\n--${this.boundary}`))
		})
		res.push(Buffer.from('--'))
		return Buffer.concat(res)
	}
	addToHeaders(headers: any, contentType: string = 'multipart/mixed') {
		const content = this.getContent()
		headers.append('Content-Type', `${contentType}; boundary=${this.boundary}`)
		headers.append('Content-Length', `${content.length.toString()}`)
	}
}

export function createMultipart(boundary: string, parts: Part[]): Buffer {
	const res: Buffer[] = []
	res.push(Buffer.from('--' + boundary))
	parts.forEach((part) => {
		res.push(Buffer.from('\n'))
		part.headers.forEach((rec: any) => {
			const str = `${rec.key}: ${rec.value}\n`
			res.push(Buffer.from(str))
		})
		res.push(Buffer.from('\n'))
		res.push(Buffer.from(part.data))
		res.push(Buffer.from(`\n${boundary}`))
	})
	res.push(Buffer.from('--'))
	return Buffer.concat(res)
}
