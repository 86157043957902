import {
	MouseTouchEvent,
	MouseTouchUpMoveEvent,
	useArrayRefs,
	usePointerController,
	useCurrentRef,
	useMoveEvent,
	useThrottledAction
} from '@app/hooks'
import { Point, Rect, logRenderReason } from '@app/utils'
import anylogger from '@app/anylogger'
import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react'
import { Flex, FlexProps } from './Flex'

const log = anylogger('DraggableFlex')

export type Axis = 'x' | 'y'
/**
 *
 */
interface DraggableFlexProps extends Omit<FlexProps, 'onItemClick'> {
	/**
	 * Specifies the draggable axis with either 'x' or 'y'.  Note that this must match the flex direction.
	 * i.e. if axis is x, then flex direction must be row
	 */
	axis: Axis
	/**
	 * If true, Allows you to resize items in the list, in addition to dragging. If so, onResized is called when
	 * a column is resized by its edge, instead of onDragged. The resizeThreshold prop specifies the number of pixels
	 * on either size of the break between items where the resize is effective (indicated by a resize cursor)
	 */
	allowResizing?: boolean
	/**
	 * This is called with isDragging set to true when we first start dragging and false when we stop dragging.
	 * It allows clients to know we are dragging, and perhaps disable some other functionality such as context menus.
	 */
	onDragging?: (isDragging: boolean, oldIdx: number) => void
	/**
	 * Called when an item is dragged from one position to another.  oldIdx contains the original index of the item and newIdx
	 * represents the new index that it was dragged to.  The client should then render the children in the new order.
	 */
	onDragged: (oldIdx: number, newIdx: number) => void
	/**
	 * This is called with isResizing set to true when we first start resizing and false when we stop resizing.
	 * It allows clients to know we are resizing, and perhaps disable some other functionality such as context menus.
	 */
	onResizing?: (isResizing: boolean, oldIdx: number) => void
	/**
	 * Called when an item is resized by its edge (instead of being dragged) if allowResize is true.  If newSize is undefined (triggered
	 * by double clicking the right or bottom edge of the item), it means that the column should be reset to its default size.
	 */
	onResized?: (idx: number, newSize: number | undefined) => void
	/**
	 * Called when an item is clicked (passing the index of the item that is clicked).
	 * This will only be called if a drag or resize operation is not started.  So, for a consistent user experience,
	 * you should attach one onItemClick event to DraggableList and not to each child of the list that is passed to it.
	 */
	onItemClick?: (idx: number, e: MouseTouchEvent) => void
	/**
	 * The number of pixels the item has to be dragged before a drag operation is initiated. (default: 10)
	 */
	dragThreshold?: number
	/**
	 * The number of pixels on either side of an edge that dictates whether a mouse drag is considered a resize as opposed to a drag. (default: 5)
	 */
	resizeThreshold?: number
}
/**
 * This can be used to make the items in any horizontal or vertical list draggable to different positions.
 * Just pass the list of items as the children to this component and they will be draggable.
 *
 */
export function DraggableFlex(props: DraggableFlexProps): ReactElement {
	const {
		axis,
		allowResizing = false,
		onDragging,
		onDragged,
		onResizing,
		onResized,
		onItemClick,
		dragThreshold = 10,
		resizeThreshold = 5,
		gap = 0,
		children
	} = props
	if (!children || !Array.isArray(children)) {
		throw new Error(`children must be an array for DraggableFlex`)
	}
	const isHoriz = axis == 'x'
	const [listRef, setListRef] = useCurrentRef<HTMLElement>()

	const [cursor, setCursor] = useState<string | undefined>(undefined)

	const [curPos, setCurPos] = useState<Point | undefined>()

	const downPos = useRef<Point | undefined>()
	const resizeIndex = useRef<number>(-1)
	const dragIdx = useRef<number>(-1)

	const [refs, setRef] = useArrayRefs([children])

	const sizes = useRef<Rect[]>([])
	const internalGetSizes = () => {
		const rects = refs.map((ref) => {
			const r = ref.getBoundingClientRect()
			const rect = new Rect(r)
			return rect
		})

		sizes.current = rects

		return rects
	}

	// we call this in mouse move to ensure that we always have the current sizes in case
	// the parent scrolled and it changed our sizes/positions
	// it only gets call a maximum of every 500ms
	const updateSizes = useThrottledAction(500, () => internalGetSizes())

	const logPositions = useCallback(() => {
		const res = sizes.current.reduce((res, s) => {
			const val = isHoriz ? s.x : s.y
			return res + val + ','
		}, '')
	}, [isHoriz])

	const getSize = useCallback(
		(idx: number) => {
			if (idx >= sizes.current.length) return 0
			try {
				return isHoriz ? sizes.current[idx].width : sizes.current[idx].height
			} catch (err) {
				log.error('Error in getSize: ', err)
				return 0
			}
		},
		[isHoriz]
	)
	const getPos = useCallback(
		(idx: number) => {
			if (idx >= sizes.current.length) return 0
			return isHoriz ? sizes.current[idx].x : sizes.current[idx].y
		},
		[isHoriz]
	)
	const extractPos = useCallback(
		(p: Point) => {
			return isHoriz ? p.x : p.y
		},
		[isHoriz]
	)
	const getNewIdx = useCallback(
		(dif: number): number => {
			if (dif < 0) {
				for (let ii = 0; ii < sizes.current.length; ii++) {
					if (getPos(dragIdx.current) + dif < getPos(ii) + getSize(ii) / 2) return ii
				}
			} else if (dif > 0) {
				for (let ii = sizes.current.length - 1; ii >= 0; ii--) {
					if (getPos(dragIdx.current) + getSize(dragIdx.current) + dif > getPos(ii) + getSize(ii) / 2) return ii
				}
			}
			return dragIdx.current
		},
		[getPos, getSize]
	)

	type ResizeItem = { start: number; end: number }

	const getResizeIndex = useCallback(
		(p: Point) => {
			if (!allowResizing) return -1
			updateSizes()

			const points: ResizeItem[] = []

			for (let ii = 0; ii < sizes.current.length; ii++) {
				const end = ii + 1 < sizes.current.length ? getPos(ii + 1) + resizeThreshold : getPos(ii) + getSize(ii) + resizeThreshold
				points.push({
					start: getPos(ii) + getSize(ii) - resizeThreshold,
					end: end
				})
			}

			const pos = extractPos(p)
			for (let ii = 0; ii < points.length; ii++) {
				const item = points[ii]
				if (pos >= item.start && pos <= item.end) return ii
			}
			return -1
		},
		[allowResizing, extractPos, getPos, getSize, resizeThreshold, updateSizes]
	)
	const checkToDisplayResizeCursor = useCallback(
		(e: MouseTouchEvent) => {
			if (!downPos.current) {
				if (getResizeIndex(e.pos) >= 0) {
					setCursor(isHoriz ? 'col-resize' : 'row-resize')
				} else setCursor(undefined)
			}
			return false
		},
		[isHoriz, getResizeIndex]
	)

	const getDragIndex = useCallback(
		(p: Point) => {
			updateSizes()
			const pos = extractPos(p)
			for (let ii = 0; ii < sizes.current.length; ii++) {
				const item = sizes.current[ii]
				const start = getPos(ii)
				const size = getSize(ii)

				if (pos >= start && pos <= start + size) return ii
			}
			return -1
		},
		[extractPos, getPos, getSize, updateSizes]
	)
	const resized = useCallback(
		(idx: number, p: Point) => {
			const newSize = getSize(idx) + extractPos(p)
			if (onResized) onResized(idx, newSize)
		},
		[extractPos, getSize, onResized]
	)

	const thresholdReached = useCallback(
		(p1: Point, p2: Point) => {
			if (isHoriz) return Math.abs(p1.x - p2.x) > dragThreshold
			else return Math.abs(p1.y - p2.y) > dragThreshold
			return false
		},
		[isHoriz, dragThreshold]
	)
	const mouseDown = useCallback(
		(e: MouseTouchEvent) => {
			downPos.current = e.pos
			setCurPos(e.pos)

			const rIdx = getResizeIndex(e.pos)
			if (rIdx >= 0) {
				if (onResizing) onResizing(true, rIdx)
				resizeIndex.current = rIdx
				return true
			}
			// we have to return true for the pointer controller to send move and up messages
			return true
		},
		[getResizeIndex, onResizing]
	)
	const mouseMove = useCallback(
		(e: MouseTouchUpMoveEvent) => {
			// log('move')
			setCurPos(e.pos)

			if (resizeIndex.current >= 0) return true

			if (dragIdx.current < 0) {
				if (thresholdReached(e.pos, e.downPos)) {
					const idx = getDragIndex(e.downPos)
					if (onDragging) onDragging(true, idx)
					dragIdx.current = idx
					return true
				} else return false
			}
			return true
		},
		[getDragIndex, onDragging, thresholdReached]
	)
	const mouseUp = useCallback(
		(e: MouseTouchUpMoveEvent) => {
			// return true if we were resizing or dragging so that the click event is prevented
			const res = Boolean(resizeIndex.current >= 0 || dragIdx.current >= 0)
			if (resizeIndex.current >= 0) {
				if (onResizing) onResizing(false, resizeIndex.current)

				resized(resizeIndex.current, new Point(e.pos.x - e.downPos.x, e.pos.y - e.downPos.y))
				resizeIndex.current = -1
			} else if (dragIdx.current >= 0) {
				const dif = extractPos(e.pos) - extractPos(e.downPos)
				const newIdx = getNewIdx(dif)
				if (onDragging) onDragging(false, dragIdx.current)

				if (newIdx != dragIdx.current) onDragged(dragIdx.current, newIdx)
				dragIdx.current = -1
			}
			downPos.current = undefined
			setCurPos(undefined)

			return res
		},
		[extractPos, getNewIdx, onDragged, onDragging, onResizing, resized]
	)

	const dragTimer = useRef<ReturnType<typeof setTimeout>>()
	const [haveDelayedDown, setHaveDelayedDown] = useState(false)

	const [shakeIdx, setShakeIdx] = useState(-1)

	const delayedDown = useCallback(
		(e: MouseTouchEvent) => {
			log('delayedDown')
			setHaveDelayedDown(true)
			// we have to set the shakeIdx so the user can see that they are in "drag" mode
			const idx = getDragIndex(e.pos)
			log('shakeIdx', idx, e.pos)

			setShakeIdx(idx)
		},
		[getDragIndex]
	)

	const touchDown = useCallback(
		(e: MouseTouchEvent) => {
			log('touchdown')

			downPos.current = e.pos
			setCurPos(e.pos)

			// check for a resize first.  If we have one, no point in checking for a drag.
			const rIdx = getResizeIndex(e.pos)
			if (rIdx >= 0) {
				if (onResizing) onResizing(true, rIdx)
				resizeIndex.current = rIdx
				return true
			}

			// on touch devices, scrolling has to be the default.  We will only initiate
			// dragging if the user touches for 300 ms without moving.
			// Therefore we will trigger a drag operation after 300 ms, but will cancel it
			// in the move event if we moved past the "no move" threshold. This threshold happens to
			// be the same value that you have to move an item with the mouse before we initiate
			// the drag operation.
			const timerId = setTimeout(() => {
				dragTimer.current = undefined
				delayedDown(e)
			}, 300)
			if (dragTimer.current) clearTimeout(dragTimer.current)
			dragTimer.current = timerId

			return true
		},
		[delayedDown, getResizeIndex, onResizing]
	)

	const touchMove = useCallback(
		(e: MouseTouchUpMoveEvent) => {
			setCurPos(e.pos)

			// if resize or drag, return true to prevent scrolling
			if (resizeIndex.current >= 0 || dragIdx.current >= 0) return true

			// if we get here, we got a touch down, and are waiting for the dragTimer.
			// if we moved past the threshold, cancel the timer because it is a scroll operation.
			// otherwise do nothing and keep waiting

			if (haveDelayedDown) {
				// if we have delayedDown, then we are just waiting to move past the threshold
				// to start a drag operation, but if not we return true to prevent scrolling
				if (thresholdReached(e.pos, e.downPos)) {
					const idx = getDragIndex(e.downPos)
					// log('dragIdx.current', idx, e.pos.y, e.downPos)

					if (onDragging) onDragging(true, idx)
					dragIdx.current = idx
				}
				return true
			} else if (dragTimer.current) {
				// if we are still waiting for the delayed down,  we will check if we moved
				// past the drag threshold.
				// if so, we will cancel the drag event and return false to resume scrolling.
				// If not, we willl continu waiting and return true to prevent scrolling
				if (thresholdReached(e.pos, e.downPos)) {
					// log('cancelling dragTimer')
					clearTimeout(dragTimer.current)
					dragTimer.current = undefined
					return false
				}
				return true
			} else {
				// if don't have a delayed down or a timer, it means we've already moved past
				// the threshold and are scrolling, so return false
				return false
			}

			// if (thresholdReached(e.pos, e.downPos)) {
			// 	if (delayedDownPos) {
			// 		const idx = getDragIndex(e.downPos)

			// 		if (onDragging) onDragging(true, idx)
			// 		setdragIdx.current(idx)
			// 		return true
			// 	} else if (dragTimer.current) {
			// 		setShakeIdx(-1)

			// 		log('cancelling dragTimer')
			// 		clearTimeout(dragTimer.current)
			// 		dragTimer.current = undefined
			// 		return true
			// 	} else return false
			// }
			// return true
		},
		[getDragIndex, haveDelayedDown, onDragging, thresholdReached]
	)
	const touchUp = useCallback(
		(e: MouseTouchUpMoveEvent) => {
			log('touchUp')

			setHaveDelayedDown(false)
			setShakeIdx(-1)
			if (dragTimer.current) {
				clearTimeout(dragTimer.current)
				dragTimer.current = undefined
			}

			// return true if we were resizing or dragging so that the click event is prevented
			const res = Boolean(resizeIndex.current >= 0 || dragIdx.current >= 0)

			if (resizeIndex.current >= 0) {
				if (onResizing) onResizing(false, resizeIndex.current)

				resized(resizeIndex.current, new Point(e.pos.x - e.downPos.x, e.pos.y - e.downPos.y))
				resizeIndex.current = -1
			} else if (dragIdx.current >= 0) {
				// if (thresholdReached(e.pos, e.downPos)) {
				const dif = extractPos(e.pos) - extractPos(e.downPos)
				const newIdx = getNewIdx(dif)
				if (onDragging) onDragging(false, dragIdx.current)

				if (newIdx != dragIdx.current) onDragged(dragIdx.current, newIdx)
				// }
				dragIdx.current = -1
			}
			downPos.current = undefined
			setCurPos(undefined)

			return res
		},
		[extractPos, getNewIdx, onDragged, onDragging, onResizing, resized]
	)
	const downEvent = useCallback(
		(e: MouseTouchEvent) => {
			if (e.isTouch) return touchDown(e)
			else return mouseDown(e)
		},
		[mouseDown, touchDown]
	)
	const moveEvent = useCallback(
		(e: MouseTouchUpMoveEvent) => {
			if (e.isTouch) return touchMove(e)
			else return mouseMove(e)
		},
		[mouseMove, touchMove]
	)
	const upEvent = useCallback(
		(e: MouseTouchUpMoveEvent) => {
			if (e.isTouch) return touchUp(e)
			else return mouseUp(e)
		},
		[mouseUp, touchUp]
	)

	const click = useCallback(
		(e: MouseTouchEvent) => {
			const idx = getDragIndex(e.pos)
			if (onItemClick) onItemClick(idx, e)
			return true
		},
		[getDragIndex, onItemClick]
	)
	const doubleClick = useCallback(
		(e: MouseTouchEvent) => {
			const idx = getResizeIndex(e.pos)

			if (idx >= 0) {
				if (onResized) onResized(idx, undefined)
				return true
			}

			return false
		},
		[getResizeIndex, onResized]
	)

	// const { dragDownEvent, dragMoveEvent, dragUpEvent, dragging } = useDragController({})
	usePointerController({
		ref: listRef,
		click,
		doubleClick,
		downEvent,
		moveEvent,
		upEvent
		// downEvent: dragDownEvent,
		// moveEvent: dragMoveEvent,
		// upEvent: dragUpEvent
	})
	useMoveEvent(listRef, checkToDisplayResizeCursor, false)

	const getResizeChange = useCallback(
		(idx: number) => {
			if (!curPos || !downPos.current) return
			if (idx != resizeIndex.current) return 0
			return extractPos(curPos) - extractPos(downPos.current) + getSize(idx)
		},
		[curPos, extractPos, getSize]
	)
	const getDragChange = useCallback(
		(idx: number) => {
			if (idx != dragIdx.current || !downPos.current || !curPos) return 0
			return extractPos(curPos) - extractPos(downPos.current)
		},
		[curPos, extractPos]
	)
	const getAdjustment = useCallback(
		(idx: number) => {
			if (!downPos.current || !curPos) return 0
			if (resizeIndex.current >= 0) {
				if (idx > resizeIndex.current) return extractPos(curPos) - extractPos(downPos.current)
			} else if (dragIdx.current >= 0) {
				const newIdx = getNewIdx(extractPos(curPos) - extractPos(downPos.current))

				if (newIdx < dragIdx.current) {
					if (idx >= newIdx && idx < dragIdx.current) return getSize(dragIdx.current)
				} else if (newIdx > dragIdx.current) {
					if (idx > dragIdx.current && idx <= newIdx) return getSize(dragIdx.current) * -1
				}
				return 0
			}
			return 0
		},
		[curPos, extractPos, getNewIdx, getSize]
	)

	return (
		<Flex
			f={isHoriz ? 'r' : 'c'}
			ref={setListRef}
			gap={gap}
			sx={{
				cursor: cursor
			}}
		>
			{children.map((child, idx) => (
				<DraggableItem
					ref={(ref: any) => setRef(ref, idx)}
					axis={axis}
					resizeChange={getResizeChange(idx)}
					dragChange={getDragChange(idx)}
					adjustment={getAdjustment(idx)}
					shake={shakeIdx == idx}
					index={idx}
					cursor={cursor}
					key={idx}
				>
					{child}
				</DraggableItem>
			))}
		</Flex>
	)
}

interface DraggableItemProps {
	index: number
	axis: Axis
	resizeChange?: number
	dragChange?: number
	adjustment?: number
	children: ReactElement
	cursor?: string
	shake: boolean
}
const DraggableItem = React.forwardRef(function DraggableItem(
	props: React.PropsWithChildren<DraggableItemProps>,
	passedRef: any
): ReactElement {
	const { index, axis, adjustment, resizeChange, dragChange, children, cursor, shake } = props
	const isHoriz = axis == 'x'
	const [ref, setRef] = useCurrentRef<HTMLElement>(passedRef)

	let transform: string | undefined = undefined
	let newSize: any = {}
	if (resizeChange) {
		newSize = isHoriz ? { width: resizeChange } : { height: resizeChange }
	} else if (dragChange) {
		transform = isHoriz ? `translate(${dragChange}px, 0px)` : `translate(0px, ${dragChange}px)`
	} else if (adjustment) {
		transform = isHoriz ? `translate(${adjustment}px, 0px)` : `translate(0px, ${adjustment}px)`
	}

	let dragStyle: any = { userSelect: 'none' }
	if (cursor) dragStyle = { ...dragStyle, cursor: `${cursor}` }
	if (newSize) dragStyle = { ...dragStyle, ...newSize }

	if (transform) {
		dragStyle = { ...dragStyle, transform: transform }
	}

	let sx = {}

	// this works pretty good, but the element moves up a bit when first selected.
	// const keyframes = useMemo(() => {
	// 	const val = dragChange
	// 	return {
	// 		'@keyframes shake': {
	// 			'0%': {
	// 				transform: isHoriz ? `translate(${val}px, 0px)` : `translate(0px, ${val}px)`
	// 			},
	// 			'25%': {
	// 				transform: isHoriz ? `translate(${val}px, -1px)` : `translate(-1px, ${val}px)`
	// 			},
	// 			'5%': {
	// 				transform: isHoriz ? `translate(${val}px, 0px)` : `translate(0px, ${val}px)`
	// 			},
	// 			'75%': {
	// 				transform: isHoriz ? `translate(${val}px, 1px)` : `translate(1px, ${val}px)`
	// 			},
	// 			'100%': {
	// 				transform: isHoriz ? `translate(${val}px, 0px)` : `translate(0px, ${val}px)`
	// 			}
	// 		}
	// 	}
	// }, [dragChange, isHoriz])
	// if (shake) {
	// 	dragStyle = {
	// 		...dragStyle
	// 		// animation: 'shake 0.1s linear infinite'
	// 	}
	// 	sx = {
	// 		...sx,
	// 		keyframes
	// 	}
	// }

	if (dragChange || resizeChange) dragStyle = { ...dragStyle, zIndex: 10000 }
	let style = children.props.style
	style = { ...style, ...dragStyle }
	if (shake) style = { ...style, fontWeight: 'bold' }

	return React.cloneElement(children, { ref: setRef, style, sx })
})
