import React, { useCallback, useEffect, useState } from 'react'
import { Checkbox } from './CheckBox'
import { Flex, FlexC } from './Flex'
import { TitleDefinition, TitleDefinitionList } from './TitleDefinition'
import anylogger from '@app/anylogger'
import { ICustomDialogCallbackProps } from './ModalDialog'
import { DraggableFlex } from './DraggableFlex'
import { Guid } from '@app/utils'

const log = anylogger('TitleDefinitionListView')

interface TitleDefinitionListProps extends ICustomDialogCallbackProps {
	allTitles: TitleDefinitionList
	selectedTitles: TitleDefinitionList
	onSelectedChanged?: (newSelected: TitleDefinitionList) => void
}
const TitleDefinitionListView = React.forwardRef(function TitleDefinitionListView(props: TitleDefinitionListProps, ref: any) {
	const { allTitles, selectedTitles, onSelectedChanged, dataChanged: setDialogData } = props

	// titles stores the newly selected titles, but not necesarily in the proper order
	const [titles, setTitles] = useState<TitleDefinitionList>(selectedTitles)

	const [groupedTitles, setGroupedTitles] = useState<TitleDefinitionList>(() => {
		const notSelected = allTitles.filter((t) => !selectedTitles.find((st) => st.name == t.name))
		return [...selectedTitles, ...notSelected]
	})
	useEffect(() => {
		if (setDialogData) setDialogData(selectedTitles)
	}, [setDialogData, selectedTitles])

	const getSelected = useCallback((grouped: TitleDefinitionList, selected: TitleDefinitionList) => {
		return grouped.filter((gt) => selected.find((st) => st.name == gt.name))
	}, [])

	const toggleTitle = useCallback(
		(t: TitleDefinition) => {
			let checked = Boolean(titles.find((st) => st.name == t.name))
			checked = !checked
			// log('before', titles)

			let newVals = [...titles]
			if (checked) newVals = [...newVals, t]
			else {
				const idx = titles.findIndex((existing) => t.name == existing.name)
				if (idx >= 0) {
					newVals.splice(idx, 1)
				}
			}
			// log('after', newVals)

			setTitles(newVals)
			const selected = getSelected(groupedTitles, newVals)

			if (selected && onSelectedChanged) onSelectedChanged(selected)
			if (setDialogData) setDialogData(selected)
		},
		[getSelected, groupedTitles, onSelectedChanged, setDialogData, titles]
	)
	const onClick = useCallback(
		(idx: number, e: any) => {
			toggleTitle(groupedTitles[idx])
		},
		[toggleTitle, groupedTitles]
	)
	const dragged = (oldIdx: number, newIdx: number) => {
		setGroupedTitles((prev) => {
			let res = [...prev]
			res.splice(newIdx, 0, ...res.splice(oldIdx, 1))
			const selected = getSelected(res, titles)
			if (setDialogData) setDialogData(selected)
			return res
		})
	}

	const items = groupedTitles.map((t, idx) => {
		const selected = titles.find((st) => st.name == t.name)
		return (
			<Flex key={idx}>
				<Checkbox
					label={t.name}
					checked={Boolean(selected)}
					size="small"
					sx={{
						fontWeight: 'inherit'
					}}
				/>
			</Flex>
		)
	})

	return (
		<FlexC>
			<DraggableFlex axis="y" onDragged={dragged} onItemClick={onClick}>
				{items}
			</DraggableFlex>
		</FlexC>
	)
})
export default TitleDefinitionListView
