import { Box, BoxProps } from '@mui/material'

interface ContrastBackgroundBoxProps extends BoxProps {
	darkMode?: boolean
	opacity?: number
}
function ContrastBackgroundBox(props: ContrastBackgroundBoxProps) {
	let { children, darkMode = false, opacity, ...rest } = props
	if (typeof opacity == 'undefined') opacity = darkMode ? 0.7 : 0.3
	if (opacity > 1) opacity = opacity / 100
	const bgColor = darkMode ? `rgb(0,0,0,${opacity})` : `rgb(255,255,255,${opacity})`
	const color = darkMode ? '#CCCCCC' : 'black'
	return (
		<Box bgcolor={bgColor} color={color} {...rest}>
			{children}
		</Box>
	)
}

export { ContrastBackgroundBox }
