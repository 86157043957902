import React, { useCallback, useRef, useState } from 'react'
import anylogger from '@app/anylogger'
import { Flex } from '..'

const log = anylogger('useBatchListUpdater')

interface Options<T> {
	batchSize?: number
	updateDelay?: number
	itemLocator?: (item: T, list: T[]) => T | undefined
}

/**
 * This can be used to continuously and effeciently update items in a list.  You pass in a setter function (obtained from useState)
 * that is used to update the list. It returns a pushChange function that add items that need to be updated.
 * The updater waits until there are either options.batchSize (default 5) items to update,
 * or until options.updateDelay (default 1000ms) has elapsed.  It then uses the optional itemLocator (by value if not provided)
 * to find the item in the original list (as retrieved from the setter function).
 * @param setter The setter used to update the list (obtained from a useState hook)
 * @param options
 * * batchSize (default 5) - the number of items pushed with pushState before an update on the list is performed
 * * updateDelay (default 1000) - the timeout in ms before the push queue is flushed if there are no updates within that time.
 * * itemLocator - a function receiving an item to find and a list to find it in.  If not provided, the item is located by value (or reference)
 * @returns An object containing a pushState function that accepts either a single item or an array of items to be updated.
 */
export function useBatchListUpdater<T>(setter: React.Dispatch<React.SetStateAction<T[]>>, options?: Options<T>) {
	const { batchSize = 5, updateDelay = 5000 } = options || {}
	let { itemLocator } = options || {}

	const [changes, setChanges] = useState<T[]>([])
	const timerId = useRef<any>()

	const updateItems = useCallback(() => {
		setChanges((changes) => {
			changes.forEach((item) => {
				setter((prev) => {
					let res = [...prev]

					const locator = itemLocator || ((item, list) => list.find((i) => i == item))

					const existingItem = locator(item, prev)
					if (!existingItem) {
						log.error('No update for you, locator!:', item, prev)
						return res
					}

					const idx = prev.indexOf(existingItem)
					if (idx >= 0) {
						res[idx] = { ...item }
					} else {
						log.error('Coud not find index for existing item', existingItem)
					}
					return res
				})
			})
			return []
		})
	}, [itemLocator, setter])

	const pushChange = useCallback(
		(item: T | T[]) => {
			setChanges((prev) => {
				if (Array.isArray(item)) prev.push(...item)
				else prev.push(item)
				if (prev.length >= batchSize) updateItems()
				return prev
			})
			if (timerId.current) clearTimeout(timerId.current)
			timerId.current = setTimeout(() => {
				timerId.current = undefined
				log('triggering Time delay', updateDelay)
				updateItems()
			}, updateDelay)
		},
		[batchSize, timerId, updateDelay, updateItems]
	)

	return { pushChange }
}
