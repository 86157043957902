import anylogger from '@app/anylogger'
import { AddTo } from './StrUtils'

const log = anylogger('treeIterator')

interface ITreeNode {
	getChildren?: any
	children?: any
	getName?: any
	name?: any
}
export type CompareFunction<T> = (node: T) => boolean
export type GetChildrenFunction<T> = (node: T) => Promise<T[]>
export type GetNameFunction<T> = (node: T) => string
/**
 * A function signature that is called when adding to a tree.
 * @param name The name of the tree node to create
 * @param path The full path of the tree node to create
 * @param isIntermediateItem Is true if this is an intermediate parent directory that needs to be created.  Is false is the leaf node that is being created
 * @param parent The parent that this node should be created in
 */
export type CreateNodeFunction<T> = (
	name: string,
	path: string,
	isIntermediateItem: boolean,
	parent: T | undefined
) => T
/**
 * Called when the found node needs to be deleted.
 * @param parent The parent of the node to delete
 * @param childToDelete The node to be deleted
 *
 * You can find the index of the child to delete in the parent's children (implementation dependent) and then use splice to remove the child.
 */
export type DeleteNodeFunction<T> = (parent: T, childToDelete: T) => void

export class TreeIterator<T extends ITreeNode> {
	getChildrenFunction?: GetChildrenFunction<T>
	getNameFunction?: GetNameFunction<T>
	rootNode: T | T[]
	constructor(rootNode: T | T[], getChildren?: GetChildrenFunction<T>, getName?: GetNameFunction<T>) {
		this.rootNode = rootNode
		this.getChildrenFunction = getChildren
		this.getNameFunction = getName
	}
	private findChildFunction = (node: T): GetChildrenFunction<T> | undefined => {
		let prop: GetChildrenFunction<T> | undefined

		prop = node['getChildren']
		if (typeof prop === 'function') return (node) => node['getChildren']()
		if (typeof prop === 'object') return (node) => node['getChildren']

		prop = node['children']
		if (typeof prop === 'function') return (node) => node['children']()
		if (typeof prop === 'object') return (node) => node['children']
		log('Could not find name function or prop', prop)

		return undefined
	}
	private findNameFunction = (node: T): GetNameFunction<T> | undefined => {
		let prop

		prop = node['name']
		if (typeof prop === 'function') return (node) => node['name']()
		if (typeof prop === 'string') return (node) => node['name']

		prop = node['getName']
		if (typeof prop === 'function') return (node) => node['getName']()
		if (typeof prop === 'string') return (node) => node['getName']

		log('Could not find name function or prop', prop)
		return undefined
	}

	private async getChildren(node: T) {
		if (!this.getChildrenFunction) {
			this.getChildrenFunction = this.findChildFunction(node)
		}
		if (!this.getChildrenFunction)
			throw new Error("Could not find 'children' or 'getChildren' functions or properties")
		return this.getChildrenFunction(node)
	}
	private getName(node: T): string {
		if (!this.getNameFunction) {
			this.getNameFunction = this.findNameFunction(node)
		}
		if (!this.getNameFunction) throw new Error("Could not find 'name' or 'getName' functions or properties")
		return this.getNameFunction(node)
	}

	async getFlatNodes(rootNode: T | T[]): Promise<T[][]> {
		let res: T[][] = []
		let nodePath: T[] = []
		const items: T[] = await this.getRootNodes(rootNode)
		for (const item of items) {
			await this.addFlatNodes(item, nodePath, res)
		}

		return res
	}
	async getFlatNames(rootNode: T | T[], separator: string = '/'): Promise<string[]> {
		const list = await this.getFlatNodes(rootNode)
		return list.map((nodes) => {
			let path = ''
			nodes.forEach((node) => {
				path = AddTo(path, separator, this.getName(node))
			})

			return path
		})
	}
	private async addFlatNodes(item: T, nodePath: T[], res: T[][]): Promise<void> {
		const children = await this.getChildren(item)

		if (children.length) {
			for (const child of children) {
				await this.addFlatNodes(child, [...nodePath, item], res)
			}
		} else {
			const newArr = [...nodePath, item]
			res.push(newArr)
		}
	}

	async getRootNodes(rootNode: T | T[]): Promise<T[]> {
		return Array.isArray(rootNode) ? rootNode : await this.getChildren(rootNode)
	}
	async getNodePath(comparer: CompareFunction<T>): Promise<T[] | undefined> {
		return this.getChildPath(this.rootNode, comparer)
	}
	private async getChildPath(rootNode: T | T[], comparer: CompareFunction<T>): Promise<T[] | undefined> {
		let nodePath: T[] = []
		const items: T[] = await this.getRootNodes(rootNode)
		for (const item of items) {
			if (comparer(item)) return [...nodePath, item]
			const childPath = await this.getChildPath(item, comparer)
			if (childPath) return [item, ...childPath]
		}
		return undefined
	}
	// async getNodePath(rootNode: T | T[], comparer: CompareFunction<T>): Promise<T[]> {
	// 	let nodePath: T[] = []
	// 	const items: T[] = await this.getRootNodes(rootNode)
	// 	for (const item of items) {
	// 		if (comparer(item)) return [...nodePath, item]
	// 		const childPath = await this.getNodePath(item, comparer)
	// 		if (childPath) return [item, ...childPath]
	// 	}
	// 	return undefined
	// }
	async findNode(comparer: CompareFunction<T>): Promise<T | undefined> {
		const res = await this.getNodePath(comparer)
		return res?.length ? res[res.length - 1] : undefined
	}
	async getParent(comparer: CompareFunction<T>): Promise<T | undefined> {
		const res = await this.getNodePath(comparer)
		if (!res?.length) return undefined
		if (res.length === 1) return !Array.isArray(this.rootNode) ? this.rootNode : undefined
		return res[res.length - 2]
	}
	async getNextSibling(comparer: CompareFunction<T>): Promise<T | undefined> {
		const parent = await this.getParent(comparer)
		const items = await this.getRootNodes(parent ? parent : this.rootNode)
		const item = await this.findNode(comparer)
		if (!item) return undefined
		const index = items.indexOf(item)
		if (index < items.length - 1) return items[index + 1]
		return undefined
	}
	async getPreviousSibling(comparer: CompareFunction<T>): Promise<T | undefined> {
		const parent = await this.getParent(comparer)
		const items = await this.getRootNodes(parent ? parent : this.rootNode)
		const item = await this.findNode(comparer)
		if (!item) return undefined
		const index = items.indexOf(item)
		if (index > 0) return items[index - 1]
		return undefined
	}

	/**
	 * Recursively adds nodes to create the end target node
	 * @param segments A list of parent directories, with the last item being the leaf item to create
	 * @param createNode A function that gets called when a node needs to be created. See  {@link CreateNodeFunction}
	 */
	async addToTree(segments: string[], createNode: CreateNodeFunction<T>) {
		let target: T[] = await this.getRootNodes(this.rootNode)
		log('this.rootNode', this.rootNode)

		let newItem: T | undefined
		let parent: T | undefined = undefined
		let path = ''
		for (let ii = 0; ii < segments.length; ii++) {
			const segment = segments[ii]
			path = AddTo(path, '/', segment)
			newItem = target.find((item) => this.getName(item) == segment)
			if (!newItem) {
				newItem = createNode(segment, path, ii != segments.length - 1, parent)
			}
			parent = newItem
			target = await this.getChildren(newItem)
		}
	}
	/**
	 * Deletes the node specified by the comparer.
	 * @param comparer The comparer function to identify the node to delete
	 * @param deleteNode Called when the found node needs to be deleted.  i.e. find the index of the child in the parent and then splice.
	 * See {@link DeleteNodeFunction}
	 * @returns The parent node of the node to be deleted.  undefined if the node is not found.
	 * This parent can be checked to see if it has any remaining children, and if not you can choose to delete it as well, recursively to the root.
	 */
	async deleteFromTree(comparer: CompareFunction<T>, deleteNode: DeleteNodeFunction<T>): Promise<T | undefined> {
		const nodes = await this.getNodePath(comparer)
		if (nodes && nodes.length) {
			let par: T, child: T
			if (nodes.length == 1) {
				if (Array.isArray(this.rootNode)) return undefined
				par = this.rootNode
				child = nodes[0]
			} else {
				;[par, child] = nodes.slice(-2)
			}
			deleteNode(par, child)
			return par
		}
		return undefined
	}
}
