import anylogger from '@app/anylogger'
import { useCallback, useEffect, useState } from 'react'

import { useCurrentRef } from '@app/hooks'
import { JsonValueProvider } from '@app/utils'
import { FieldDefinition, useEditorService } from './EditorService'
import { FlexC } from './Flex'
import { ICustomDialogCallbackProps } from './ModalDialog'
import { findFirstFocusableElement } from './findFirstFocusableElement'
import { EditorConfig } from './EditorConfig'

const log = anylogger('EditDialog')

interface EditDialogProps<T extends object = any> extends ICustomDialogCallbackProps {
	// inherited
	// dataChanged?: (data: any)=>void
	data: T
	fields: FieldDefinition<T>[]
	shouldFocusFirst?: boolean
}
export function EditDialog<T extends object = any>(props: EditDialogProps<T>) {
	const { data, fields, dataChanged: dataChangedProp, shouldFocusFirst = true } = props

	const [ref, setRef] = useCurrentRef<HTMLElement>()
	const [focusedFirst, setFocusedFirst] = useState(false)

	useEffect(() => {
		if (!shouldFocusFirst || focusedFirst || !ref) return
		setFocusedFirst(true)
		const child = findFirstFocusableElement(ref)
		if (child) child.focus()
	}, [focusedFirst, ref, shouldFocusFirst])

	const dataChanged = useCallback(
		(data: any) => {
			if (dataChangedProp) dataChangedProp(data)
		},
		[dataChangedProp]
	)
	const svc = useEditorService()
	const getFields = useCallback(() => {
		if (!svc) return
		return fields.map((fld, idx) => {
			return svc.getEditor(idx, fld, data, dataChanged, ref)
		})
	}, [svc, fields, data, dataChanged, ref])

	return (
		<FlexC ref={setRef} padding="0.25em">
			{getFields()}
		</FlexC>
	)
}
