import { formatDateTime } from '@app/utils'
import { useCallback, useRef, useState } from 'react'

/**
 * This returns a function that can be called from the client. The function can be called frequently, but it will be called no more
 * frequently than throttle ms.
 * It can be useful for "polling" the UI for the position of components (i.e. affected by a scroll) without calling the action function too often.
 * @param throttle The minimum interval between calling the action function
 * @param action The action function
 * @returns A function that can be called very frequently, but will not trigger the action function more often than the throttle ms
 */
export function useThrottledAction<T = any>(throttle: number, action: () => T): () => T {
	const lastCall = useRef<number>()
	const lastRes = useRef<T>()
	// const [lastCall, setLastCall] = useState(0)
	// const [lastRes, setLastRes] = useState<T | undefined>(undefined)

	const actionProxy = useCallback(() => {
		const now = Date.now()
		let res: T
		const dif = lastCall.current ? now - lastCall.current : 0
		if (!lastCall.current || dif > throttle) {
			res = action()
			lastRes.current = res
			lastCall.current = now
		} else res = lastRes.current as T
		return res
	}, [action, throttle])
	return actionProxy
}
