import { ReactElement } from 'react'
import ReactDOM from 'react-dom'
import anylogger from '@app/anylogger'
import { CSSParser } from '@app/utils'

const log = anylogger('getMuiStyle')

// if you need a temp component
// best off to create a fixed, visibility: hidden component in the same container that you need to measure
// let div: any = undefined
// export async function createComponent(Element: any, container?: HTMLElement) {
// 	return new Promise((resolve, reject) => {
// 		if (!container && !div) {
// 			div = document.createElement('div')
// 			document.body.appendChild(div)
// 			div.id = 'sampleDiv'
// 			div.style.visibility = 'hidden'
// 			div.style.position = 'fixed'
// 			div.style.display = 'block'
// 		}
// 		if (!container) container = div
// 		let newRef = ReactDOM.render(<Element />, container, () => {
// 			log('container', container)
// 		})
// 		resolve(newRef)
// 	})
// }

// this is useful for getting the default (and overridden) props for a component, but if you need to do this,
// it is likely better to create a fixed, visibility: hidden component in the same container that you need to measure
export async function getMuiStyle(Element: any, slot: string): Promise<any> {
	return new Promise((resolve, reject) => {
		let div = document.createElement('div')
		document.body.appendChild(div)
		ReactDOM.render(<Element />, div, () => {
			try {
				if (!div.firstChild) throw new Error(`Could not render component (no firstChild)`)
				// @ts-ignore
				const classes = div.firstChild.className
				const classNames = classes.split(' ')

				// get the appropriate className
				const className = classNames.find((className: string) => className.includes(slot))

				const styles = Array.from(document.head.getElementsByTagName('style')).map((style) => style.innerHTML)
				const style = styles.find((s) => s.startsWith('.' + className))
				const parser = new CSSParser()
				const parsed = parser.parseCSS(style)
				let res = parsed?.length ? parser.createObjectFromRules(parsed[0].rules) : {}
				resolve(res)
			} finally {
				ReactDOM.unmountComponentAtNode(div)
				document.body.removeChild(div)
			}
		})
	})
}
