import React, { useEffect, useState } from 'react'
import anylogger from '@app/anylogger'
import { secsDiff, sleep } from '@app/utils'

const log = anylogger('useForceRefresh')

type ValueOrFunction<T> = T | (() => T)

interface Props {
	needRefresh: () => boolean
	refreshDelay: ValueOrFunction<number>
	dependencies: any[]
	countdownStatus?: StatusFunction
}
type StatusFunction = (secsLeft: number) => void
export function useDelayedRefresh(props: Props): number {
	const { needRefresh, refreshDelay, dependencies, countdownStatus } = props

	const [refreshValue, setRefreshValue] = useState<number>(1)

	useEffect(() => {
		if (needRefresh()) {
			const started = new Date()
			let delay
			if (typeof refreshDelay === 'function') delay = refreshDelay()
			else delay = refreshDelay
			started.setMilliseconds(started.getMilliseconds() + delay)
			const id = setTimeout(() => {
				setRefreshValue(refreshValue + 1)
			}, delay)
			let intervalId: any = -1
			if (countdownStatus) {
				intervalId = setInterval(async () => {
					// this avoids chrome warning: 'setInterval' handler took XX ms
					await sleep(1)
					const now = new Date()
					// log('status')
					countdownStatus?.(secsDiff(now, started))
				}, 1000)
			}
			return () => {
				clearTimeout(id)
				if (intervalId >= 0) clearInterval(intervalId)
			}
		}
		return () => {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...dependencies, countdownStatus, needRefresh, refreshDelay, refreshValue])

	return refreshValue
}
