import React, { ReactElement } from 'react'
import anylogger from '@app/anylogger'
import { Flex } from './Flex'
import { H3 } from './Text'
import { Image } from './Image'
import { Box } from '@mui/material'
import { useRouter } from 'next/router'

const log = anylogger('TitleBar')

interface TitleBarProps {
	title: string
	siteIconUrl?: string
	rightContent?: ReactElement
	homePage?: string
}
function TitleBar(props: TitleBarProps) {
	const { title, siteIconUrl, rightContent, homePage = '/' } = props
	const router = useRouter()

	const homeClick = () => {
		router.push(homePage)
	}

	return (
		<Flex p="0.5em" alignItems="center" position="relative" justifyContent="space-between">
			<SiteIcon url={siteIconUrl} zIndex={2} onClick={homeClick} />
			<Title title={title} zIndex={1} onClick={homeClick} />
			<Box zIndex={2}>{rightContent}</Box>
		</Flex>
	)
}

const Title = React.forwardRef(function Title(props: any, ref: any) {
	const { title, ...rest } = props

	return (
		<Flex
			flexGrow="1"
			justifyContent="center"
			zIndex={1}
			{...rest}
			sx={{
				position: 'absolute',
				left: '1em',
				right: '1em',
				cursor: 'pointer'
			}}
		>
			<H3>{title}</H3>
		</Flex>
	)
})

const SiteIcon = React.forwardRef(function SiteIcon(props: any, ref: any) {
	const { url } = props
	return (
		<Box
			sx={{
				cursor: 'pointer'
			}}
			{...props}
			// sx={{
			// 	width: '50px',
			// 	height: '50px',
			// 	position: 'absolute',
			// 	left: '1em'
			// }}
		>
			<Image height="50px" src={url} alt="KC Logo" />
		</Box>
	)
})

export { TitleBar }
