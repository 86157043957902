import { BoxProps, styled, useThemeProps } from '@mui/material'
import React, { PropsWithChildren, ReactNode, useEffect, useMemo, useState } from 'react'
import anylogger from '@app/anylogger'

const log = anylogger('ComboBox')

export type ChangeEvent = (item: any) => void
export interface ComboBoxProps extends BoxProps {
	/**
	 * A ComboBoxProvider that provides the list items to be rendered as the drop down items
	 */
	provider: ComboBoxProvider<any>

	/**
	 * The currently selected value. Can be just a string if the provider provides just strings, or an object.
	 */
	value: any

	/**
	 * This is fired when an item is selected.
	 */
	onItemSelected?: ChangeEvent
}

const ComboBox = React.forwardRef(function ComboBox(inProps: PropsWithChildren<ComboBoxProps>, ref: any) {
	const props = useThemeProps({ props: inProps, name: 'ComboBox' })
	const { provider, value, onItemSelected, ...rest } = props
	if (!provider) throw new Error(`You must specify a provider`)

	const [mappedValue, setMappedValue] = useState('')

	const options = useMemo(() => {
		return provider.items.map((item) => {
			return (
				<option key={provider.id(item)} value={provider.id(item)}>
					{provider.label(item)}
				</option>
			)
		})
	}, [provider])

	const internalOnChange = (e: any) => {
		// we have to allow 0 and an empty string to pass through
		// if (typeof e?.target?.value == 'undefined') return

		const item = provider.items.find((item: any) => provider.id(item) == e.target.value)
		if (onItemSelected) onItemSelected(item)
	}

	return (
		// @ts-ignore
		<ComboBoxRoot
			ref={ref}
			{...rest}
			onChange={internalOnChange}
			value={provider.mapValue(value)}
			sx={{
				fontSize: 'inherit',
				lineHeight: 'inherit'
			}}
		>
			{options}
		</ComboBoxRoot>
	)
})
export { ComboBox }

export const ComboBoxRoot = styled('select', {
	name: 'ComboBox',
	slot: 'Root',
	overridesResolver: (props, styles) => styles.root
})(({ theme }) => {
	return {
		fontSize: 'inherit',
		padding: '0.25em '
	}
})

type ValueGetter<T> = string | ((item: T) => string)
export class ComboBoxProvider<T extends object | string = string> {
	items: any[]
	labelFieldOrGetter: ValueGetter<T> | undefined
	idFieldOrGetter: ValueGetter<T> | undefined
	isObject: boolean
	blankLabel: string | undefined
	constructor(
		items: any[],
		labelFieldOrGetter?: ValueGetter<T>,
		idFieldOrGetter?: ValueGetter<T>,
		blankLabel: string = ''
	) {
		this.items = [...items]
		this.blankLabel = blankLabel

		this.isObject = Boolean(items.length) && typeof items[0] == 'object'
		if (this.isObject) {
			if (!labelFieldOrGetter || !idFieldOrGetter)
				throw new Error(`ComboBox: If using an object list, you must specify the labelFeld and idField options`)
			this.labelFieldOrGetter = labelFieldOrGetter
			this.idFieldOrGetter = idFieldOrGetter
			let blank: any = undefined
			this.items.unshift(blank)
		} else {
			this.items.unshift('')
		}
	}

	id(item: T) {
		if (typeof item == 'undefined' || (typeof item == 'string' && item == '')) return 'internalBlankLabel'
		if (this.isObject) {
			if (typeof this.idFieldOrGetter === 'function') return this.idFieldOrGetter(item)
			else return (item as any)[this.idFieldOrGetter!]
		} else {
			return item.toString()
		}
	}
	label(item: T) {
		if (typeof item == 'undefined' || (typeof item == 'string' && item == '')) return this.blankLabel
		if (this.isObject) {
			if (typeof this.labelFieldOrGetter === 'function') return this.labelFieldOrGetter(item)
			else return (item as any)[this.labelFieldOrGetter!]
		} else {
			return item.toString()
		}
	}
	mapValue(item: T) {
		// if (typeof item == 'undefined') return
		// if (!item) return

		return this.id(item)
		// if (!value || !this.items?.length) return''

		// let id = ''
		// if (['string', 'number'].includes(typeof value)) id = value.toString()
		// if (typeof value == 'object') {
		// 	if (value.id) id = getValueFromFunction(value.id)
		// }

		// if (!id) {
		// 	const item = this.items.find((item: any) => item == value)
		// 	if (item) id = item.id()
		// }
		// if (id) setMappedValue(id)
		// else {
		// 	console.error('Could not find id for value:', value, items)
		// }
	}
}
