import React from 'react'
import anylogger from '@app/anylogger'
import { Box } from '@mui/material'

const log = anylogger('Image')

interface ImageProps {}
export const Image = React.forwardRef(function Image(props: any, ref: any) {
	const { src, width, height, ...rest } = props

	return (
		<Box component="img" src={src} width={width} height={height} maxWidth="100%" maxHeight="100%" {...rest}></Box>
	)
})
