import {
	Autocomplete,
	AutocompleteProps,
	AutocompleteRenderInputParams,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	SelectProps,
	styled,
	SxProps,
	TextField,
	Theme,
	useThemeProps
} from '@mui/material'
import anylogger from '@app/anylogger'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'

const log = anylogger('ComboBox')

export type OnChangeEvent = (e: SelectChangeEvent, value: any) => void

type SelectComboBoxProps = SelectProps & {
	/** A List of objects that are rendered in the drop down */
	options: ReadonlyArray<any>

	/** The value object that is selected */
	value: any

	/** A function to extract the display name from the object */
	getOptionLabel?: (o: any) => string

	/** A function to extract the unique Id from an object in the options list */
	getUniqueId?: (o: any) => string

	/** The label for the combo box */
	label?: string

	/** This is the label for the TextField that is displayed if nothing is selected */
	hint?: string

	/** Indicates an error in the combo box */
	error?: boolean

	/** The text displayed below the component.  It can be used for general advice or for errors */
	helperText?: ReactNode
}
export const SelectComboBox = React.forwardRef(function SelectComboBox(inProps: SelectComboBoxProps, ref: any) {
	const props = useThemeProps({ props: inProps, name: 'SelectComboBox' })
	const [optionValues, setOptionValues] = useState<any[]>([])
	const [optionValue, setOptionValue] = useState<any>(undefined)

	const { options, getOptionLabel, getUniqueId, hint, error, helperText, label, value, sx, onChange, ...rest } = props

	useEffect(() => {
		console.log('setting option values', options?.length)

		setOptionValues(
			options.map((o) => {
				const label = getOptionLabel ? getOptionLabel(o) : o
				const id = getUniqueId ? getUniqueId(o) : o
				return (
					<MenuItem value={o} key={id}>
						{label}
					</MenuItem>
				)
			})
		)
	}, [getOptionLabel, getUniqueId, options])

	useEffect(() => {
		// This translates the incoming props.value object
		if (!value) return setOptionValue('')

		const checkId = getUniqueId ? getUniqueId(value) : value
		console.log('checkId', checkId)

		const val = optionValues.find((o: any) => {
			const id = getUniqueId ? getUniqueId(o.props.value) : o.props.value
			// console.log('checking', id, o)

			return checkId == id
		})
		console.log('val', val)

		if (val) {
			setOptionValue(val)
		}
	}, [getUniqueId, optionValues, value])

	const internalOnChange = useCallback(
		(e: SelectChangeEvent<unknown>, menuItem: any) => {
			const value = menuItem?.props?.value
			if (!value) return
			console.log('change', value)

			if (onChange) onChange(e, value)
		},
		[onChange]
	)
	console.log('optionValue', optionValue)

	return (
		<FormControl>
			<InputLabel>{label}</InputLabel>
			<SelectComboBoxRoot onChange={internalOnChange} value={optionValue} {...rest}>
				{optionValues}
			</SelectComboBoxRoot>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	)
})
export const SelectComboBoxRoot = styled(Select, {
	name: 'SelectComboBox',
	slot: 'Root',
	overridesResolver: (props, styles) => styles.root
})(({ theme }) => {
	// for some reason, Storybook (even with a @mui/styles ThemeProvider) does not provide a theme here, so we have silly default values
	// console.log('theme', theme)

	return {}
})
