import {
	Autocomplete,
	AutocompleteProps,
	AutocompleteRenderInputParams,
	styled,
	SxProps,
	TextField,
	Theme,
	useThemeProps
} from '@mui/material'
import anylogger from '@app/anylogger'
import React, { ReactNode } from 'react'

const log = anylogger('ItemSelector')

interface ItemSelectorProps
	extends Omit<AutocompleteProps<any, boolean | undefined, boolean | undefined, boolean | undefined>, 'renderInput'> {
	/** This is the label for the TextField that is displayed if nothing is selected */
	label?: string

	/** Indicates an error in the combo box */
	error?: boolean

	/** The text displayed below the component.  It can be used for general advice or for errors */
	helperText?: ReactNode

	/** This is made optional because a default implemntation is provided. */
	renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode
}
/** This is a light wrapper around the Autocomplete component with multiple set to true.
 * It provides a default implementation of renderInput and adds the following options:
 * * filterSelectedOptions
 * * autoComplete
 * * autoHighlight
 * * autoSelect
 * * clearOnBlur
 *
 * It adds a hint prop for the TextField label. (i.e. the hint text that is displayed when nothing is selected)
 * It adds a fill prop so that the component can take up the rest of the room in a flex parent, typically a flex row.
 */
export const ItemSelector = React.forwardRef(function ItemSelector(inProps: ItemSelectorProps, ref: any) {
	const props = useThemeProps({ props: inProps, name: 'ItemSelector' })
	const { options, multiple, label, sx, error, helperText, ...rest } = props
	const errorProps = { error: error, helperText: helperText }

	let adjustedSx: SxProps<Theme> = { ...sx }

	// we don't need this as the AutoComplete has a fullWidth prop
	// if (fill) adjustedSx = { flexGrow: '1', ...adjustedSx }

	return (
		<Autocomplete
			options={options}
			multiple={multiple}
			filterSelectedOptions
			autoComplete
			autoHighlight
			// This selects the hightlighted option, even if the user clicks outside the list to close it, so we do not want this
			// autoSelect
			clearOnBlur
			renderInput={(params) => <TextField {...errorProps} {...params} inputProps={params.inputProps} label={label} />}
			sx={adjustedSx}
			{...rest}
		/>
	)
})
export const ItemSelectorRoot = styled(Autocomplete, {
	name: 'ItemSelector',
	slot: 'Root',
	overridesResolver: (props, styles) => styles.root
})(({ theme }) => {
	// for some reason, Storybook (even with a @mui/styles ThemeProvider) does not provide a theme here, so we have silly default values
	// console.log('theme', theme)

	return {}
})
