import {
	Autocomplete,
	AutocompleteProps,
	AutocompleteRenderInputParams,
	styled,
	SxProps,
	TextField,
	Theme,
	useThemeProps
} from '@mui/material'
import anylogger from '@app/anylogger'
import React, { ReactNode } from 'react'

const log = anylogger('ComboBox')

interface ComplexComboBoxProps
	extends Omit<AutocompleteProps<any, boolean | undefined, boolean | undefined, boolean | undefined>, 'renderInput'> {
	/** This is the label for the TextField that is displayed if nothing is selected */
	hint?: string

	/** Indicates an error in the combo box */
	error?: boolean

	/** The text displayed below the component.  It can be used for general advice or for errors */
	helperText?: ReactNode

	/** This is made optional because a default implemntation is provided. */
	renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode
}
/** This is a thin wrapper over Autocomplete. It provides a default implementation of renderInput and adds the following options:
 * * autoComplete
 * * autoHighlight
 * * autoSelect
 * * clearOnBlur
 *
 * It adds a hint prop for the TextField label.  (i.e. the hint text that is displayed when nothing is selected)
 * It adds a fill prop so that the component can take up the rest of the room in a flex parent, typically a flex row.
 */
export const ComplexComboBox = React.forwardRef(function ComplexComboBox(inProps: ComplexComboBoxProps, ref: any) {
	const props = useThemeProps({ props: inProps, name: 'ComboBox' })

	const { options, hint, error, helperText, sx, ...rest } = props
	const errorProps = { error: error, helperText: helperText }

	let adjustedSx: SxProps<Theme> = { ...sx }
	// we don't need this as the AutoComplete has a fullWidth prop
	// if (fill) adjustedSx = { flexGrow: '1', ...adjustedSx }

	return (
		<ComplexCombBoxRoot
			options={options}
			autoComplete
			autoHighlight
			autoSelect
			clearOnBlur
			renderInput={(params) => (
				<TextField {...errorProps} {...params} inputProps={params.inputProps} label={hint} />
			)}
			sx={adjustedSx}
			{...rest}
		/>
	)
})
export const ComplexCombBoxRoot = styled(Autocomplete, {
	name: 'ComboBox',
	slot: 'Root',
	overridesResolver: (props, styles) => styles.root
})(({ theme }) => {
	// for some reason, Storybook (even with a @mui/styles ThemeProvider) does not provide a theme here, so we have silly default values
	// console.log('theme', theme)

	return {}
})
