import { AddTo } from './StrUtils'

export class LogXXX {
	constructor() {}
	log = (...args: any) => {
		console.log(...args)
	}
	logArray = (prefix: string, array: any[], getValue: (item: any) => string) => {
		const str = array.reduce((res, item) => {
			return AddTo(res, ', ', getValue(item))
		}, '')
		this.log(prefix, str)
	}
}
