import React, { MouseEventHandler, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import anylogger from '@app/anylogger'
import { useComponentHeight, useComponentWidth, useCurrentRef, useLocalStorageOrState } from '@app/hooks'
import { Text } from './Text'
import { getElementFont, getNextArrayItem, getPrevArrayItem, getTextWidth, logRenderReason } from '@app/utils'
import { Property } from 'csstype'
import {
	Box,
	BoxProps,
	MenuItem,
	styled,
	Table as MTable,
	TableHead as MTableHead,
	TableRow as MTableRow,
	TableBody as MTableBody,
	TableCell as MTableCell,
	unstable_composeClasses as composeClasses,
	generateUtilityClass,
	generateUtilityClasses,
	alpha,
	useThemeProps
} from '@mui/material'
import { TitleDefinition, TitleDefinitionList } from './TitleDefinition'
import { Theme } from '@mui/material'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import { Flex } from './Flex'
import { transformList } from '@app/utils'
import clsx from 'clsx'
import { SelectMode } from './hooks/useSelectionController'
const log = anylogger('ListView')

export interface ListViewRowClasses {
	/** Styles applied to the root element. */
	root: string
	/** State class applied to the content element when selected. */
	selected: string
	/** State class applied to the content element when focused. */
	focused: string
	hover: string
	separator: string
	sticky: string
}

export type ListViewRowClassKey = keyof ListViewRowClasses

export function getListViewRowUtilityClass(slot: string): string {
	return generateUtilityClass('ListView', slot)
}

const rowClasses: ListViewRowClasses = generateUtilityClasses('ListView', ['root', 'selected', 'focused', 'hover', 'separator', 'sticky'])

// this generates a list of classes that are applicable given the ownerState.
// i.e. "ListView-row Mui-selected Mui-focused ListView-showSeparator"
// Mui-selected will not be returned as per below, if selctedMode is 'none' or isSelected is false.
// These class names are then passed to the appropriate components and the "Style" classes
// can test for their existence and apply the appropriate css values as per ListViewRowStyle below
const getRowClasses = (ownerState: any) => {
	const { classes, selectMode, isSelected, hover, showFocused, isFocused, showHover, showSeparator } = ownerState
	const slots = {
		row: [
			'row',
			selectMode != 'none' && isSelected && 'selected',
			showHover && 'hover',
			showFocused && isFocused && 'focused',
			showSeparator && 'separator'
		],
		item: ['item']
	}

	const res = composeClasses(slots, getListViewRowUtilityClass, classes)

	return res
}
const getTitleClasses = (ownerState: any) => {
	const { classes, stickyHeader } = ownerState
	const slots = {
		title: ['row', stickyHeader && 'sticky']
	}

	const res = composeClasses(slots, getListViewRowUtilityClass, classes)

	return res
}

type ValueOrFunction<T> = T | (() => T)
export interface IListItem {
	data: any
	id: ValueOrFunction<string>
	adornments?: ValueOrFunction<React.ReactNode>
	columns: ValueOrFunction<ReactNode[]>
}
export type IListItemList = IListItem[]

function getAlignmentProps(title: TitleDefinition) {
	let res: any = {}
	if (title) {
		res.textAlign = title.alignment
		res.verticalAlign = title.verticalAlignment
	}
	return res
}

class ListItem {
	data: IListItem
	origIndex: number
	constructor(data: IListItem, origIndex: number) {
		this.data = data
		this.origIndex = origIndex
	}
	id = () => this.getValue(this.data.id)
	columns = () => {
		const cols = this.getValue(this.data.columns)
		return cols.map((col) => this.getValue(col))
	}

	private getValue<T>(valOrFunc: ValueOrFunction<T>, ...args: any[]): T {
		return typeof valOrFunc === 'function' ? (valOrFunc as (...args: any) => T)(...args) : valOrFunc
	}
	// instead of using this and forcing the component creation to be done in the adapter,
	// we can pass in a cell component directly to the ListView
	// getComponent(val: string, col: number) {
	// 	let res
	// 	if (this.data.getComponent) res = this.data.getComponent(val, this.origIndex, col)
	// 	return res ?? val
	// }

	adornments(parentHover: boolean): React.ReactNode {
		return this.data.adornments ? this.getValue(this.data.adornments, parentHover) : null
	}
}
type ListItemList = ListItem[]

const SortImage = (props: any) => {
	const { sorted, isAsc } = props
	if (!sorted) return <Text width="16px" />

	return isAsc ? (
		<ArrowDropUp
			sx={{
				fontSize: 'inherit'
			}}
		/>
	) : (
		<ArrowDropDown
			sx={{
				fontSize: 'inherit'
			}}
		/>
	)
}
export const ListViewHeader = React.forwardRef(function ListViewHeader(props: any, ref: any) {
	const { titles, titleClick, sortIndex, sortAsc, TableHead, TableRow, TableCell, gap, adornments, ownerState } = props

	// this gets generates the ListView-sticky class name (if configured) and passes it to the
	// TableHead class (which is an alias for ListViewTitleStyle), which applies the appropriate css
	// to make the header "sticky"
	const classes = getTitleClasses(ownerState)

	const renderTitle = () => {
		const content = titles.map((val: any, idx: number) => {
			const alignment = getAlignmentProps(titles[idx])
			const calcGap = idx == 0 ? '0px' : gap

			return (
				<TableCell
					ref={ref}
					key={idx}
					onClick={() => titleClick(idx)}
					sx={{
						align: 'center',
						whiteSpace: 'nowrap',
						userSelect: 'none',
						...alignment
					}}
				>
					<Flex gap="0px" alignItems="center">
						{val.name}
						<SortImage sorted={sortIndex == idx} isAsc={sortAsc} />
					</Flex>
				</TableCell>
			)
		})
		return (
			<TableHead className={clsx(classes.title, '')}>
				<TableRow ownerState={ownerState}>
					{/* <tr key={row}>
					{content}
					{adornmentSpacer()}
				</tr> */}
					{content}
				</TableRow>
			</TableHead>
		)
	}
	return renderTitle()
})

interface ListViewItemProps {
	ownerState: any
	item: ListItem
	selected?: string[]
	focused?: string
	showHover?: boolean
	ItemClick?: (item: any, e: any) => void
	row: number
	gap: string
	titles: TitleDefinition[] | undefined
	TableCell: any
	TableRow: any
	getComponent?: (val: ReactNode, row: number, col: number) => any
	verticalAlignment?: Property.VerticalAlign
}
export const ListViewLine = React.forwardRef(function ListViewLine(props: ListViewItemProps, ref: any) {
	let {
		item,
		selected,
		focused,
		showHover,
		ItemClick = () => {},
		row,
		gap,
		titles,
		TableCell,
		TableRow,
		getComponent,
		verticalAlignment,
		ownerState
	} = props
	if (!ref) ref = React.createRef<HTMLElement>()

	const [hasMouse, setHasMouse] = useState(false)

	const isSelected = selected?.includes(item.id())
	const isFocused = focused == item.id()
	ownerState = useMemo(() => {
		return { ...ownerState, isSelected, isFocused }
	}, [ownerState, isSelected, isFocused])

	const classes = useMemo(() => getRowClasses(ownerState), [ownerState])

	const clicked = useCallback(
		(e: any) => {
			e.stopPropagation()
			e.preventDefault()
			ItemClick(item, e)
		},
		[ItemClick, item]
	)
	const mouseEnter = useCallback(() => {
		setHasMouse(true)
	}, [])
	const mouseLeave = useCallback(() => {
		setHasMouse(false)
	}, [])
	const [sx, setSx] = useState({
		wordWrap: 'break-word',
		overflow: 'hidden'
	})

	const getContent = useCallback(() => {
		return item.columns().map((val, idx) => {
			let alignment = {}
			// use the general vertical alignment, if present
			if (verticalAlignment) alignment = { ...alignment, verticalAlign: verticalAlignment }
			// but this can be overwritten with a verticalAlignment from the title definitions
			if (titles) alignment = { ...alignment, ...getAlignmentProps(titles[idx]) }

			let comp
			if (getComponent) comp = getComponent(val, item.origIndex, idx)
			else comp = val

			return (
				<TableCell key={idx} ownerState={ownerState} className={clsx(classes.item, '')} sx={sx}>
					{comp}
				</TableCell>
			)
		})
	}, [TableCell, classes.item, getComponent, item, ownerState, sx, titles, verticalAlignment])

	const adornmentHeight = useComponentHeight(ref)
	const getAdornment = useCallback(() => {
		let adornment = item.adornments(hasMouse)
		if (!adornment) return null

		// we must specify the width of the cell, or it will be ignored, and the other cells will take all the space
		return (
			<td
				style={{
					textAlign: 'center'
				}}
				width={adornmentHeight}
			>
				{adornment}
			</td>
		)
	}, [adornmentHeight, hasMouse, item])

	return (
		<TableRow
			ownerState={ownerState}
			ref={ref}
			key={row}
			className={clsx(classes.row, '')}
			style={{
				width: '100%',
				maxWidth: '100%'
			}}
			onClick={clicked}
			onMouseEnter={mouseEnter}
			onMouseLeave={mouseLeave}
		>
			{getContent()}
			{getAdornment()}
		</TableRow>
	)
})

interface ListViewProps extends BoxProps {
	items: IListItemList
	localStorageKey?: string
	selectMode?: SelectMode
	preventTextSelection?: boolean
	preveventCellWordWrap?: boolean
	itemClicked?: (item: IListItem | undefined) => void
	itemFocused?: (item: IListItem | undefined) => void
	selectionChanged?: (selected: IListItemList) => void
	titles?: TitleDefinitionList
	getComponent?: (val: ReactNode, row: number, col: number) => any
	gap?: string
	verticalAlignment?: Property.VerticalAlign
	showSeparator?: boolean
	/**
	 * If true, then the ListView will show hover effects for each row
	 */
	showHover?: boolean
	/**
	 * If true, then the ListView will show the currently focused row
	 */
	showFocused?: boolean
	/**
	 * This component uses the MUI Table underneath.  This variant prop is passed
	 * to the underlying table component so that you can control the look of this ListView
	 * by configuring the variants in your theme.
	 */
	stickyHeader?: boolean
}

export const ListView = React.forwardRef(function ListView(inProps: ListViewProps, passedRef: any) {
	const props = useThemeProps({ props: inProps, name: 'ListView' })
	const { items: itemData, ...mostProps } = props
	let {
		itemClicked,
		itemFocused,
		selectionChanged,
		selectMode = 'single',
		localStorageKey,
		titles,
		gap = '0.5em',
		verticalAlignment,
		getComponent,
		preventTextSelection,
		preveventCellWordWrap,
		showHover,
		showSeparator,
		showFocused,
		...rest
	} = mostProps

	const ownerState = useMemo(() => {
		return { selectMode, showHover, showSeparator, showFocused }
	}, [selectMode, showHover, showSeparator, showFocused])

	const [items, setItems] = useState<ListItem[]>([])
	const [selected, setSelected] = useLocalStorageOrState(localStorageKey ? localStorageKey + '.selected' : undefined, [])
	const [focused, setFocused] = useLocalStorageOrState(localStorageKey ? localStorageKey + '.focused' : undefined, '')
	let [shiftPos, setShiftPos] = useState(undefined)

	const [sorted, setSorted] = useState<ListItem[]>([])
	const [sortIndex, setSortIndex] = useState(-1)
	const [sortAsc, setSortAsc] = useState(true)

	let sxProps = {}
	if (preventTextSelection) sxProps = { ...sxProps, userSelect: 'none' }
	if (preveventCellWordWrap) sxProps = { ...sxProps, whiteSpace: 'nowrap' }

	// let Table: any, TableHead: any, TableRow: any, TableCell: any, TableBody: any, HeaderCell: any
	// const useMui = false
	// const useHtml = false
	// if (useMui) {
	// 	Table = MTable
	// 	TableHead = MTableHead
	// 	HeaderCell = MTableCell
	// 	TableRow = MTableRow
	// 	TableCell = MTableCell
	// 	TableBody = MTableBody
	// } else if (useHtml) {
	// 	Table = 'table'
	// 	TableHead = 'thead'
	// 	HeaderCell = 'td'
	// 	TableRow = 'tr'
	// 	TableCell = 'td'
	// 	TableBody = 'tbody'
	// } else {
	// 	Table = MTable
	// 	TableHead = ListViewTitleStyle
	// 	HeaderCell = ListViewTitleItemStyle
	// 	TableRow = ListViewRowStyle
	// 	TableCell = ListViewItemStyle
	// 	TableBody = 'tbody'
	// }

	const [ref, setRef] = useCurrentRef<HTMLElement | null>(passedRef)
	const [titleRef, setTitleRef] = useCurrentRef<HTMLElement>()

	const width = useComponentWidth(ref)

	const doSetSelected = useCallback(
		(ids: string[]) => {
			setSelected(ids)
			const listItems = ids.map((id) => sorted.find((item) => item.id() == id)).filter(Boolean) as ListItemList
			const items = listItems.map((item) => item.data)
			selectionChanged && selectionChanged(items)
		},
		[selectionChanged, setSelected, sorted]
	)

	useEffect(() => {
		if (!itemData) return setItems([])
		setItems((prev) => {
			const res = transformList(
				itemData,
				prev,
				(src: any, dst: any) => src == dst.data,
				(item: any, idx: number) => new ListItem(item, idx)
			)
			return res
		})
	}, [itemData])

	useEffect(() => {
		if (sortIndex < 0 || !titles?.length) return

		const idx = titles.findIndex((t) => t.initialSort)

		if (idx >= 0) {
			if (titles[idx].defaultToDescending) setSortAsc(false)
			else setSortAsc(true)
			setSortIndex(idx)
		}
	}, [sortIndex, titles])

	useEffect(() => {
		if (sortIndex < 0 || !titles) {
			return setSorted(items)
		}

		const def = titles[sortIndex]

		const list = items.sort((a, b) => {
			let res: number
			const valA = a.columns()[sortIndex]
			const valB = b.columns()[sortIndex]
			if (typeof valA != 'string' || typeof valB != 'string') {
				log.error('Sorting on non-string column values is not supported')
				return 0
			}
			if (typeof valA == 'undefined' || typeof valB == 'undefined') {
				if (!valA && !valB) res = 0
				if (!valB) res = 1
				else res = -1
			} else if (def.isNumeric) {
				const valA = a.columns()[sortIndex] ?? 0
				const valB = b.columns()[sortIndex] ?? 0
				// @ts-ignore
				const numA = parseFloat(valA)
				// @ts-ignore
				const numB = parseFloat(valB)
				res = numA - numB
			} else if (typeof valA == 'number' && typeof valB == 'number') {
				res = valA - valB
			} else {
				const valA = a.columns()[sortIndex] ?? ''
				const valB = b.columns()[sortIndex] ?? ''
				// @ts-ignore
				res = valA.localeCompare(valB)
			}
			if (!sortAsc) res = res * -1
			return res
		})
		setSorted([...list])
	}, [items, sortIndex, sortAsc, titles])

	const [font, setFont] = useState('')
	const [titleFont, setTitleFont] = useState('')

	useEffect(() => {
		if (!ref) return

		const f = getElementFont(ref)
		if (f != font) setFont(f)
	}, [font, ref, width])

	useEffect(() => {
		if (!titleRef) return

		const f = getElementFont(titleRef)
		if (f != titleFont) setTitleFont(f)
	}, [titleFont, titleRef, width])

	const getItemById = useCallback(
		(id: string) => {
			if (!id) return undefined
			return sorted.find((item) => item.id() == id)
		},
		[sorted]
	)

	const selectRange = useCallback((list: ListItem[], startItem: ListItem, endItem: ListItem) => {
		let startIdx = list.indexOf(startItem)
		let endIdx = list.indexOf(endItem)
		const start = Math.min(startIdx, endIdx)
		const end = Math.min(Math.max(startIdx, endIdx), list.length - 1)

		let sel = []
		for (let ii = start; ii <= end; ii++) {
			sel.push(list[ii])
		}
		return sel
	}, [])
	const keyDown = useCallback(
		(e: any) => {
			if (!sorted?.length) return
			if (!['ArrowUp', 'ArrowDown'].includes(e.key)) return
			if (!e.shiftKey) {
				setShiftPos(undefined)
			}
			let focusedItem = getItemById(focused)

			if (selectMode == 'multi' && e.shiftKey) {
				if (!focusedItem) {
					focusedItem = sorted[0]
					setFocused(focusedItem.id())
				}
				const pos = shiftPos || focusedItem
				let newVal = undefined
				if (e.key == 'ArrowUp') {
					newVal = getPrevArrayItem(sorted, pos, 'last')
				} else if ((e.key = 'ArrowDown')) {
					newVal = getNextArrayItem(sorted, pos, 'first')
				}

				if (!newVal) return
				setShiftPos(newVal)
				const vals = selectRange(sorted, newVal, focusedItem)
				doSetSelected(vals.map((item) => item.id()))
				return
			}

			if (e.key == 'ArrowUp') {
				const prev = getPrevArrayItem(sorted, focusedItem, 'last')

				if (!prev) return
				setFocused(prev.id())
				if (selectMode != 'none') {
					doSetSelected([prev.id()])
					itemClicked && itemClicked(prev.data)
				}
			} else if (e.key == 'ArrowDown') {
				const next = getNextArrayItem(sorted, focusedItem, 'first')
				if (!next) return
				setFocused(next.id())
				if (selectMode != 'none') {
					doSetSelected([next.id()])
					itemClicked && itemClicked(next.data)
				}
			}
		},
		[focused, getItemById, itemClicked, selectMode, selectRange, setFocused, doSetSelected, shiftPos, sorted]
	)

	const ItemClick = useCallback(
		(item: ListItem, e: any) => {
			if (!sorted.length) return
			let oldFocused = getItemById(focused)
			if (!e.shiftKey) {
				setFocused(item.id())
				setShiftPos(undefined)
			}

			if (selectMode == 'none') return

			if (selectMode == 'multi' && (e.ctrlKey || e.shiftKey)) {
				if (e.ctrlKey) {
					if (selected.includes(item.id())) {
						doSetSelected(selected.filter((value: any) => value != item.id()))
					} else {
						doSetSelected([...selected, item.id()])
					}
				} else if (e.shiftKey) {
					if (!oldFocused) oldFocused = sorted[0]
					const end = getItemById(item.id())
					if (!end) return
					const vals = selectRange(sorted, oldFocused, end)
					doSetSelected(vals.map((item) => item.id()))
				}
			} else {
				const id = item.id()
				doSetSelected([id])
			}
			itemClicked && itemClicked(item.data)
		},
		[focused, getItemById, itemClicked, selectMode, selectRange, selected, setFocused, doSetSelected, sorted]
	)

	const titleClick = useCallback(
		(index: number) => {
			if (!titles) return setSortAsc(!sortAsc)

			if (index == sortIndex) setSortAsc(!sortAsc)
			else {
				const def = titles[index]
				if (def.defaultToDescending) setSortAsc(false)
				else setSortAsc(true)
				setSortIndex(index)
			}
		},
		[sortAsc, sortIndex, titles]
	)

	const getItemViews = useCallback(() => {
		const items = sorted.map((item, index) => {
			return (
				<ListViewLine
					ownerState={ownerState}
					gap={gap}
					key={item.id()}
					row={index + 2}
					// widths={widths}
					item={item}
					ItemClick={ItemClick}
					selected={selected}
					focused={focused}
					showHover={showHover}
					titles={titles}
					TableRow={ListViewRowStyle}
					TableCell={ListViewCellStyle}
					getComponent={getComponent}
					verticalAlignment={verticalAlignment}
				/>
			)
		})

		return <tbody>{items}</tbody>
	}, [sorted, ownerState, gap, ItemClick, selected, focused, showHover, titles, getComponent, verticalAlignment])

	const getTitleBar = useCallback(() => {
		if (!titles) return null
		return (
			<ListViewHeader
				ref={setTitleRef}
				key={999999}
				gap={gap}
				row={1}
				titles={titles}
				ownerState={ownerState}
				// widths={widths}
				titleClick={titleClick}
				adornments={items?.length && items[0].adornments(false)}
				TableHead={ListViewTitleStyle}
				TableRow={ListViewRowStyle}
				TableCell={ListViewTitleItemStyle}
				{...{ sortAsc, sortIndex }}
			/>
		)
	}, [gap, items, ownerState, setTitleRef, sortAsc, sortIndex, titleClick, titles])

	// useEffect(() => {
	// 	log('ownerState changed')
	// }, [ownerState])
	// logRenderReason('ownerState', [ownerState])

	return (
		<ListViewRoot
			// @ts-ignore
			ref={setRef}
			// @ts-ignore
			ownerState={ownerState}
			width="100%"
			// this somehow fixes a problem where only few items would split the vertical height equally, and end up taking 100% of the parent height
			// maxHeight="100%"
			onKeyDown={keyDown}
			tabIndex={-1}
			{...sxProps}
			{...rest}
			// sx={{
			// 	...sxProps
			// }}
		>
			<MTable size="small">
				{getTitleBar()}
				{getItemViews()}
			</MTable>
		</ListViewRoot>
	)
})
export const ListViewRoot = styled('div', {
	name: 'ListView',
	slot: 'Root',
	overridesResolver: (props: any, styles: any) => {
		const { ownerState } = props
		// we we are in any kind of single or select mode, then don't allow text selection
		return [styles.root, ownerState.selectMode != 'none' && { userSelect: 'none' }]
	}
})(({ theme }) => {
	return {}
})
export const ListViewTitleStyle = styled('thead', {
	name: 'ListView',
	slot: 'Title',
	overridesResolver: (props, styles) => styles.title
})(({ theme }) => {
	return {
		[`&.${rowClasses.sticky}`]: {
			position: 'sticky',
			top: 0,
			zIndex: 9999,
			backgroundColor: theme.palette.background.default
		},

		fontWeight: '500'
	}
})
export const ListViewTitleItemStyle = styled('td', {
	name: 'ListView',
	slot: 'TitleItem',
	overridesResolver: (props, styles) => styles.titleItem
})(({ theme }) => {
	return {
		cursor: 'pointer',
		padding: '0.25em',
		// borderBottom: '1px solid',
		fontWeight: '500',
		'&:hover': {
			fontWeight: '600',
			color: theme.palette.primary.main
		}
	}
})
export const ListViewRowStyle = styled('tr', {
	name: 'ListView',
	slot: 'Row',

	overridesResolver: (props: any, styles) => {
		const { ownerState } = props

		// The styles param contains the style overrides in the theme.
		// If you are just "adding" css values, you can just return a style key as an element in the returned array.
		// Normally we would have the default values in the returned function below (not in this overrides resolver)
		// BUT, if you want to have the theme style overrides override the existing  defaults defined here, then you
		// have 2 options:

		// Option 1) Specify both the default values and override them with a spread operator directly in this function as follows:
		//    (This is a bit clearer as the defaults and the overrides are together)
		const sep: any = {
			[`:not(:last-child)`]: {
				borderColor: 'lightgray',
				borderBottomWidth: '1px',
				borderBottomStyle: ' solid',
				...(styles.separator as any)
			}
		}

		// Alternativley, you can specify the defaults in the returned function below (commented out).
		// But then when adding the overrides to the array returned from this function,
		// you must include the same selector as is used below, as per the commented ou rowClasses.separator example below.
		// Otherwise, the defaults will be used because they are more specific than just a general key
		// i.e. if we just returned styles.separator, instead of the rowClasses.separator selector,
		// then the default, (which specifies the more specific selector) would take precedence because it is more specific.

		// Option 2) Note that  we need to specify the selector exactly as it is specified below for the defaults.
		// const sep: any = {
		// 	[`&.${rowClasses.separator}:not(:last-child)`]: {
		// 		...(styles.separator as any)
		// 	}
		// }

		const res = [styles.item, ownerState.showSeparator && sep]
		return res
	}
})(({ theme, ownerState }: { theme: Theme; ownerState: any }) => {
	return {
		// Option 2
		// [`&.${rowClasses.separator}:not(:last-child)`]: {
		// 	borderColor: 'lightgray',
		// 	borderBottomWidth: '1px',
		// 	borderBottomStyle: ' solid'
		// },
		[`&.${rowClasses.hover}:hover`]: {
			backgroundColor: theme.palette.action.hover
		},
		[`&.${rowClasses.focused}`]: {
			backgroundColor: theme.palette.action.focus
		},
		[`&.${rowClasses.selected}`]: {
			backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			[`&.${rowClasses.hover}:hover`]: {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity)
			},
			[`&.${rowClasses.focused}`]: {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity)
			}
		}
	}
})
export const ListViewCellStyle = styled('td', {
	name: 'ListView',
	slot: 'Cell',
	overridesResolver: (props, styles) => {
		return [styles.item]
	}
	// @ts-ignore
})(({ theme, ownerState }) => {
	return {
		padding: '0px',
		paddingRight: '0.5em'
	}
})
