import { translateError } from './translateError'

export class NestedError extends Error {
	constructor(message: string, cause: any) {
		super(message)
		this.cause = cause
	}
}
export function getNestedErrorMessage(err: any): string {
	let firstErr = err
	while (firstErr) {
		if (firstErr.cause) {
			firstErr = firstErr.cause
		} else {
			return translateError(firstErr)
		}
	}
	return err
}
