import { JsonValueProvider, getValueFromFunction, isTrue, sleep } from '@app/utils'
import { useCallback, useEffect, useState } from 'react'
import { FieldEditorProps } from 'src/EditorService'
import anylogger from '@app/anylogger'

const log = anylogger('useTextFieldEditor')

export function useFieldEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const { fieldDef: field, data, dataChanged } = props
	const { name, provider, editor } = field

	const validate = editor.validate
	const [isError, setIsError] = useState<boolean>(false)
	const [errorText, setErrorText] = useState('')
	const [refresh, setRefresh] = useState(0)

	// const [internalValue, setInternalValue] = useState(() => {
	let value
	if (editor.getEditValue) value = editor.getEditValue()
	if (typeof value == 'undefined') value = provider.getValue(data) ?? ''
	// return value
	// })

	const setValue = useCallback(
		async (val: any) => {
			if (validate) {
				const vr = await validate(val)
				if (vr.preventChange) return
				setIsError(vr.isError || false)
				setErrorText(vr.errorText || '')
			}

			provider.setValue(data, val)
			// setInternalValue(val)
			if (dataChanged) dataChanged(data)
			setRefresh((v) => v + 1)
		},

		[data, dataChanged, provider, validate]
	)

	return {
		baseProps: {
			label: name,
			helperText: errorText,
			error: isError,
			disabled: getValueFromFunction(editor.disabled)
		},
		value,
		setValue
	}
}

export function useTextFieldEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const { baseProps, value, setValue } = useFieldEditor(props)
	return {
		...baseProps,
		value,
		onChange: (e: any) => {
			setValue(e.target.value)
		},
		autoComplete: 'off'
	}
}
export function useCheckboxFieldEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const { baseProps, value, setValue } = useFieldEditor(props)
	const onChange = useCallback(
		(e: any) => {
			setValue(e.target.checked)
		},
		[setValue]
	)

	return {
		...baseProps,
		checked: isTrue(value),
		onChange
	}
}
export function useItemSelectorFieldEditor<T extends object = any>(props: FieldEditorProps<T>) {
	const { baseProps, value, setValue } = useFieldEditor(props)
	log('ItemSelector', value)

	const onChange = useCallback(
		async (e: any, val: any, reason: any) => {
			await setValue(val)
			log('reason', reason, val)
		},
		[setValue]
	)

	return {
		...baseProps,
		value,
		onChange
	}
}
