import { useState } from 'react'
import useResizeObserver from './useResizeObserver'

/**
 * This component returns the width  of the referenced component.
 * It also watches if the referenced component changes in size and triggers a state change.
 * This causes any components that call this hook to re-render if the referenced component changes in size
 *
 */
export function useComponentWidth(ref: any): number {
	const [width, setWidth] = useState(0)

	const { width: w } = useResizeObserver({ ref })
	if (w && w !== width) {
		setWidth(w)
	}
	return width
}
