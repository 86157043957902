import anylogger from '@app/anylogger'
import { useCallback } from 'react'
import { HTMLRef, MouseTouchEvent, MouseTouchEventHandler, createMouseEvent, createTouchEvent } from './mouseTouchUtils'
import { useEvent } from './useEvent'
import { useIsTouch } from './useIsTouch'

const log = anylogger('useUpEvent')

/**
 * This hook amalgamates the mouseUp, touchEnd and touchCancel events so that you can use one hook to control both
 * desktop and mobile devices.
 * @param ref - The ref of the object you want the downEvent for.
 * @param downEvent - This callback gets fired when there is a mouseUp or touchEnd event.  The callback passes a {@link MouseTouchEvent} parameter.
 * @param allowPreventDefault - Whether returning true from the callback will call preventDefault on the originating event handler.
 * @param data - This data will be passed in the callback function parameter.s
 */
export const useUpEvent = (ref: HTMLRef, upEvent: MouseTouchEventHandler, allowPreventDefault?: boolean, data?: any) => {
	const isTouch = useIsTouch()

	const touchEnd = useCallback(
		(e: any) => {
			if (!isTouch) return
			if (!e.changedTouches.length) return
			// log('touchEnd', e)

			if (upEvent(createTouchEvent(e, data))) {
				e.stopPropagation()
				if (allowPreventDefault) e.preventDefault()
			}
		},
		[allowPreventDefault, data, isTouch, upEvent]
	)
	const mouseUp = useCallback(
		(e: any) => {
			if (isTouch) return
			if (upEvent(createMouseEvent(e, data))) {
				log('stopping up', allowPreventDefault)
				e.stopPropagation()
				if (allowPreventDefault) e.preventDefault()
			}
		},
		[allowPreventDefault, data, isTouch, upEvent]
	)
	const options = allowPreventDefault ? { passive: false } : undefined
	useEvent(ref, 'touchEnd', touchEnd, options)
	useEvent(ref, 'mouseUp', mouseUp, options)
	useEvent(ref, 'touchCancel', touchEnd, options)
}
